import { initIdpTable } from './js/portal/idps';
import { Datatable } from './Datatable';

const tableId = 'table-idps';

export default function UsersIdpPage() {
  const page = (
    <>
      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initIdpTable(tableId);
  }

  return [page, onPageReady];
}

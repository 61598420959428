import { initTrainingsAccountTable } from './js/portal/trainingsAccounts';
import { Datatable } from './Datatable';

const tableId = 'table-training-accounts';

export default function TrainingAccountsListPage() {
  const page = (
    <>
      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initTrainingsAccountTable(tableId);
  }

  return [page, onPageReady];
}

import { retrieveVisibleFields, showErrorFromApiOperation } from './main';
import { apiAxios } from './api';
import { removeSpinners } from './sidebar';

function updateSelect(result, listId, accountId, callback) {
  console.debug('updateSelect');
  removeSpinners();
  let list_ids;
  if (Object.prototype.toString.call(listId) !== '[object Array]') {
    list_ids = [listId];
  } else {
    list_ids = listId;
  }

  const accounts = result.data.account_data;

  for (const list_id of list_ids) {
    const select = document.getElementById(list_id);

    if ($('#' + list_id)[0].multiple) {
      $('#' + list_id)
        .find('option')
        .remove();
      $('#' + list_id)
        .find('li')
        .remove();
    }

    jQuery.each(accounts, function () {
      const opt = document.createElement('option');
      let mainText = this.account_id + ' - ' + this.account_friendly_name;
      let subText = this.account_type + ' | ' + this.account_stage;
      opt.value = this.account_id + ';' + this.account_type + ';' + this.account_stage;
      opt.innerText = mainText;
      opt.setAttribute('data-subtext', subText);
      opt.setAttribute('account_id', this.account_id);
      opt.setAttribute('account_friendly_name', this.account_friendly_name);
      opt.setAttribute('account_type', this.account_type);
      opt.setAttribute('account_stage', this.account_stage);
      select.appendChild(opt);

      if (accountId && accountId === this.account_id) {
        opt.selected = true;
      }
    });

    $('#' + list_id).html(
      $('#' + list_id + ' option').sort(function (a, b) {
        return a.text < b.text ? -1 : 1;
      })
    );
  }
  $('.selectpicker').selectpicker('refresh');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');

  if (callback && accountId) callback();
}

export function getAccountIds(listId, accountIdSearchParam, callback) {
  if (listId === undefined) {
    listId = 'aws-account-id';
  }

  let accountId = undefined;
  if (accountIdSearchParam && callback) {
    let urlParams = new URLSearchParams(window.location.search);
    accountId = urlParams.get(accountIdSearchParam);
  }

  // depends if we want to invalidate the cache everytime or not
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = { short: true };
  apiAxios
    .get('/accounts', { headers, params })
    .then(response => {
      updateSelect(response, listId, accountId, callback);
    })
    .catch(showErrorFromApiOperation('Error fetching list of accounts short'));
}

function updateUserSelect(result, list_id) {
  let list_ids = [];
  if (Array.isArray(list_id)) {
    list_ids = list_id;
  } else {
    list_ids = [list_id];
  }

  if (!result.data) throw Error('no user data');

  const user_names = result.data.user_names;

  for (const l_id of list_ids) {
    const select = document.getElementById(l_id);

    if (!select) {
      console.error(`select field with id '${list_id}' not found`);
      continue;
    }

    if (select.multiple) {
      $(select).find('option').remove();
      $(select).find('li').remove();
    }

    let options = [];

    for (let j = 0; j < user_names.length; j++) {
      const opt = document.createElement('option');
      opt.value = user_names[j];
      opt.innerText = user_names[j];
      options.push(opt);
    }

    options.sort(function (a, b) {
      return a.text < b.text ? -1 : 1;
    });

    $(select).children().not('option:first').remove();
    $(select).append(options);
    select.selectedIndex = 0;
  }
  $('.selectpicker').selectpicker('refresh');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');

  removeSpinners();
}

export function getADUserNames(list_id) {
  if (list_id === undefined) {
    list_id = 'user-email';
  }

  apiAxios
    .get('ad-users')
    .then(response => {
      updateUserSelect(response, list_id);
    })
    .catch(showErrorFromApiOperation('loading AD users failed'));
}

/**
 *
 * @param {*} param0
 */
export async function getData({
  apiPath,
  dataAttribute,
  tableId,
  tableColumns,
  dropdownColumns,
  searchColumns,
  headers,
  params,
  forceReload,
}) {
  headers = forceReload ? { ...headers, 'Cache-Control': 'max-age=0, must-revalidate' } : headers;

  const attributes = tableColumns ? retrieveVisibleFields('#' + tableId, tableColumns) : [];
  params = params ? { ...params, attribute_names: attributes.join() } : { attribute_names: attributes.join() };

  return apiAxios
    .get(apiPath, { headers, params })
    .then(response => {
      console.debug('GET ' + apiPath, response);
      addDataAndFormatFooter({
        dataArray: dataAttribute ? response.data[dataAttribute] : response.data,
        tableId: tableId,
        tableColumns: tableColumns,
        dropdownColumns: dropdownColumns,
        searchColumns: searchColumns,
      });
      return response;
    })
    .catch(showErrorFromApiOperation('Error fetching list of trainings accounts'))
    .finally(() => {
      $(() => removeSpinners());
    });
}

export function addDataAndFormatFooter({ tableId, dataArray, tableColumns, dropdownColumns, searchColumns }) {
  if (!dataArray || !Array.isArray(dataArray)) {
    return;
  }

  const table = $('#' + tableId);
  const dt = table.DataTable().clear().draw();
  const dtWTF = table.dataTable();
  const columnsState = dtWTF.api().state().columns;

  dt.rows.add(dataArray).draw();
  dt.columns().every(function (col) {
    const that = this;
    const column = this;
    const title = column.header().innerHTML;
    const dataSource = column.dataSrc();
    const id = tableColumns ? tableColumns[column.index()].id : undefined;

    // Configure Dropdown Columns
    if (dropdownColumns && dropdownColumns.indexOf(dataSource) > -1) {
      const select = $('<select><option value=""></option></select>')
        .appendTo($(column.footer()).empty())
        .on('change', function () {
          const val = $.fn.dataTable.util.escapeRegex($(this).val());
          column.search(val ? '^' + val + '$' : '', true, false).draw();
        });

      column
        .data()
        .unique()
        .sort()
        .each(function (data) {
          let opt = document.createElement('option');
          opt.innerText = data;
          opt.value = data;
          select.append(opt);

          if (
            data &&
            columnsState[col].search &&
            columnsState[col].search.search &&
            data.match(columnsState[col].search.search)
          ) {
            opt.selected = true;
          }
        });
    }

    // Configure Search Columns
    if (searchColumns && (searchColumns.indexOf(dataSource) > -1 || searchColumns.indexOf(id) > -1)) {
      $(column.footer()).html('<input type="text" placeholder="Search ' + title + '" />');

      if (columnsState[col].search && columnsState[col].search.search) {
        column.footer().lastChild.value = columnsState[col].search.search;
      }

      $('input', that.footer()).on('keyup change', function () {
        if (that.search() !== this.value) {
          that.search(this.value).draw();
        }
      });
    }
  });
}

/**
 * Update the navigation bar with the current search parameters without reloading the page. Removes all search
 * parameters from the URL if the function is called without parameters.
 *
 * @param searchParams? {object,string} contains the new search parameters as string '?account_id=123456789012' or as
 * object {account_id: '123456789012'}
 */
export function setSearchParamsUrl(searchParams) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let newUrl = window.location.origin + window.location.pathname + window.location.hash;
  if (typeof searchParams === 'object') {
    for (const [key, value] of Object.entries(searchParams)) {
      urlSearchParams.set(key, value);
    }
    newUrl =
      window.location.origin + window.location.pathname + '?' + urlSearchParams.toString() + window.location.hash;
    sessionStorage.lastSearch = '?' + urlSearchParams.toString();
  } else if (typeof searchParams === 'string') {
    newUrl = window.location.origin + window.location.pathname + searchParams + window.location.hash;
    sessionStorage.lastSearch = searchParams;
  }
  window.history.pushState({ path: newUrl }, '', newUrl);
}

export function overwriteSearchParamsUrl(searchParams) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of Object.entries(searchParams)) {
    if (value) {
      urlSearchParams.set(key, value);
    } else {
      urlSearchParams.delete(key);
    }
  }
  const searchParamsString = urlSearchParams.toString();
  const newUrl =
    window.location.origin +
    window.location.pathname +
    (searchParamsString ? '?' + searchParamsString : '') +
    window.location.hash;
  sessionStorage.lastSearch = searchParamsString ? '?' + searchParamsString : '';
  window.history.pushState({}, '', newUrl);
}

export function getSearchParamsUrl(searchParam) {
  return new URLSearchParams(window.location.search).get(searchParam);
}

export function removeAllSearchParamsUrlExcept(remainingParams) {
  if (window.location.search) {
    let search_params = new URLSearchParams(window.location.search);
    const keys = search_params.keys();
    for (const param_name of keys) {
      if (remainingParams.indexOf(param_name) === -1) search_params.delete(param_name);
    }
    const new_search_params = search_params.toString();
    const clean_uri =
      window.location.origin +
      window.location.pathname +
      (new_search_params ? '?' + new_search_params : '') +
      window.location.hash;
    window.history.replaceState({}, document.title, clean_uri);
  }
}

export function removeSearchParamsUrl() {
  if (window.location.search) {
    const clean_uri = window.location.origin + window.location.pathname + window.location.hash;
    window.history.replaceState({}, document.title, clean_uri);
  }
}

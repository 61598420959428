import { createJsonCodeBlock } from '../../../js/portal/main';

export default function VPCTestResult(props) {
  const { data, loaded } = props;

  return (
    <div class="accordion-body connectivity-result-container">
      <div class="connectivity-result-content d-flex flex-row">
        <div class="flex-grow-1">
          <div class="form-group row px-4 modal-data-table-row">
            <div class="form-label-text col-4">
              <span class="float-end">Region</span>
            </div>
            <div class="form-value-text col-8">{data.region}</div>
          </div>
          <div class="form-group row px-4 modal-data-table-row">
            <div class="form-label-text col-4">
              <span class="float-end">On-Premise URLs</span>
            </div>
            <div class="form-value-text col-8">
              {data.customer_onprem_urls
                ? data.customer_onprem_urls
                    .split(',')
                    .map(item => <span class="bs-dropdown-badge new d-inline-block mb-1">{item}</span>)
                : '-'}
            </div>
          </div>
          <div class="form-group row px-4 modal-data-table-row">
            <div class="form-label-text col-4">
              <span class="float-end">Custom Proxy URLs</span>
            </div>
            <div class="form-value-text col-8">
              {data.customer_proxy_urls
                ? data.customer_proxy_urls
                    .split(',')
                    .map(item => <span class="bs-dropdown-badge new d-inline-block mb-1">{item}</span>)
                : '-'}
            </div>
          </div>
          <div class="form-group row px-4 modal-data-table-row modal-data-table-row--last-row">
            <div class="form-label-text col-4">
              <span class="float-end">Status</span>
            </div>
            <div class="form-value-text col-8">{`${data.status_code} - ${data.status_response} ${
              data.status_update ? `(${data.status_update})` : ''
            }`}</div>
          </div>
        </div>
        <div class="text-center px-5 py-4">
          <div class="connectivity-donut-chart">
            <div class="donut-hole"></div>
            <div class="donut-title">
              {!loaded && (
                <span class="loading-animation">
                  <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                  Loading chart...
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="row px-4">
        <div class="form-value-text col-12 test-output-field">
          {!loaded && (
            <span class="loading-animation">
              <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
              Loading test output...
            </span>
          )}
          {createJsonCodeBlock({})}
        </div>
      </div>
    </div>
  );
}

import { initUserPermissionsTable, loadUserPermissionsTableData } from './js/portal/user-management';
import { Datatable } from './Datatable';
import UserDropdown from './jsx/components/forms/UserDropdown';

const tableId = 'table-user-permissions';

export default function AdminListUserPermissionsPage() {
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <form name="user-assign-form" id="user-assign-form" method="POST">
            <div class="card form-card">
              <div class="card-header">Select User</div>
              <div class="card-body">
                <UserDropdown id="user-email" onchange={() => loadUserPermissionsTableData(tableId)} />
              </div>
            </div>
          </form>
        </div>
      </div>

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initUserPermissionsTable(tableId);
  }

  return [page, onPageReady];
}

import { initInvalidPrincipalsTable } from './js/portal/user-management';
import { Datatable } from './Datatable';

const tableId = 'table-invalid-principals';

export default function AdminListInvalidPrincipalsPage() {
  const page = (
    <>
      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initInvalidPrincipalsTable(tableId);
  }

  return [page, onPageReady];
}

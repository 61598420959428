import { loadAccountData } from './js/portal/accounts';
import { getSearchParamsUrl } from './js/portal/search_params';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import AccountTabs from './jsx/components/forms/AccountTabs';

export default function AccountDetailsPage() {
  // Get the account id from the URL search parameters
  const accountId = getSearchParamsUrl('account_id');

  // Replace the text field with the account id with a dropdown with all account ids for the current user.
  function replaceAccountDropdown() {
    // Remove the current breadcrumb which is only showing the account id and the edit button
    const breadcrumbAccountId = document.getElementById('breadcrumbAccountId');
    breadcrumbAccountId.remove();

    // Create new breadcrumb items. As the dropdown won't show a separator before/after the dropdown, we have to create
    // those two "dummy" breadcrumbs.
    const preDropdownBreadcrumb = <li class="breadcrumb-item"></li>;
    const accountDropdown = (
      <li>
        <AccountDropdown label="none" onchange={loadAccountData} listAll={true} inline />
      </li>
    );
    const postDropdownBreadcrumb = <li class="breadcrumb-item edit-account-show" style="display:none;"></li>;

    // Get the first breadcrumb item and insert new separators and the account dropdown right after
    const breadcrumbRoot = document.getElementById('breadcrumbRoot');
    breadcrumbRoot.after(preDropdownBreadcrumb, accountDropdown, postDropdownBreadcrumb);
  }

  // The actual content of the page
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div class="my-2">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item" id="breadcrumbRoot">
                  <a href="#listaccounts">AWS Accounts</a>
                </li>
                {/* The resource account will be either a simple (disabled) text field (if there's an account_id in
                  the search params of the URL), or as a fallback a dropdown with all the account ids of the current
                  user */}
                {accountId ? (
                  <li class="breadcrumb-item active" id="breadcrumbAccountId">
                    <span>
                      <a>{accountId} </a>
                      <i class="fas fa-pencil-alt" onclick={() => replaceAccountDropdown()}></i>
                    </span>
                  </li>
                ) : (
                  <>
                    <li class="breadcrumb-item"></li>
                    <li>
                      <AccountDropdown label="none" onchange={loadAccountData} listAll={true} inline />
                    </li>
                    <li class="breadcrumb-item edit-account-show" style="display:none;"></li>
                  </>
                )}
                <li class="breadcrumb-item active edit-account-show" style="display:none;" aria-current="page">
                  Edit Account
                </li>
              </ol>
            </nav>
          </div>
          <div id="edit-accounts-form-container" class="my-5 edit-account-show" style="display:none;"></div>
          <div id="account-details-main" class="edit-account-hide my-5">
            <AccountTabs data={{}} tempAccessRoles={[]} isAccountDetailsPage={true} />
          </div>
        </div>
      </div>
    </>
  );

  function onPageReady() {
    // Load the target account data if there's an account selected via URL search parameter
    if (accountId) loadAccountData('', '', '', accountId);
  }

  return [page, onPageReady];
}

export const NetworksCreateSubnets = () => (
  <>
    <ul class="nav nav-tabs space">
      <li class="nav-item">
        <a
          class="nav-link active"
          aria-selected="true"
          aria-expanded="true"
          role="tab"
          data-bs-toggle="tab"
          data-bs-target="#subnets-overview">
          Overview
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          aria-selected="false"
          aria-expanded="false"
          role="tab"
          data-bs-toggle="tab"
          data-bs-target="#subnets-public">
          Public
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          aria-selected="false"
          aria-expanded="false"
          role="tab"
          data-bs-toggle="tab"
          data-bs-target="#subnets-private">
          Private
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          aria-selected="false"
          aria-expanded="false"
          role="tab"
          data-bs-toggle="tab"
          data-bs-target="#subnets-intranet">
          Intranet
        </a>
      </li>
    </ul>

    <div class="tab-content detailsTab">
      <div class="tab-pane fade active show" role="tabpanel" id="subnets-overview" data-tab-type="overview" />
      <div class="tab-pane fade" role="tabpanel" id="subnets-public" data-tab-type="public" />
      <div class="tab-pane fade" role="tabpanel" id="subnets-private" data-tab-type="private" />
      <div class="tab-pane fade" role="tabpanel" id="subnets-intranet" data-tab-type="intranet" />
    </div>
  </>
);

import { baseApiAxios } from './js/portal/api';
import {
  addApiKeyPermissionsMapping,
  addApiKeySettings,
  initAdminApiKeysTable,
  updateByApiSelection,
} from './js/portal/apikeys';
import { Datatable } from './Datatable';

const tableId = 'apiKeysTable';

export default function AdminListApiKeysPage() {
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div id="form-error" class="alert alert-danger" style="display: none">
            <ul />
          </div>
          <div id="form-success" class="alert alert-success" style="display: none">
            <ul />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="form-group row">
            <label for="api-keys" class="col-form-label">
              API Keys
            </label>
            <div class="col">
              <select
                required
                class="form-control form-select selectpicker"
                data-live-search="true"
                data-show-subtext="true"
                id="api-keys"
                name="api-keys"
                data-size="10"
                onchange={() => updateByApiSelection()}>
                <option value="" disabled selected>
                  - Select the target API -
                </option>
              </select>
            </div>
          </div>

          <div name="config-wrapper-detail" />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card infobox">
            <div class="card-body">
              <h4>
                API Settings: <span id="chosenApi" />
              </h4>
              <ul>
                <li>
                  <strong>AWS Account Id:</strong> <span id="apiAccountId" />
                </li>
                <li>
                  <strong>AWS Region:</strong> <span id="apiRegion" />
                </li>
                <li>
                  <strong>Usage Plan Id:</strong> <span id="apiUsagePlanId" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="edit-api-key-permissions-form" style="display: none" />

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initAdminApiKeysTable(tableId);
    baseApiAxios.getSettings({ action: 'get-settings', settings: ['api_keys'] }).then(addApiKeySettings);
    baseApiAxios
      .postApiKeyData({ action: 'list-api-resources-methods', target: 'portal' })
      .then(addApiKeyPermissionsMapping);
  }

  return [page, onPageReady];
}

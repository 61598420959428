export default function DatasetsPaginated(props) {
  const { id, headers, selectionCallback, loadNextPage, loadPreviousPage, nextToken, searchCallback, prefixSearch } =
    props;

  let items = props['items'];
  let datasetsComponent, datasetsContent, datasetsPagination, datasetsSelectedText, pageText;
  let filteredItems = items;
  const maxPages = 5;
  let pageSize = 10;
  let currPage = 1;
  let searchPhrase;
  let markup;
  let selection = {};
  let pageTokens = nextToken ? { [currPage]: null, [currPage + 1]: nextToken } : {};

  const selectAllListener = () => {
    const selectionValues = Object.keys(selection);
    if (items.length === selectionValues.length) {
      selection = {};
    } else {
      items.forEach(item => (selection[item.logGroupName] = item));
    }
    if (!datasetsSelectedText) {
      datasetsComponent = datasetsComponent || document.getElementById(id);
      datasetsSelectedText = datasetsComponent.querySelector('.datasets-selected-text');
    }
    datasetsSelectedText.innerText = `${Object.keys(selection).length} selected${
      !loadNextPage && !loadPreviousPage ? ' of ' + items.length : ''
    }`;
    renderItems(filteredItems);
    if (selectionCallback) selectionCallback(selection);
  };

  const selectionListener = evt => {
    const checkbox = evt.target.querySelector('input[type="checkbox"]');
    checkbox.checked = !checkbox.checked;
    if (checkbox.checked) {
      evt.target.parentElement.classList.add('selected-row');
    } else {
      evt.target.parentElement.classList.remove('selected-row');
    }
    if (!datasetsSelectedText) {
      datasetsComponent = datasetsComponent || document.getElementById(id);
      datasetsSelectedText = datasetsComponent.querySelector('.datasets-selected-text');
    }
    datasetsComponent.querySelectorAll('.datasets-checkbox > input[type="checkbox"]').forEach(cb => {
      if (cb.checked) {
        selection[cb.dataset.id] = items.find(item => item.logGroupName === cb.dataset.id);
      } else {
        if (cb.dataset.id in selection) {
          delete selection[cb.dataset.id];
        }
      }
    });
    datasetsSelectedText.innerText = `${Object.keys(selection).length} selected${
      !loadNextPage && !loadPreviousPage ? `of ${items.length}` : ''
    }`;
    if (selectionCallback) selectionCallback(selection);
  };

  const paginate = (totalItems, currentPage = 1, pageSize = 10) => {
    let totalPages = Math.ceil(totalItems / pageSize);
    currentPage = Math.max(1, Math.min(currentPage, totalPages));
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    return [startIndex, endIndex];
  };

  const setPage = pageNr => {
    currPage = pageNr;
    if (!pageText) {
      datasetsComponent = datasetsComponent || document.getElementById(id);
      pageText = datasetsComponent.querySelector('.page-text');
    }
    pageText.innerText = pageNr;
  };

  return (
    <div id={id} class="detailsContainer">
      <div class="d-flex flex-row mx-0 my-3">
        <div class="flex-grow-1">
          {prefixSearch ? (
            <div class="text-array-field d-flex">
              <input
                type="text"
                class="form-control datasets-search"
                placeholder="Search by prefix..."
                onkeyup={evt => {
                  if (evt.key === 'Enter') {
                    evt.target.parentElement.querySelector('.datasets-search-button').dispatchEvent(new Event('click'));
                  }
                }}></input>
              <button
                type="button"
                class="btn btn-success datasets-search-button mx-1"
                onclick={async evt => {
                  const parent = evt.target.parentElement;
                  searchPhrase = parent.querySelector('.datasets-search').value;
                  parent.querySelector('.reset-search-button').classList.remove('d-none');
                  setPage(1);
                  if (searchCallback) {
                    const [newItems, token] = await searchCallback(pageSize, searchPhrase);
                    pageTokens = { [currPage]: null, [currPage + 1]: token };
                    items = newItems;
                    renderItems(newItems);
                  } else {
                    filteredItems = items.filter(obj =>
                      Object.values(obj).some(val => val?.toString().includes(searchPhrase))
                    );
                    renderItems(filteredItems);
                  }
                }}>
                <i class="fas fa-search pe-none"></i>
              </button>
              <button
                type="button"
                class="btn btn-success reset-search-button form-control--transition mx-1 d-none"
                onclick={async evt => {
                  evt.target.parentElement.querySelector('.datasets-search').value = '';
                  searchPhrase = null;
                  setPage(1);
                  const [newItems, token] = await searchCallback(pageSize, searchPhrase);
                  pageTokens = { [currPage]: null, [currPage + 1]: token };
                  items = newItems;
                  renderItems(newItems);
                  evt.target.classList.add('d-none');
                }}>
                <i class="fas fa-redo-alt pe-none"></i>
              </button>
            </div>
          ) : (
            <input
              type="text"
              class="form-control datasets-search"
              placeholder="Search by keyword..."
              oninput={async evt => {
                searchPhrase = evt.target.value;
                filteredItems = items.filter(obj =>
                  Object.values(obj).some(val => val?.toString().includes(searchPhrase))
                );
                setPage(1);
                selection = {};
                renderItems(filteredItems);
                if (searchCallback) {
                  const [newItems, token] = await searchCallback(pageSize, searchPhrase);
                  pageTokens = { [currPage]: null, [currPage + 1]: token };
                  renderItems(newItems);
                }
              }}></input>
          )}
        </div>
        <div class="d-flex text-nowrap">
          <div class="datasets-label-text px-4">
            Page <strong class="page-text">1</strong>
          </div>
          <select
            class="form-control form-select datasets-page-size"
            onchange={async evt => {
              pageSize = evt.target.value;
              renderItems(filteredItems);
              if (loadNextPage) {
                setPage(1);
                const [newItems, token] = await loadNextPage(null, pageSize, searchPhrase);
                items = newItems;
                renderItems(newItems);
                pageTokens = { [currPage]: null, [currPage + 1]: token };
                if (!datasetsSelectedText) {
                  datasetsComponent = datasetsComponent || document.getElementById(id);
                  datasetsSelectedText = datasetsComponent.querySelector('.datasets-selected-text');
                }
                datasetsSelectedText.innerText = `${Object.keys(selection).length} selected${
                  !loadNextPage && !loadPreviousPage ? ' of ' + items.length : ''
                }`;
              }
            }}>
            <option>10</option>
            <option>20</option>
            <option>50</option>
          </select>
        </div>
      </div>
      <div class="datasets-container">
        <table class="w-100">
          <thead>
            <tr>
              <th class="datasets-checkbox-header datasets-header" onclick={selectAllListener}>
                Select
              </th>
              {headers && headers.map(header => <th class="col datasets-header">{header.title}</th>)}
            </tr>
          </thead>

          <tbody class="datasets-content">{renderItems(filteredItems, true)}</tbody>
        </table>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end my-3">
          <ul class="pagination my-0">
            <li class="page-item">
              <span class="page-link prev disabled datasets-selected-text">{`0 selected${
                !loadNextPage && !loadPreviousPage ? ` of ${filteredItems.length}` : ''
              }`}</span>
            </li>
          </ul>
        </div>
        <div class="flex-grow-1">
          <div
            class="d-flex justify-content-end datasets-pagination my-3"
            onclick={async evt => {
              evt.preventDefault();
              if (evt.target.classList.contains('page')) {
                setPage(parseInt(evt.target.innerText));
              } else if (evt.target.classList.contains('next')) {
                setPage(currPage + 1);
                if (loadNextPage) {
                  const [newItems, token] = await loadNextPage(pageTokens[currPage], pageSize, searchPhrase);
                  items = newItems;
                  renderItems(newItems);
                  pageTokens[currPage + 1] = pageTokens[currPage + 1] || token;
                  return;
                }
              } else if (evt.target.classList.contains('prev')) {
                setPage(currPage - 1);
                if (loadPreviousPage) {
                  const [newItems, token] = await loadPreviousPage(pageTokens[currPage], pageSize, searchPhrase);
                  pageTokens[currPage + 1] = pageTokens[currPage + 1] || token;
                  items = newItems;
                  renderItems(newItems);
                  return;
                }
              } else {
                return;
              }
              renderItems(filteredItems);
            }}>
            {renderPageControls(currPage, items.length)}
          </div>
        </div>
      </div>
    </div>
  );

  function renderItems(visibleItems, returnHTML) {
    const [startIndex, endIndex] =
      !loadNextPage && !loadPreviousPage ? paginate(visibleItems.length, currPage, pageSize) : [0, pageSize];
    if (!returnHTML) {
      if (!datasetsPagination) {
        datasetsComponent = datasetsComponent || document.getElementById(id);
        datasetsPagination = datasetsComponent.querySelector('.datasets-pagination');
      }
      $(datasetsPagination).html(renderPageControls(currPage, visibleItems.length));
    }
    if (visibleItems?.length) {
      markup = visibleItems.slice(startIndex, endIndex + 1).map(item => (
        <tr class={`datasets-row${item.logGroupName in selection ? ' selected-row' : ''}`}>
          <td class="datasets-checkbox text-center" onclick={selectionListener}>
            <input type="checkbox" data-id={item.logGroupName} checked={item.logGroupName in selection}></input>
          </td>
          {headers.map(header => (
            <td class="col">{item && item[header.key]?.toString()}</td>
          ))}
        </tr>
      ));
    } else {
      markup = [
        <tr>
          <td colspan={`${headers.length + 1}`} class="py-5 text-center">
            no datasets
          </td>
        </tr>,
      ];
    }
    if (returnHTML) return markup;
    if (!datasetsContent) {
      datasetsComponent = datasetsComponent || document.getElementById(id);
      datasetsContent = datasetsComponent.querySelector('.datasets-content');
    }
    $(datasetsContent).html(markup);
  }

  function renderPageControls(currentPage, totalItems) {
    let pages = [];
    if (!loadNextPage && !loadPreviousPage) {
      let totalPages = Math.ceil(totalItems / pageSize);
      const startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
      const endPage = Math.min(totalPages, currentPage + Math.floor(maxPages / 2) - 1);
      pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);
    }
    return (
      <ul class="pagination my-0">
        <li class="page-item">
          <span class={`page-link prev ${currentPage === 1 ? ' disabled' : ''}`}>Previous</span>
        </li>
        {pages.map(p => (
          <li class="page-item">
            <span class="page-link page" page={p}>
              {p}
            </span>
          </li>
        ))}
        <li class="page-item">
          <span
            class={`page-link next${
              (!loadNextPage && currentPage === Math.ceil(filteredItems.length / pageSize)) || items.length < pageSize
                ? ' disabled'
                : ''
            }`}>
            Next
          </span>
        </li>
      </ul>
    );
  }
}

import swal from 'sweetalert';
import { accountResetSpinners } from './js/portal/accounts';
import { baseApiAxios, showError } from './js/portal/api';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import AccountDropdown from './jsx/components/forms/AccountDropdown';

export default function AdminOrganizationsMovePage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="ou-move-form" id="ou-move-form" method="POST">
          <div class="card form-card">
            <div class="card-header">Move AWS Account in Organizations OU</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>
              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  With this form you can move the chosen AWS account into its target OU (automatically evaluated via the
                  Master Account ID, Account Type, Account Stage and FPC Status). Values for Account Type, Account Stage
                  and FPC Status are queried from DynamoDB. The Master Account ID will be described directly within the
                  target AWS Account via organizations:DescribeOrganizations api.
                </div>
              </div>

              {/* We want to see also deleted accounts in this dropdown by request from operations */}
              <AccountDropdown id="aws-account-id" listAll={true} listDeleted={true} lookup />
            </div>
            <div class="card-footer" align="right">
              <button id="submitButton" type="submit" class="btn btn-success" value="Move Account">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Move Account
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function resultFunction(result) {
    switch (result.status) {
      case 200:
        $('#form-success')
          .html('<strong>' + result.message + '</strong>')
          .show();
        break;
      case 400:
      case 401:
      case 403:
      case 500:
        $('#form-error')
          .html('<strong>Warning: </strong>' + result.message + ' ')
          .show();
        break;
    }
  }

  function onPageReady() {
    $('#ou-move-form')
      .on('submit', function (e) {
        e.preventDefault();
        $('#form-error').hide();
        $('#form-success').hide();
        addSpinner();
        addButtonSpinner();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        submitHandler: function () {
          const accountId = $('#aws-account-id')[0].value.split(';')[0];

          swal({
            title: 'Are you sure?',
            text: 'Do you really want to execute an OU move for AWS account ' + accountId + '?',
            icon: 'warning',
            buttons: {
              cancel: {
                text: 'Cancel',
                value: null,
                visible: true,
              },
              reset: {
                text: 'Move Account',
                value: true,
                className: 'swal-button swal-button--confirm swal-button--danger',
              },
            },
            dangerMode: true,
          }).then(response => {
            if (response) {
              if (accountId) {
                baseApiAxios
                  .post(`/accounts/${accountId}/ou-move`)
                  .then(resultFunction)
                  .catch(error => {
                    showError(error);
                  })
                  .finally(removeSpinners);
              }
            } else {
              accountResetSpinners();
            }
          });
        },
      });
  }

  return [page, onPageReady];
}

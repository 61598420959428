import ChartLegend from './ChartLegend';

export default function ChartHorizontalStacked(props) {
  const { title, labels, values, absolute, reference, hideLegend, colors } = props;

  let sum = 0;
  let endValues = [];

  values.forEach(value => {
    sum += value;
    endValues.push(sum);
  });

  const percentages = values.map(value => ((value * 100) / sum).toFixed(3));
  const ticks = endValues.map(endValue => (endValue * 100) / sum);

  return (
    <div class="mt-2">
      <div class="tile-text-tertiary">
        <b>{title}</b>
      </div>
      <div class="tile-chart-bar" style={{ width: `${absolute ? Math.max((sum * 100) / reference, 20.0) : 100}%` }}>
        <div class="tile-chart-bar-background">
          {values?.length && (
            <span class="tile-chart-bar-ticks" style={{ left: '0' }}>
              0
            </span>
          )}
          {absolute ? (
            <span class="tile-chart-bar-ticks" style={{ left: `100%` }}>
              {Math.round(sum)}
            </span>
          ) : (
            ticks.map((tick, index) => {
              if (percentages[index] < 4) return <></>;
              return (
                <span class="tile-chart-bar-ticks" style={{ left: `${tick.toFixed(3)}%` }}>
                  {index === ticks.length - 1 ? 100 : Math.floor(tick)}
                </span>
              );
            })
          )}
        </div>
      </div>
      <div class="tile-chart-bar" style={{ width: `${absolute ? Math.max((sum * 100) / reference, 20.0) : 100}%` }}>
        <div class="tile-chart-bar-background">
          {percentages.map((percentage, index) => {
            if (percentage === 0) return <></>;
            return (
              <div
                class="tile-chart-bar-body"
                data-value={values[index]}
                style={{ width: `${percentage}%`, backgroundColor: colors[index % colors.length] }}></div>
            );
          })}
        </div>
      </div>
      {!hideLegend && <ChartLegend labels={labels || []} colors={colors}></ChartLegend>}
    </div>
  );
}

import axios from 'axios';

const kinesisPricingUrl = 'https://pricing.us-east-1.amazonaws.com/offers/v1.0/aws/AmazonKinesis/current/index.json';
let kinesisPricing;

export async function getKinesisPriceList() {
  const relevantKeys = ['OnDemandStreamHr', 'OnDemandDataIngested', 'ConsumerHour', 'shardHourStorage', 'PutRequest'];
  const result = await axios({
    method: 'get',
    url: kinesisPricingUrl,
    withCredentials: false,
  });
  let productPriceData = {};
  Object.entries(result.data.products).forEach(([productKey, values]) => {
    if (relevantKeys.indexOf(values.attributes.operation) !== -1) {
      const priceData = Object.values(result.data.terms['OnDemand'][productKey])[0];
      let priceDimensionData = Object.values(priceData['priceDimensions'])[0];
      productPriceData[values.attributes.regionCode] = productPriceData[values.attributes.regionCode] || {};
      productPriceData[values.attributes.regionCode][values.attributes.operation] = parseFloat(
        priceDimensionData['pricePerUnit']['USD']
      );
    }
  });
  return productPriceData;
}

export async function getSIEMCostEstimate(region, volumeBytes, shards, retention, encryption) {
  const estimatedUnitSize = 5000;
  const numberOfUnits = volumeBytes / estimatedUnitSize;
  const unitsPerSecond = numberOfUnits / (730 * 60 * 60);

  const costsKinesis = await getKinesisDataStreamCostEstimate(
    unitsPerSecond,
    estimatedUnitSize,
    region,
    shards,
    retention
  );
  const costsKMS = encryption ? getKMSCostEstimate(numberOfUnits * 2) : 0.0;

  const costsTotal = costsKinesis + costsKMS;
  return isNaN(costsTotal) ? 0.0 : costsTotal;
}

export async function getKinesisDataStreamCostEstimate(unitsPerSecond, unitSizeBytes, region, shards, retention) {
  // AWS limits
  const awsMaxRecordsPerSec = 1000; // Maximum number of records per second per shard
  /*
    const awsMaxIncomingBw = Math.pow(1024, 2) // Maximum number of incoming bytes per second per shard
    const awsMaxOutgoingBw = 2 * Math.pow(1024, 2) // Maximum number of outgoing bytes per second per shard
    */

  if (!kinesisPricing) kinesisPricing = await getKinesisPriceList();

  const streamHour = kinesisPricing[region]['OnDemandStreamHr'];
  const ingestedGB = kinesisPricing[region]['OnDemandDataIngested'];
  const shardHour = kinesisPricing[region]['ConsumerHour'];
  const shardHourStorage = kinesisPricing[region]['shardHourStorage'];
  const putUnits = kinesisPricing[region]['PutRequest'];

  let costsStreams = 0.0;
  let costsDataIngested = 0.0;
  let costsShards = 0.0;
  let costsPutOperations = 0.0;
  let costsRetention = 0.0;

  if (shards === 0) {
    costsStreams = Math.ceil(unitsPerSecond / awsMaxRecordsPerSec) * streamHour * 730;
    costsDataIngested = (unitsPerSecond * 2628000 * unitSizeBytes * ingestedGB) / (1000 * 1024 * 1024);
  } else {
    costsShards = shards * shardHour * 730;
    costsPutOperations = unitsPerSecond * 2628000 * putUnits;
    costsRetention = retention === 1 ? 0.0 : shardHourStorage * 730;
  }

  return costsStreams + costsDataIngested + costsShards + costsPutOperations + costsRetention;
}

export function getKMSCostEstimate(requests) {
  return 1.0 + (requests * 0.03) / 10000;
}

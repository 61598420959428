/**
 * Loads a UI component and optionally inserts it into the given target.
 *
 * If `component` is a function, it can return either a `HTMLElement` or an array of [HTMLElement, function].
 *
 * In the latter case, the function is executed _after_ the element is inserted into the target, if a `target`
 * is also indicated. Otherwise, the function will be executed immediately. This can be used as an alternative
 * to JQuery's `$(document).ready(...)` or similar, which cannot be used in the render function since the
 * DOM is already "ready" at that point.
 *
 * If optional `target` is given, the rendered component will be inserted into that container, replacing its
 * current contents. `target` can be anything that can be passed to JQuery's `$()` function, including a
 * JQuery object or a selector string.
 *
 * @param component {HTMLElement|HTMLElement[]|[HTMLElement, function]|[HTMLElement[], function]|function}
 *        a component or a render function.
 * @param target {HTMLElement|JQuery<*>|string?}
 * @return {Promise<JQuery<*>>} a promise that will be fulfilled after the element has been rendered, inserted
 *         into `target` and after the ready function has been executed.
 */
export async function loadComponent(component, target) {
  if (typeof component === 'function') {
    return loadComponent(component(), target);
  }

  while (component instanceof Promise) {
    component = await component;
  }

  let onReady;
  if (Array.isArray(component) && component.length === 2 && typeof component[1] === 'function') {
    [component, onReady] = component;
  }

  if (target) {
    target = $(target).empty().append(component);
  }

  if (onReady) {
    onReady();
  }

  return target;
}

$.fn.extend({
  /**
   * JQuery extension for loadComponent, so we can render a component directly into a container and keep the
   * fluent style.
   *
   * @this {JQuery<*>} the container into which the component is inserted
   * @param component {HTMLElement|HTMLElement[]|[HTMLElement, function]|[HTMLElement[], function]|function}
   *        the DOM element or render function for the component to be inserted
   * @param callback {function?} an optional callback function that gets executed when the component
   *        has been inserted into this container
   */
  loadComponent(component, callback) {
    loadComponent(component, this).then(() => {
      if (callback) callback.bind(this)();
    });
    return this;
  },
});

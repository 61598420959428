import { baseApiAxios } from './js/portal/api';
import { createDisplayErrorFunction, displaySuccess } from './js/portal/main';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';

export default function SiemCreateKeyPage() {
  const displayError = createDisplayErrorFunction('Generation of <b>SIEM Onboarding Key</b> failed:', false);

  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="create-siem-key" id="create-siem-key" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Create SIEM Customer Key</div>

            {/* CARD BODY */}
            <div class="card-body">
              <div id="form-error" class="alert alert-danger mb-4" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success mb-4" style="display: none">
                <ul />
              </div>

              <div class="detailsContainer">
                <div class="row mb-3 px-3">
                  <div id="infobox" class="card infobox">
                    <div class="card-body">
                      <p>Create a new customer key for SIEM Onboarding requests.</p>
                    </div>
                  </div>
                </div>

                <div class="d-flex form-group">
                  <div class="flex-grow-1 row">
                    <label for="ola_handle" class="col-form-label">
                      OLA Handle
                    </label>
                    <div class="col-12 col-lg-6">
                      <input
                        id="ola_handle"
                        type="text"
                        class="form-control"
                        placeholder="e.g. a10_xyz_abcdef1"
                        required={true}/>
                    </div>
                    <div class="col-3 py-4 py-lg-0">
                      <button id="submitButton" type="submit" class="btn btn-success">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        Generate Key
                      </button>
                    </div>
                  </div>
                </div>

                <div class="d-flex form-group">
                  <div class="flex-grow-1 row">
                    <label class="col-form-label">Key</label>
                    <div class="col-12 col-lg-6">
                      <div class="form-result-container">
                        <input type="text" id="key" class="form-result text-truncate" />
                        <div id="copy-key" class="copy-icon">
                          <i class="fa fa-copy" />
                        </div>
                      </div>
                    </div>
                    <div class="col d-lg-none"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    const resultContainer = document.getElementById('key');

    document.getElementById('create-siem-key').addEventListener('submit', evt => {
      evt.preventDefault();
      resultContainer.classList.remove('form-result-success');
      addSpinner();
      addButtonSpinner();
      baseApiAxios
        .createSIEMKey(document.getElementById('ola_handle')?.value)
        .then(verificationResponse => {
          const key = verificationResponse['siem_key'];
          if (resultContainer && key) {
            displaySuccess('SIEM Onboarding Key successfully created', false);
            resultContainer.value = key;
            resultContainer.classList.add('form-result-success');
          } else {
            displayError(verificationResponse);
          }
        })
        .catch(err => {
          displayError(err);
        })
        .finally(() => {
          removeSpinners();
        });
    });

    document.getElementById('copy-key').addEventListener('click', evt => {
      const inputField = evt.target.parentElement.querySelector('#key');
      navigator.clipboard.writeText(inputField.value);
      evt.target.classList.add('copied');
      setTimeout(() => {
        evt.target.classList.remove('copied');
      }, 300);
    });
  }

  return [page, onPageReady];
}

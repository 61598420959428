import axios from 'axios';
import { CONF } from './environment';
import { authInterceptor } from './auth';
import {
  addCopyButton,
  addRowLoadingAnimation,
  displaySuccessPopup,
  encodeEntities,
  getWindowPosition,
  renderInfoTable,
  showErrorFromApiOperation,
  withLoadingSpinner,
} from './main';
import { renderBanner } from './banner';
import swal from 'sweetalert';
import { addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import { initDataTable } from './datatable';

/**
 * Provides methods for accessing the banners API in the backend.
 * @constructor
 */
export function BannersApi() {
  const axiosInstance = axios.create({
    baseURL: CONF.apiEndpoint + '/banner',
  });
  axiosInstance.interceptors.request.use(authInterceptor);

  /**
   * Loads all banners (active and inactive) from the backend. Requires authorization.
   * @returns {Promise<object[]>} the array of banners
   */
  this.loadBanners = function () {
    return axiosInstance.get('/').then(response => {
      console.debug('GET /banner response:', response);
      return response.data['banners'];
    });
  };

  /**
   * Creates a new banner.
   * @param bannerInput {object} the banner input properties
   * @returns {Promise<void>}
   */
  this.createBanner = function (bannerInput) {
    return axiosInstance.post('/', bannerInput).then(response => {
      console.debug('POST /banner response:', response);
    });
  };

  /**
   * Deletes a banner.
   * @param bannerId {string} the ID of the banner to delete
   * @returns {Promise<void>}
   */
  this.deleteBanner = function (bannerId) {
    return axiosInstance.delete('/' + bannerId).then(response => {
      console.debug('DELETE /banner/' + bannerId + ' response:', response);
    });
  };
}

/**
 * UI operations for working with banners. In addition to the "raw" API operations, this
 * will also take care of asking for confirmation, showing success/error messages etc.
 *
 * Using BannerOperations will reload the banners when the `reloadBanners` method is called,
 * or automatically whenever an API operation would change the contents of the banners.
 *
 * @param bannersApi {BannersApi} to invoke API operations
 * @constructor
 */
export function BannerOperations(bannersApi) {
  /**
   * Register a callback function to be called when the banners have been (re-)loaded.
   * @param callback {Function} the callback function, which will be passed the list of
   *        banners as a parameter
   */
  this.onBannersLoaded = function (callback) {
    this.onBannersLoadedCallback = callback;
  };

  this.reloadBanners = function () {
    $(() => addSpinner());
    $(() => addTableSpinner());
    bannersApi.loadBanners().then(banners => {
      if (this.onBannersLoadedCallback) {
        this.onBannersLoadedCallback(banners);
      }
    });
  };

  this.createBanner = function (bannerInput) {
    return withLoadingSpinner(() => bannersApi.createBanner(bannerInput))
      .then(() =>
        displaySuccessPopup(
          'The new banner has been created successfully!\n\n' +
            'Please note that due to caching, if the banner starts to be active immediately or very soon, ' +
            'it might take a short while until all users will see it.',
          'Banner created'
        )
      )
      .then(() => this.reloadBanners())
      .catch(showErrorFromApiOperation('Error creating banner'));
  };

  this.deleteBanner = function (banner) {
    const bannerTitleDisplay = banner.title.length > 50 ? banner.title.substring(0, 50) + '...' : banner.title;

    function confirmDeletion() {
      let confirmText = 'Are you sure you wish to delete the banner "' + bannerTitleDisplay + '"?';
      if (banner.status === 'EXPIRED') {
        confirmText +=
          '\n\nNote: expired banners will automatically disappear from this list after some time, ' +
          'they do not have to be deleted manually.';
      }
      return swal({
        title: 'Are you sure?',
        text: confirmText,
        buttons: {
          cancel: { text: 'Cancel', value: null, visible: true },
          confirm: { text: 'Delete', value: true, className: 'swal-button swal-button--confirm swal-button--danger' },
        },
      });
    }

    return confirmDeletion().then(
      confirmResult =>
        confirmResult &&
        withLoadingSpinner(() => bannersApi.deleteBanner(banner.id))
          .then(() => {
            let successText = 'The banner "' + bannerTitleDisplay + '" has been deleted successfully.';
            if (banner.status === 'ACTIVE') {
              successText +=
                '\n\nPlease note that due to caching, users may continue to see the banner for a ' + 'short time.';
            }
            return displaySuccessPopup(successText, 'Banner deleted');
          })
          .then(() => this.reloadBanners())
          .catch(showErrorFromApiOperation('Error deleting banner'))
    );
  };
}

/**
 * Manages the table of banners.
 *
 * @param bannerOperations {BannerOperations}
 * @param showForm {Function} function that will be called when the "create banner"
 *        form should be shown
 * @constructor
 */
export function BannersTable(bannerOperations, showForm) {
  const renderDate = {
    display: date => date.toUTCString(),
    sort: date => date.toISOString(),
    filter: date => date.toUTCString() + '///' + date.toISOString(),
  };

  const dataTable = initDataTable(
    'table-banners',
    'lCfrtpBi',
    [
      {
        text: 'Create Banner',
        action: () => {
          window.scrollTo(0, 0);
          showForm();
        },
      },
    ],
    [
      {
        name: 'select_col',
        data: null,
        defaultContent: '',
        orderable: false,
        searchable: false,
        class: 'details-control',
        createdCell: null,
      },
      {
        name: 'id_col',
        title: 'ID',
        data: 'id',
        visible: false,
        orderable: true,
        searchable: true,
      },
      {
        name: 'title_col',
        title: 'Title',
        data: 'title',
        width: '30%',
      },
      {
        name: 'severity_col',
        title: 'Severity',
        data: 'severity',
        render: {
          display: data => (typeof data === 'string' ? data.toUpperCase() : data),
        },
      },
      {
        name: 'status_col',
        title: 'Status',
        data: 'status',
        createdCell: (td, cellData) => {
          $(td)
            .addCopyButton()
            .css({
              color: cellData === 'ACTIVE' ? '#009688' : 'grey',
              'font-weight': 'bold',
            });
        },
      },
      {
        name: 'start_time_col',
        title: 'Active From',
        data: 'activeFrom',
        render: renderDate,
      },
      {
        name: 'end_time_col',
        title: 'Active Until',
        data: 'activeUntil',
        render: renderDate,
      },
      {
        name: 'created_col',
        title: 'Created',
        data: 'createdDate',
        visible: false,
        render: renderDate,
      },
      {
        name: 'created_by_col',
        title: 'Created By',
        data: 'created_by',
        visible: false,
      },
      {
        visible: true,
        name: 'actions_col',
        title: 'Actions',
        data: null,
        orderable: false,
        searchable: false,
        defaultContent: '',
        width: '50px',
        createdCell: (td, _cellData, rowData) => {
          $(td).append(
            $('<div class="table-action-button-group">').append(
              $('<button class="btn btn-custom btn-xs space" data-bs-toggle="tooltip" title="Delete Banner">')
                .on('click', () => bannerOperations.deleteBanner(rowData))
                .append('<span class="fas fa-trash-alt">')
            )
          );
        },
      },
    ],
    function (row, data) {
      if (data.fpc_status === 'deleted') {
        $(row).addClass('row-deleted');
      } else if (data.fpc_status === 'halt') {
        $(row).addClass('row-inactive');
      }
    },
    {
      columnDefs: [
        {
          targets: '_all',
          visible: true,
          orderable: true,
          searchable: true,
          render: {
            display: encodeEntities,
          },
          createdCell: addCopyButton,
        },
      ],
      preDrawCallback: getWindowPosition,
    }
  );

  $('#table-banners tbody').on('click', 'tr td.details-control', function () {
    const tr = $(this).closest('tr');
    const row = dataTable.row(tr);
    if (row.child.isShown()) {
      tr.removeClass('details');
      row.child.hide();
    } else {
      tr.addClass('details');
      addRowLoadingAnimation(row);
      row.child(renderDetailsRow(row.data())).show();
      row.child().addClass('rowDetails');
    }
  });

  this.updateBanners = function (banners) {
    console.debug('Banners loaded', banners);
    dataTable.clear();
    dataTable.rows.add(
      // enrich banner data for display in table
      banners.map(banner => {
        const now = new Date();
        const activeFrom = new Date(banner['start_time']);
        const activeUntil = new Date(banner['end_time']);
        let status = 'EXPIRED';
        if (now < activeFrom) {
          status = 'SCHEDULED';
        } else if (now < activeUntil) {
          status = 'ACTIVE';
        }
        return {
          ...banner,
          activeFrom,
          activeUntil,
          status,
          createdDate: new Date(banner['created']),
        };
      })
    );
    removeSpinners();
    dataTable.draw(true);
  };

  const bannerDetailFields = [
    { Name: 'ID', Value: 'banner_id' },
    { Name: 'Title', Value: 'title' },
    { Name: 'Severity', Value: 'severity' },
    { Name: 'Active From', Value: 'start_time' },
    { Name: 'Active Until', Value: 'end_time' },
    { Name: 'Created', Value: 'created' },
    { Name: 'Created By', Value: 'created_by' },
  ];

  const bannerDetailTabs = [
    {
      id: 'details',
      title: 'Details',
      render: banner => {
        // add the parsed Date objects into the banner for rendering in the details table
        const bannerDetails = {
          ...banner,
          // need to use an alias for ID, lest it be interpreted as an order ID
          banner_id: banner['id'],
          severity: banner['severity'].toUpperCase(),
          start_time: new Date(banner['start_time']).toUTCString(),
          end_time: new Date(banner['end_time']).toUTCString(),
          created: new Date(banner['created']).toUTCString(),
        };

        return $('<div class="row">').append(
          $('<div class="col-xs-12 col-lg-6 detailsContent">').append(
            '<h4>Banner Details</h4>',
            $('<div>').append(renderInfoTable(bannerDetails, bannerDetailFields))
          ),
          $('<div class="col-xs-12 col-lg-6 detailsContent">').append(
            '<h4>Banner Text</h4>',
            $('<div>').append($('<pre style="white-space: pre">').text(banner['text']))
          ),
          $('<div class="col-xs-12 detailsContent">').append(
            $('<button type="button" class="btn-sm space">')
              .on('click', () => bannerOperations.deleteBanner(banner))
              .append('<span class="fas fa-trash-alt">', ' Delete Banner')
          )
        );
      },
    },
    {
      id: 'preview',
      title: 'Preview',
      render: banner =>
        $('<div class="row">').append(
          $('<div class="col-xs-12 detailsContent">').append(
            '<h4>Banner Preview</h4>',
            renderBanner(banner, { noDismiss: true })
          )
        ),
    },
  ];

  function renderDetailsRow(banner) {
    const tabNav = $('<ul class="nav nav-tabs space">');
    const tabContent = $('<div class="tab-content detailsTab">');

    bannerDetailTabs.forEach((tabDef, index) => {
      const tabContentId = 'banner-detailstab-' + banner.id + '-' + tabDef.id;
      const active = index === 0;

      tabNav.append(
        $('<li class="nav-item" role="presentation">')
          .toggleClass('active', active)
          .append(
            $('<a class="nav-link" role="tab" data-bs-toggle="tab">')
              .toggleClass('active', active)
              .text(tabDef.title)
              .attr({
                'data-bs-target': '#' + tabContentId,
                'aria-selected': active,
                'aria-expanded': active,
              })
          )
      );
      tabContent.append(
        $('<div class="tab-pane fade container-fluid" role="tabpanel">')
          .attr('id', tabContentId)
          .toggleClass('active show', active)
          .append(tabDef.render(banner))
      );
    });

    return $('<div class="detailsContainer">').append(tabNav, tabContent);
  }
}

/**
 * Manages the input form to create a new banner.
 *
 * @param bannerOperations {BannerOperations}
 * @constructor
 */
export function CreateBannerForm(bannerOperations) {
  const form = $('#createBannerForm');
  const formContainer = $('#createBannerFormContainer');

  function combineDateTime(dateValue, timeValue) {
    return dateValue && dateValue + 'T' + (timeValue || '00:00') + 'Z';
  }

  function updateHiddenDateTimeInput(inputGroupSelector) {
    $(inputGroupSelector || '.datetime-input-group').each(function () {
      const dateInput = $('input[type=date]', this);
      const timeInput = $('input[type=time]', this);
      $('input[type=hidden]', this).val(combineDateTime(dateInput.val(), timeInput.val()) || '');
    });
  }

  // Set the "active from" date to the current date and the time to 00:00 (midnight)
  // when the "active immediately" checkbox is checked
  function setActiveFromToCurrentDate() {
    $('#bannerActiveFromDate').val(new Date().toISOString().substring(0, 10));
    $('#bannerActiveFromTime').val('00:00');
    updateHiddenDateTimeInput('#inputGroupActiveFrom');
  }

  function updateBannerPreview() {
    const banner = {
      title: $('#bannerTitle').val(),
      severity: $('#bannerSeverity').val(),
      text: $('#bannerText').val(),
    };
    if (banner.title) {
      const renderedBanner = renderBanner(banner, { noDismiss: true });
      $('#bannerPreviewMissingTitleText').hide();
      $('#createBannerPreview').empty().append(renderedBanner).show();
    } else {
      $('#createBannerPreview').hide();
      $('#bannerPreviewMissingTitleText').show();
    }
  }

  this.showForm = function () {
    setActiveFromToCurrentDate();
    formContainer.show();
  };

  function hideForm() {
    const validator = form.validate();
    validator.resetForm();
    updateBannerPreview(); // to reset the preview banner too
    formContainer.hide();
  }

  setActiveFromToCurrentDate();

  $('#checkboxActiveImmediately').on('change', function () {
    if (this.checked) {
      setActiveFromToCurrentDate();
    }
    $('#inputGroupActiveFrom input').attr('disabled', this.checked);
  });

  // Custom validation rule to make sure that the "active until" comes after the "active from"
  $.validator.addMethod('dateAfter', (value, element, other) => {
    const otherValue = element.form[other].value;
    if (!value || !otherValue) return true;
    return new Date(value) > new Date(otherValue);
  });

  form
    // Since the individual date/time inputs are not validated directly, we must intercept events, update the
    // combined datetime value and then validate it manually
    .on('blur keyup', '.datetime-input-group.validating', function () {
      updateHiddenDateTimeInput(this);
      const validator = $(this).closest('form').validate();
      validator.element($('input[type="hidden"]', this));
    })
    // Update the banner preview when the content changes
    .on('input change', '#bannerTitle, #bannerSeverity, #bannerText', updateBannerPreview)
    .on('submit', event => {
      event.preventDefault();
      updateHiddenDateTimeInput();
    })
    .on('invalid-form.validate', (_event, validator) => {
      // if the first invalid element is a hidden date-time input it cannot be focused, so we need to focus
      // the sibling date input instead
      const element = $(
        validator.findLastActive() || (validator.errorList.length && validator.errorList[0].element) || []
      );
      if (element.is('.datetime-input-group > input[type="hidden"]')) {
        element.siblings('input[type="date"]').trigger('focus').trigger('focusin');
      }
    })
    .validate({
      // don't validate date and time inputs directly, validate the hidden date-time input instead
      ignore: '.datetime-input-group input[type=date], .datetime-input-group input[type=time]',
      rules: {
        bannerActiveFrom: {
          required: true,
        },
        bannerActiveUntil: {
          required: true,
          dateAfter: 'bannerActiveFrom',
        },
      },
      messages: {
        bannerActiveUntil: {
          dateAfter: 'The "active until" date must be after the "active from" date.',
        },
      },
      // Custom error placement since we're using input groups with "addons" as labels,
      // error messages need to be placed after input group, not inside it
      errorPlacement: (errorLabel, element) => {
        const parent = $(element).parent();
        errorLabel.addClass('invalid-feedback').insertAfter(parent.hasClass('input-group') ? parent : element);
      },
      // Some custom setup of error classes:
      // - error class needs to be set on the form group so the entire group is highlighted
      // - we don't want the form all colored up before we first hit submit
      // - red inputs should turn to normal while typing when the input is valid
      errorClass: 'validating is-invalid',
      validClass: 'validating',
      highlight: (element, errorClass, validClass) => {
        const toHighlight = element.type === 'hidden' ? $(element).closest('.input-group') : $(element);
        toHighlight.removeClass(validClass).addClass(errorClass);
      },
      unhighlight: (element, errorClass, validClass) => {
        const toHighlight = element.type === 'hidden' ? $(element).closest('.input-group') : $(element);
        toHighlight.removeClass(errorClass).addClass(validClass);
      },
      submitHandler: formData => {
        const bannerInput = {
          title: formData.bannerTitle.value,
          severity: formData.bannerSeverity.value,
          text: formData.bannerText.value,
          start_time: new Date(formData.bannerActiveFrom.value).toISOString(),
          end_time: new Date(formData.bannerActiveUntil.value).toISOString(),
        };
        bannerOperations.createBanner(bannerInput).then(hideForm);
      },
    });

  $('#btnCancelCreateBanner').on('click', hideForm);
}

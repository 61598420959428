import { baseApiAxios } from './js/portal/api';
import { loadSeriesTile, setTimeframeSelectOptions } from './js/portal/statistics';
import { addSpinner, removeSpinners } from './js/portal/sidebar';
import TileSeries from './jsx/components/tiles/TileSeries';

export default async function StatisticsSeriesPage() {
  let timeframeSelectElements = {};
  let orderActionsData = {},
    accountAreaData = {},
    orderTypeData = {},
    orderMarketplaceData = {};
  let dropdownOptionsChart1 = [],
    dropdownOptionsChart2 = [],
    dropdownOptionsChart4 = [];

  const page = (
    <div id="dashboard_overview_account">
      <div class="row mb-3">
        <div class="col">
          <div class="dashboard-title d-inline-block">
            <h3 class="overview" style="line-height:32px;margin-bottom:0;">
              Series Statistics
            </h3>
          </div>
          <div id="network-usage-dashboard-status" class="d-inline-block"></div>
        </div>
        <div class="col-auto text-right">
          <span id="chart-1-loading" class="d-inline-block loading-form-inline mx-3">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </span>
          <span class="d-inline-block portal-dropdown mx-1">
            <select class="form-control form-select" id="chart-timeframe-select-start-1">
              <option disabled selected>
                Month
              </option>
            </select>
          </span>
          <span class="mx-1">-</span>
          <span class="d-inline-block portal-dropdown mx-1">
            <select class="form-control form-select" id="chart-timeframe-select-end-1">
              <option disabled selected>
                Month
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="row">
        <TileSeries
          id="number-of-orders"
          title="Number of Orders"
          headline="by Time"
          color="#EDD051"
          values={null}></TileSeries>
      </div>
      <div class="row mb-3">
        <div class="col"></div>
        <div class="col-auto text-right">
          <span id="chart-2-loading" class="d-inline-block loading-form-inline mx-3">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </span>
          <span class="d-inline-block portal-dropdown mx-1">
            <select class="form-control form-select" id="chart-timeframe-select-start-2">
              <option disabled selected>
                Month
              </option>
            </select>
          </span>
          <span class="mx-1">-</span>
          <span class="d-inline-block portal-dropdown mx-1">
            <select class="form-control form-select" id="chart-timeframe-select-end-2">
              <option disabled selected>
                Month
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="row">
        <TileSeries
          id="number-of-active-accounts"
          title="Number of Active Accounts"
          headline="by Time"
          color="#B6C75A"
          values={null}></TileSeries>
      </div>
      <div class="row mb-3">
        <div class="col"></div>
        <div class="col-auto text-right">
          <span id="chart-3-loading" class="d-inline-block loading-form-inline mx-3">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </span>
          <span class="d-inline-block portal-dropdown mx-1">
            <select class="form-control form-select" id="chart-timeframe-select-3">
              <option disabled selected>
                Month
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="row">
        <TileSeries
          id="number-of-orders-by-type"
          title="Number of Orders"
          headline="by Type"
          values={null}></TileSeries>
      </div>
      <div class="row mb-3">
        <div class="col"></div>
        <div class="col-auto text-right">
          <span id="chart-4-loading" class="d-inline-block loading-form-inline mx-3">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </span>
          <span class="d-inline-block portal-dropdown mx-1">
            <select class="form-control form-select" id="chart-timeframe-select-start-4">
              <option disabled selected>
                Month
              </option>
            </select>
          </span>
          <span class="mx-1">-</span>
          <span class="d-inline-block portal-dropdown mx-1">
            <select class="form-control form-select" id="chart-timeframe-select-end-4">
              <option disabled selected>
                Month
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="row">
        <TileSeries
          id="number-of-marketplace-orders"
          title="Number of Marketplace Role Orders"
          headline="by Time"
          color="#4FA484"
          values={null}></TileSeries>
      </div>
    </div>
  );

  async function onPageReady() {
    timeframeSelectElements = {
      'chart-1': {
        start: document.getElementById('chart-timeframe-select-start-1'),
        end: document.getElementById('chart-timeframe-select-end-1'),
      },
      'chart-2': {
        start: document.getElementById('chart-timeframe-select-start-2'),
        end: document.getElementById('chart-timeframe-select-end-2'),
      },
      'chart-3': document.getElementById('chart-timeframe-select-3'),
      'chart-4': {
        start: document.getElementById('chart-timeframe-select-start-4'),
        end: document.getElementById('chart-timeframe-select-end-4'),
      },
    };

    loadOrderActionsSeriesData('monthly', true);
    loadAccountAreaSeriesData('monthly', true);
    loadOrderTypeSeriesData('monthly');
    loadMarketplaceOrderSeriesData('monthly', true);

    const chart1Listener = () => {
      loadOrderActionsSeriesData('monthly');
    };
    const chart2Listener = () => {
      loadAccountAreaSeriesData('monthly');
    };
    const chart3Listener = evt => {
      loadOrderTypeSeriesData('monthly', evt.target.value);
    };
    const chart4Listener = () => {
      loadMarketplaceOrderSeriesData('monthly');
    };

    timeframeSelectElements['chart-1']['start'].addEventListener('change', evt => {
      const elementIndex = dropdownOptionsChart1.findIndex(item => item === evt.target.value);
      setTimeframeSelectOptions(
        timeframeSelectElements['chart-1']['end'],
        dropdownOptionsChart1,
        dropdownOptionsChart1.slice(0, Math.min(elementIndex + 1, dropdownOptionsChart1.length - 1))
      );
      chart1Listener();
    });
    timeframeSelectElements['chart-1']['end'].addEventListener('change', chart1Listener);
    timeframeSelectElements['chart-2']['start'].addEventListener('change', evt => {
      const elementIndex = dropdownOptionsChart1.findIndex(item => item === evt.target.value);
      setTimeframeSelectOptions(
        timeframeSelectElements['chart-2']['end'],
        dropdownOptionsChart2,
        dropdownOptionsChart2.slice(0, Math.min(elementIndex + 1, dropdownOptionsChart2.length - 1))
      );
      chart2Listener();
    });
    timeframeSelectElements['chart-2']['end'].addEventListener('change', chart2Listener);
    timeframeSelectElements['chart-3'].addEventListener('change', chart3Listener);
    timeframeSelectElements['chart-4']['start'].addEventListener('change', evt => {
      const elementIndex = dropdownOptionsChart4.findIndex(item => item === evt.target.value);
      setTimeframeSelectOptions(
        timeframeSelectElements['chart-4']['end'],
        dropdownOptionsChart4,
        dropdownOptionsChart4.slice(0, Math.min(elementIndex + 1, dropdownOptionsChart4.length - 1))
      );
      chart4Listener();
    });
    timeframeSelectElements['chart-4']['end'].addEventListener('change', chart4Listener);
  }

  async function loadOrderActionsSeriesData(timeframe, loadOptions) {
    addSpinner();
    document.getElementById('chart-1-loading')?.classList.add('loading-animation');

    try {
      orderActionsData = await baseApiAxios.get(
        `/stats/categories/orders/statistics/actions?time_frame=${timeframe}`,
        null,
        {}
      );

      if (orderActionsData?.stats?.length) {
        let chartData = {};
        dropdownOptionsChart1 = [];
        let selectedRange = [];
        orderActionsData.stats.map(dataset => {
          chartData[dataset.sk] = dataset.data.count;
          dropdownOptionsChart1.push(dataset.sk);
        });

        if (loadOptions) {
          setTimeframeSelectOptions(timeframeSelectElements['chart-1']['start'], dropdownOptionsChart1);
          timeframeSelectElements['chart-1']['start']['value'] = dropdownOptionsChart1[0];
          setTimeframeSelectOptions(timeframeSelectElements['chart-1']['end'], dropdownOptionsChart1);
          timeframeSelectElements['chart-1']['end']['value'] =
            dropdownOptionsChart1.length > 10
              ? dropdownOptionsChart1[11]
              : dropdownOptionsChart1[dropdownOptionsChart1.length - 1];
        }

        const startIndex = dropdownOptionsChart1.findIndex(
          item => item === timeframeSelectElements['chart-1']['start']['value']
        );
        const endIndex = dropdownOptionsChart1.findIndex(
          item => item === timeframeSelectElements['chart-1']['end']['value']
        );
        selectedRange = dropdownOptionsChart1.slice(startIndex, endIndex + 1);

        let selectedChartData = {};
        selectedRange.forEach(sk => (selectedChartData[sk] = chartData[sk]));

        loadSeriesTile('number-of-orders', 'Number of Orders', 'by Time', selectedChartData, '#EDD051');
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadAccountAreaSeriesData(timeframe, loadOptions) {
    addSpinner();
    document.getElementById('chart-2-loading')?.classList.add('loading-animation');

    try {
      accountAreaData = await baseApiAxios.get(
        `/stats/categories/accounts/statistics/account_area?time_frame=${timeframe}`,
        null,
        {}
      );

      if (accountAreaData?.stats?.length) {
        let chartData = {};
        dropdownOptionsChart2 = [];
        let selectedRange = [];
        accountAreaData.stats.map(dataset => {
          let activeAccounts = 0;
          Object.values(dataset.data).forEach(obj => {
            activeAccounts += obj?.active ? obj.active.count : 0;
          });
          chartData[dataset.sk] = activeAccounts;
          dropdownOptionsChart2.push(dataset.sk);
        });

        if (loadOptions) {
          setTimeframeSelectOptions(timeframeSelectElements['chart-2']['start'], dropdownOptionsChart2);
          timeframeSelectElements['chart-2']['start']['value'] = dropdownOptionsChart2[0];
          setTimeframeSelectOptions(timeframeSelectElements['chart-2']['end'], dropdownOptionsChart2);
          timeframeSelectElements['chart-2']['end']['value'] =
            dropdownOptionsChart2.length > 10
              ? dropdownOptionsChart2[11]
              : dropdownOptionsChart2[dropdownOptionsChart2.length - 1];
        }

        const startIndex = dropdownOptionsChart2.findIndex(
          item => item === timeframeSelectElements['chart-2']['start']['value']
        );
        const endIndex = dropdownOptionsChart2.findIndex(
          item => item === timeframeSelectElements['chart-2']['end']['value']
        );
        selectedRange = dropdownOptionsChart2.slice(startIndex, endIndex + 1);

        let selectedChartData = {};
        selectedRange.forEach(sk => (selectedChartData[sk] = chartData[sk]));

        loadSeriesTile(
          'number-of-active-accounts',
          'Number of active Accounts',
          'by Time',
          selectedChartData,
          '#B6C75A'
        );
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadOrderTypeSeriesData(timeframe, sk) {
    addSpinner();
    document.getElementById('chart-3-loading')?.classList.add('loading-animation');

    try {
      orderTypeData = await baseApiAxios.get(
        `/stats/categories/orders?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {}
      );

      let stateLabels = ['new', 'in_progress', 'failed', 'success'];
      if (orderTypeData?.stats?.length) {
        let skParam = sk;
        let dropdownOptionsObj = {};
        if (!sk) {
          const year = Math.max(...orderTypeData.stats.map(stat => stat.year));
          const month = Math.max(...orderTypeData.stats.map(stat => stat.year === year && stat.month));
          skParam = `${year}-${month}`;
          orderTypeData.stats.forEach(stat => (dropdownOptionsObj[stat.sk] = true));
          setTimeframeSelectOptions(timeframeSelectElements['chart-3'], Object.keys(dropdownOptionsObj));
        }

        let chartData = {};
        const filteredDatasets = orderTypeData.stats.filter(item => item.sk === skParam && item.stat !== 'actions');
        if (filteredDatasets.length) {
          filteredDatasets.forEach(dataset => {
            let dataObj = {};
            stateLabels.forEach(label => {
              dataObj[label] = dataset.data[label];
            });
            chartData[dataset.stat] = Object.values(dataObj).reduce((a, b) => a + b);
          });
        } else {
          chartData = null;
        }

        loadSeriesTile('number-of-orders-by-type', 'Number of Orders', 'by Type', chartData, null, true, true, true);
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadMarketplaceOrderSeriesData(timeframe, loadOptions) {
    addSpinner();
    document.getElementById('chart-4-loading')?.classList.add('loading-animation');

    try {
      orderMarketplaceData = await baseApiAxios.get(
        `/stats/categories/orders/statistics/grant-purchase-role?time_frame=${timeframe}`,
        null,
        {}
      );

      if (orderMarketplaceData?.stats?.length) {
        let chartData = {};
        dropdownOptionsChart4 = [];
        let selectedRange = [];
        orderMarketplaceData.stats.map(dataset => {
          chartData[dataset.sk] = dataset.data.count;
          dropdownOptionsChart4.push(dataset.sk);
        });

        if (loadOptions) {
          setTimeframeSelectOptions(timeframeSelectElements['chart-4']['start'], dropdownOptionsChart4);
          timeframeSelectElements['chart-4']['start']['value'] = dropdownOptionsChart4[0];
          setTimeframeSelectOptions(timeframeSelectElements['chart-4']['end'], dropdownOptionsChart4);
          timeframeSelectElements['chart-4']['end']['value'] =
            dropdownOptionsChart4.length > 10
              ? dropdownOptionsChart4[11]
              : dropdownOptionsChart4[dropdownOptionsChart4.length - 1];
        }

        const startIndex = dropdownOptionsChart4.findIndex(
          item => item === timeframeSelectElements['chart-4']['start']['value']
        );
        const endIndex = dropdownOptionsChart4.findIndex(
          item => item === timeframeSelectElements['chart-4']['end']['value']
        );
        selectedRange = dropdownOptionsChart4.slice(startIndex, endIndex + 1);

        let selectedChartData = {};
        selectedRange.forEach(sk => (selectedChartData[sk] = chartData[sk]));

        loadSeriesTile(
          'number-of-marketplace-orders',
          'Number of Marketplace Role Orders',
          'by Time',
          selectedChartData,
          '#4FA484'
        );
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  return [page, onPageReady];
}

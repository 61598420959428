import { apiAxios, showError } from './api';
import { addSpinner, removeSpinners } from './sidebar';
import swal from 'sweetalert';

/**
 * Initialize/resets the whole training form
 * @param {string} id
 */
export function initPlanTrainingForm(id) {
  // Deselect the type and trainers selectpicker
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');

  // Reset the start and the end date
  $('#start-date-' + id).val('');
  $('#end-date-' + id).val('');

  // Set the training start and end date
  setTrainingDates({ id: id });

  // Retrieve and set the min/max number of AWS accounts
  setTrainingAccountCount({ id: id, setValue: true });

  // Clear the notes field
  $('#notes-' + id).val('');
}

/**
 *
 * @param {string} id
 * @param {string} selectedOption
 * @param {string} selectedName
 * @param {string} selectedDescription
 * @param {string} selectedPreparationDays
 * @param {string} selectedCleanupDays
 */
export function updatePreparationAndCleanupDates(
  id,
  _selectedOption,
  _selectedName,
  _selectedDescription,
  selectedPreparationDays,
  selectedCleanupDays
) {
  setTrainingDates({ id: id, preparationDays: selectedPreparationDays, cleanupDays: selectedCleanupDays });
}

/**
 * Initialize the training start and end dates incl. new min and max values
 * based on the current date
 *
 * @param {string} id
 * @param {string} preparationDays
 * @param {string} cleanupDays
 */
export function setTrainingDates({ id, preparationDays, cleanupDays }) {
  // Get current date and create ISO date string
  const maxDaysInFuture = 365;

  // Configure the min start date of the training, which is today + 2 days
  const minStartDate = new Date();
  minStartDate.setDate(minStartDate.getDate() + 2);
  const minStartDateString = minStartDate.toISOString().substring(0, 10);

  // Configure the start date of the training
  let startDateString = $('#start-date-' + id).val();
  const startDate = startDateString ? new Date(startDateString) : new Date();
  if (!startDateString) {
    startDate.setDate(startDate.getDate() + 2);
  }
  startDateString = startDate.toISOString().substring(0, 10);

  // Configure the maximum date in the future (up to a year)
  const maxDate = new Date();
  maxDate.setDate(startDate.getDate() + maxDaysInFuture);
  const maxDateString = maxDate.toISOString().substring(0, 10);
  $('#start-date-' + id).val(startDateString);
  $('#start-date-' + id).prop('min', minStartDateString);
  $('#start-date-' + id).prop('max', maxDateString);

  // Configure the preparation date, x days before the training actually starts
  if (preparationDays) {
    const preparationDate = new Date();
    preparationDate.setDate(startDate.getDate() - parseInt(preparationDays));
    const preparationDateString = preparationDate.toISOString().substring(0, 10);
    $('#preparation-date-' + id).val(preparationDateString);
  }

  // Get end date (up to a year in the future) and create ISO date string
  let endDateString = $('#end-date-' + id).val();
  const endDate = endDateString ? new Date(endDateString) : new Date();
  if (!endDateString) {
    endDate.setDate(endDate.getDate() + 2);
  }
  endDateString = endDate.toISOString().substring(0, 10);
  $('#end-date-' + id).val(endDateString);
  $('#end-date-' + id).prop('min', startDateString);
  $('#end-date-' + id).prop('max', maxDateString);

  // Configures the cleanup date, x days after the training actually ends
  if (cleanupDays) {
    const cleanupDate = new Date();
    cleanupDate.setDate(endDate.getDate() + parseInt(cleanupDays));
    const cleanupDateString = cleanupDate.toISOString().substring(0, 10);
    $('#cleanup-date-' + id).val(cleanupDateString);
  }
}

/**
 * Sets the end date (min) value to the start date as the end date must be after the start date
 *
 * @param {string} str
 */
export function setTrainingEndDate({ id }) {
  const startDateString = $('#start-date-' + id).val();
  const endDateString = $('#end-date-' + id).val();

  if (startDateString > endDateString) {
    $('#end-date-' + id).val(startDateString);
    $('#end-date-' + id).prop('min', startDateString);
  }

  $('#end-date-' + id).trigger('change');
}

/**
 * Retrieve the number of available AWS training accounts to update the
 * configuration of the AWS accounts field input
 *
 * @param {string} id
 * @param {boolean} setValue
 */
export function setTrainingAccountCount({ id, setValue, startDate, endDate, trainingId }) {
  const params = {
    start_date: startDate ?? $('#start-date-' + id).val(),
    end_date: endDate ?? $('#end-date-' + id).val(),
    training_ids: [trainingId].join(','),
  };
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };

  const selectedOption = $('#type-' + id).find(':selected');
  const selectedPreparationDays = selectedOption.attr('training-preparation-days');
  const selectedCleanupDays = selectedOption.attr('training-cleanup-days');
  setTrainingDates({ id: id, preparationDays: selectedPreparationDays, cleanupDays: selectedCleanupDays });

  addSpinner();

  // GET request to get the maximum number of available training accounts
  apiAxios
    .get('/trainings/accounts/count', { headers, params })
    .then(getCountResponse => {
      try {
        // Set the current value and the min and max value of the input field
        const maxNumber = parseInt(getCountResponse.data.training_accounts_count);

        if (setValue) {
          $('#number-accounts-' + id).val(maxNumber > 0 ? 1 : 0);
        }

        $('#number-accounts-' + id).prop('max', maxNumber > 20 ? 20 : maxNumber);
        $('#number-accounts-' + id).prop('min', maxNumber > 0 ? 1 : 0);

        if (maxNumber < 1) {
          $('#number-accounts-' + id).addClass('portal-danger');
          $('#number-accounts-' + id)
            .addClass('is-invalid')
            .removeClass('is-valid');
          $('#number-accounts-group-' + id)
            .addClass('is-invalid')
            .removeClass('is-valid');
        } else {
          $('#number-accounts-' + id).removeClass('portal-danger');
          $('#number-accounts-' + id)
            .removeClass('is-invalid')
            .addClass('is-valid');
          $('#number-accounts-group-' + id)
            .removeClass('is-invalid')
            .addClass('is-valid');
        }
      } catch (error) {
        // Fallback to the default values
        if (setValue) {
          $('#number-accounts-' + id).val(1);
        }
        $('#number-accounts-' + id).prop('max', 20);
        $('#number-accounts-' + id).prop('min', 1);
      }
    })
    .catch(getCountError => {
      showError(getCountError);
    })
    .finally(() => {
      removeSpinners();
    });
}

/**
 *
 * @param {string} id
 * @returns Array
 */
export function collectTrainingData(id) {
  // Prepare the payload for the post request
  return {
    type: $('#type-' + id).val(),
    start_date: $('#start-date-' + id).val(),
    end_date: $('#end-date-' + id).val(),
    accounts_requested: $('#number-accounts-' + id).val(),
    trainers: $('#trainers-' + id).val(),
    notes: $('#notes-' + id).val(),
  };
}

/**
 * Collects the target training details and creates a new training item
 *
 * @param {string} id
 */
export function planTraining(id) {
  const trainingPayload = collectTrainingData(id);

  // POST request to create a new training item
  return apiAxios
    .post('/trainings', trainingPayload, { headers: { 'Content-Type': 'application/json' } })
    .then(response => {
      swal({
        title: 'Plan Training',
        text: response.data.message,
        icon: response.status === 200 ? 'success' : 'error',
      });

      // Reset the training plan form
      initPlanTrainingForm();
    })
    .catch(error => {
      showError(error);
    });
}

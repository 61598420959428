import { baseApiAxios } from './js/portal/api';
import TileDonutChart from './jsx/components/tiles/TileDonutChart';
import TileText from './jsx/components/tiles/TileText';
import ChartHorizontalStacked from './jsx/components/tiles/ChartHorizontalStacked';
import ChartLegend from './jsx/components/tiles/ChartLegend';
import {
  defaultColors,
  loadDonutChart,
  loadOrderStatistics,
  loadStackedAbsoluteValuesChart,
  loadTextTile,
  setTimeframeSelectOptions,
} from './js/portal/statistics';
import { addSpinner, removeSpinners } from './js/portal/sidebar';

export default async function StatisticsAccountPage() {
  let timeframeSelect;
  let dropdownSelection, radioSelection;
  let accountStageData = {},
    accountAreaData = {},
    orgIdData = {};

  const page = (
    <div id="dashboard_overview_account">
      <div class="row mb-3">
        <div class="col">
          <div class="dashboard-title d-inline-block">
            <h3 class="overview" style="line-height:32px;margin-bottom:0;">
              Account Statistics
            </h3>
          </div>
          <div id="network-usage-dashboard-status" class="d-inline-block"></div>
        </div>
        <div class="col-auto text-right">
          <span class="d-inline-block loading-form-inline">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </span>
          <span class="d-inline-block portal-dropdown mx-3">
            <select class="form-control form-select" id="chart-timeframe-select" onchange={dropdownChangeListener}>
              <option disabled selected>
                No Data available
              </option>
            </select>
          </span>
          <span class="d-inline-block">
            <ul class="nav nav-pills chart-timeframe-switch">
              <li class="nav-item">
                <input
                  type="radio"
                  id="category-monthly"
                  name="category"
                  value="monthly"
                  onchange={radioChangeListener}
                  checked/>
                <label class="nav-link" for="category-monthly">
                  Monthly
                </label>
              </li>
              <li class="nav-item">
                <input type="radio" id="category-daily" name="category" value="daily" onchange={radioChangeListener} />
                <label class="nav-link" for="category-daily">
                  Daily
                </label>
              </li>
            </ul>
          </span>
        </div>
      </div>
      <div class="row">
        <TileText id="number-active-accounts" title="Active Accounts"></TileText>
        <TileText id="number-suspended-accounts" title="Suspended Accounts"></TileText>
        <TileText id="number-external-accounts" title="External Accounts"></TileText>
        <TileDonutChart
          id="accounts-by-area"
          title="Accounts by Area"
          labels={[]}
          colors={defaultColors}
          partial={26 / 30}></TileDonutChart>
        <TileText id="accounts-by-area-and-type" title="Number of Accounts" headline="by Type and Area">
          <ChartHorizontalStacked
            title=""
            hideLegend={true}
            values={[]}
            colors={defaultColors}></ChartHorizontalStacked>
          <ChartLegend labels={[]} colors={defaultColors}></ChartLegend>
        </TileText>
        <TileDonutChart
          id="accounts-by-stage"
          title="Accounts by Stage"
          labels={[]}
          colors={defaultColors}
          partial={26 / 30}></TileDonutChart>
        <TileDonutChart
          id="accounts-by-ou"
          title="Accounts by Org Unit"
          labels={[]}
          colors={defaultColors}
          partial={26 / 30}></TileDonutChart>
        <TileText id="create-account" title="Create Account"></TileText>
        <TileText id="account-close" title="Close Account"></TileText>
      </div>
    </div>
  );

  async function onPageReady() {
    timeframeSelect = document.getElementById('chart-timeframe-select');
    radioChangeListener();
  }

  function dropdownChangeListener(evt) {
    const value = evt.target.value;
    if (value) {
      dropdownSelection = value;
      loadDashboardData(radioSelection, dropdownSelection);
    }
  }

  async function radioChangeListener() {
    const value = document.querySelector(`.chart-timeframe-switch input[name="category"]:checked`)?.value;
    if (value) {
      radioSelection = value;
      dropdownSelection = null;
      const [initialOptions, initiallySelected] = await loadInitialData();
      setTimeframeSelectOptions(timeframeSelect, orgIdData.stats ? initialOptions : []);
      dropdownSelection = initiallySelected;

      if (!dropdownSelection) {
        loadEmptyTiles();
      } else {
        loadDashboardData(radioSelection, dropdownSelection);
      }
    }
  }

  // LOAD AND DISPLAY DATA

  async function loadInitialData() {
    await loadOrgIdData(radioSelection, dropdownSelection);
    if (!orgIdData?.stats?.length) return [['No Data available'], null];
    const year = Math.max(...orgIdData.stats.map(stat => stat.year));
    const month = Math.max(...orgIdData.stats.map(stat => stat.year === year && stat.month));
    const options = orgIdData.stats.map(stat => stat.sk);
    if (radioSelection === 'daily') {
      const day = Math.max(...orgIdData.stats.map(stat => stat.year === year && stat.month === month && stat.day));
      return [options, `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`];
    }
    return [options, `${year}-${('0' + month).slice(-2)}`];
  }

  function loadEmptyTiles() {
    loadTextTile('number-active-accounts', '-', null, true);
    loadTextTile('number-suspended-accounts', '-', null, true);
    loadTextTile('number-external-accounts', '-', null, true);
    loadTextTile('create-account', '-', null, true);
    loadTextTile('account-close', '-', null, true);
    loadDonutChart('accounts-by-area', 'Accounts by Area', {});
    loadDonutChart('accounts-by-type', 'Accounts by Type', {});
    loadStackedAbsoluteValuesChart(
      'accounts-by-area-and-type',
      'Number of Accounts',
      'by Type and Area',
      {},
      [],
      10,
      true,
      true
    );
    loadDonutChart('accounts-by-stage', 'Accounts by Stage', {});
    loadDonutChart('accounts-by-ou', 'Accounts by Org Unit', {});
  }

  function loadDashboardData(timeframe, sk) {
    loadOrderStatistics('create-account', 'orders', 'create-account', 'monthly', sk);
    loadOrderStatistics('account-close', 'orders', 'account-close', 'monthly', sk);
    loadAccountAreaData(timeframe, sk);
    loadAccountStageData(timeframe, sk);
    loadOrgIdData(timeframe, sk);
  }

  async function loadAccountAreaData(timeframe, sk) {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    try {
      accountAreaData = await baseApiAxios.get(
        `/stats/categories/accounts/statistics/account_area?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {}
      );

      if (accountAreaData?.stats?.length) {
        let chartData = {};
        let areaData = {};
        let activeAccounts = 0;
        let suspendedAccounts = 0;
        let typeData = {};

        Object.entries(accountAreaData.stats[0].data).forEach(([area, values]) => {
          if (area === 'count') return;
          let numberByType = {};
          if (values.active) {
            areaData[area] = values.active.count;
            activeAccounts += values.active.count;
            let activeAccountData = values.active;
            delete activeAccountData['count'];
            Object.entries(activeAccountData).forEach(([type, count]) => {
              typeData[type] = (typeData[type] || 0) + count;
              if (!numberByType[type]) numberByType[type] = {};
              numberByType[type][area] = count;
            });
          }
          if (values.inactive) {
            areaData[area] = (areaData[area] || 0) + values.inactive.count;
            suspendedAccounts += values.inactive.count;
            let inactiveAccountData = values.inactive;
            delete inactiveAccountData['count'];
            Object.entries(inactiveAccountData).forEach(([type, count]) => {
              typeData[type] = (typeData[type] || 0) + count;
              if (!chartData[type]) chartData[type] = { count: 0 };
              chartData[type][area] = { count: (numberByType[type]?.[area] || 0) + count };
              chartData[type]['count'] += chartData[type][area]['count'];
            });
          }
        });

        loadTextTile('number-active-accounts', activeAccounts, null, true);
        loadTextTile('number-suspended-accounts', suspendedAccounts, null, true);
        loadTextTile('number-external-accounts', typeData['external'], null, true);
        loadDonutChart('accounts-by-area', 'Accounts by Area', areaData);
        loadDonutChart('accounts-by-type', 'Accounts by Type', typeData);
        loadStackedAbsoluteValuesChart(
          'accounts-by-area-and-type',
          'Number of Accounts',
          'by Type and Area',
          chartData,
          Object.keys(areaData),
          Math.max(...Object.values(typeData)),
          true,
          true
        );
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadAccountStageData(timeframe, sk) {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    try {
      accountStageData = await baseApiAxios.get(
        `/stats/categories/accounts/statistics/account_stage?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {}
      );

      if (accountStageData?.stats?.length) {
        let chartData = {};
        Object.entries(accountStageData.stats[0].data)
          .filter(([_stage, values]) => values.count)
          .forEach(([stage, values]) => {
            chartData[stage] = values.count;
          });
        loadDonutChart('accounts-by-stage', 'Accounts by Stage', chartData);
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadOrgIdData(timeframe, sk) {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    try {
      orgIdData = await baseApiAxios.get(
        `/stats/categories/accounts/statistics/org_id?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {}
      );

      if (orgIdData?.stats?.length) {
        let chartData = orgIdData.stats[0].data;
        delete chartData['count'];
        loadDonutChart('accounts-by-ou', 'Accounts by Org Unit', chartData);
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  return [page, onPageReady];
}

import { getADUserNames } from './js/portal/databases';
import { enableAddDlMember } from './js/portal/distribution_list_wizard';

export default function DistributionListWizard() {
  const component = (
    <>
      {/* selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/ */}
      <div class="form-group px-3 pt-3" id="add-new-member-div" style="display: none">
        {/* selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/ */}
        <div class="row">
          <div class="col input-group">
            <select
              required
              class="form-control form-select selectpicker"
              data-live-search="true"
              data-show-subtext="true"
              aria-describedby="dl-invalid-feedback"
              id="user-email"
              name="user-email"
              data-size="5"
              data-none-selected-text="- Select one AD user -"
              data-selected-text-format="count > 5"
              data-max-options="1"
              onchange={() => enableAddDlMember()}>
              <option value="" disabled>
                - Select an AD user -
              </option>
            </select>
            <button type="button" class="btn btn-success" id="add-dl-member-button" disabled>
              Add Member
            </button>
            <div class="invalid-feedback d-block" id="dl-invalid-feedback" />
          </div>
        </div>
      </div>

      <table
        id="table-dl-members"
        class="table table-hover dataTable row-border nowrap"
        style="width: 100%"
        role="grid"/>
    </>
  );

  function onReady() {
    getADUserNames('user-email');
  }

  return [component, onReady];
}

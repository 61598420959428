import UserDropdown from './jsx/components/forms/UserDropdown';
import WorkOrderTemplateDropdown from './jsx/components/forms/WorkOrderTemplateDropdown';
import WorkOrderGroupDropdown from './jsx/components/forms/WorkOrderGroupDropdown';
import { COGNITO } from './js/portal/auth';
import { baseApiAxios } from './js/portal/api';
import { showReleaseChecklistModal, updateIsoValue, updateIsoValueEOD } from './js/portal/work_orders';
import { displayInfoPopup, showErrorFromApiOperation } from './js/portal/main';
import { addButtonSpinner, addSpinners, removeSpinners } from './js/portal/sidebar';
import DatePickerField from './jsx/components/forms/DatePickerField';

export default function AdminWorkOrdersCreatePage() {
  const parse_jwt = function (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  let user_email = '';
  const cognito_user_data = COGNITO.userpool.getCurrentUser();
  if (cognito_user_data.keyPrefix) {
    const user_data = parse_jwt(
      cognito_user_data.storage[`${cognito_user_data.keyPrefix}.${cognito_user_data.username}.idToken`]
    );
    user_email = user_data.email.toLowerCase();
  }

  let prefilled_summary = '';
  let prefilled_description = '';
  let prefilled_request_manager = [];

  const write_template_values = function (
    _id,
    selectedOption,
    _selectedName,
    selectedSummary,
    selectedDescription,
    selectedRequestManager
  ) {
    if (selectedOption) {
      $('#summary').val(selectedSummary.slice(0, 95));
      $('#description').val(selectedDescription);
      $('#request_manager').selectpicker('val', selectedRequestManager);
      prefilled_summary = selectedSummary.slice(0, 95);
      prefilled_description = selectedDescription;
      prefilled_request_manager = [selectedRequestManager];
    }
  };

  const now_iso = new Date().toISOString();
  const now_date_value = now_iso.slice(0, 10);
  const now_time_value = now_iso.slice(11, 16);

  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form id="create_work_order_form" name="create_work_order_form" class="needs-validation" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Create New Work Order</div>

            {/* CARD BODY */}
            <div class="card-body">
              {/* WORK ORDER TEMPLATE */}
              <div class="form-group row">
                <label for="work_order_template" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Work Order Template</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <WorkOrderTemplateDropdown
                    label="none"
                    selectedValues={[]}
                    disableUrlParams
                    id="work_order_template"
                    onchange={write_template_values}/>
                </div>
              </div>

              {/* WORK ORDER REGION */}
              <div class="form-group row portal-dropdown no-buttons">
                <label for="region" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Work Order Region</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <select class="form-control form-select" id="region" name="region" required>
                    <option selected>Global and China</option>
                    <option>Global</option>
                    <option>China</option>
                  </select>
                </div>
              </div>

              {/* SCHEDULED START DATE */}
              <div class="form-group row">
                <label for="scheduled_start_date" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Scheduled Start</span>
                </label>
                <div class="col-md-12 col-lg-5 col-xl-5">
                  <DatePickerField
                    id="scheduled_start_date"
                    selectedValue={now_date_value}
                    minValue={now_date_value}
                    onchange={(id, selectedValue) => {
                      updateIsoValue(id, selectedValue);
                      $('#scheduled_start_time').val('00:00');
                      const end_date_field = $('#scheduled_end_date');
                      end_date_field.attr('min', selectedValue);
                      if (new Date(selectedValue).getTime() > new Date(end_date_field.val()).getTime()) {
                        end_date_field.val(selectedValue);
                        updateIsoValueEOD('scheduled_end_date', selectedValue);
                      }
                    }}/>
                  <input type="hidden" name="scheduled_start_date" value={now_iso} />
                </div>
                <div class="col-md-12 col-lg-4 col-xl-5">
                  <input type="text" id="scheduled_start_time" class="form-control" value={now_time_value} disabled />
                </div>
              </div>

              {/* SCHEDULED END DATE */}
              <div class="form-group row">
                <label for="scheduled_end_date" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Scheduled End</span>
                </label>
                <div class="col-md-12 col-lg-5 col-xl-5">
                  <DatePickerField
                    id="scheduled_end_date"
                    selectedValue={now_date_value}
                    minValue={now_date_value}
                    onchange={updateIsoValueEOD}/>
                  <input type="hidden" name="scheduled_end_date" value={`${now_date_value}T23:59:00.000Z`} />
                </div>
                <div class="col-md-12 col-lg-4 col-xl-5">
                  <input type="text" id="scheduled_end_time" class="form-control" value="23:59" disabled />
                </div>
              </div>

              {/* PRIORITY */}
              <div class="form-group row portal-dropdown no-buttons">
                <label for="priority" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Priority</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <select
                    class="form-control form-select selectpicker"
                    required
                    id="priority"
                    name="priority"
                    data-show-subtext="true">
                    <option value="Low" selected data-subtext="Currently no impact forecast. Target date agreed.">
                      Low
                    </option>
                    <option value="Medium" data-subtext="Impact will occur within the next days.">
                      Medium
                    </option>
                    <option
                      value="High"
                      data-subtext="Impact will occur by the end of the current business day/ current shift.">
                      High
                    </option>
                    <option
                      value="Critical"
                      data-subtext="Impact has already occured or will occur within the next hours.">
                      Critical
                    </option>
                  </select>
                </div>
              </div>

              {/* REQUEST MANAGER */}
              <div class="form-group row">
                <label for="request_manager" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Request Manager</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <UserDropdown
                    label="none"
                    selectedValues={prefilled_request_manager}
                    disableUrlParams
                    id="request_manager"/>
                </div>
              </div>

              {/* REQUEST ASSIGNEE SUPPORT GROUP */}
              <div class="form-group row">
                <label for="assignee_group" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Assig. Support Group</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <WorkOrderGroupDropdown label="none" selectedValues={[]} disableUrlParams id="assignee_group" />
                </div>
              </div>

              {/* REQUEST ASSIGNEE */}
              <div class="form-group row">
                <label for="assignee" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Request Assignee</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <UserDropdown label="none" selectedValues={[user_email]} disableUrlParams id="assignee" />
                </div>
              </div>

              {/* SUMMARY */}
              <div class="form-group row">
                <label for="summary" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Summary</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <input
                    type="text"
                    id="summary"
                    name="summary"
                    class="form-control"
                    placeholder="Summary"
                    title="Summary of the Work Order"
                    maxlength="95"
                    value={prefilled_summary}
                    required/>
                </div>
              </div>

              {/* DESCRIPTION */}
              <div class="form-group row">
                <label for="description" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Description</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <textarea rows="4" id="description" name="description" class="form-control">
                    {prefilled_description}
                  </textarea>
                </div>
              </div>
            </div>

            {/* CARD FOOTER */}
            <div class="card-footer" align="right">
              <button type="button" id="btn_create_work_order" class="btn btn-success">
                Create Work Order
              </button>
              <button type="button" id="btn-create-and-start-work-order" class="btn btn-success mx-2">
                Create and Start Work Order
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    $('#priority').selectpicker('refresh');

    const create_work_order_form = $('#create_work_order_form');

    const confirm_callback = status_info => {
      const payload = Object.assign(Object.fromEntries(new FormData(create_work_order_form[0]).entries()), status_info);
      addSpinners();
      addButtonSpinner();
      baseApiAxios
        .post('/workorders', payload)
        .then(response => {
          displayInfoPopup(
            `${response.message} Work Order Id: ${response.work_order.work_order_id}`,
            'Successfully created new Work Order'
          );
        })
        .catch(showErrorFromApiOperation('Work Order creation failed'))
        .finally(() => {
          removeSpinners();
        });
    };

    $('#btn_create_work_order').on('click', () => {
      showReleaseChecklistModal(() => {
        confirm_callback({
          status: 'Planning',
          status_reason: 'Accepted',
        });
      });
    });
    $('#btn-create-and-start-work-order').on('click', () => {
      showReleaseChecklistModal(() => {
        confirm_callback({
          status: 'In Progress',
          status_reason: 'Assigned',
        });
      });
    });
  }

  return [page, onPageReady];
}

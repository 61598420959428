import { Modal } from 'bootstrap';
import swal from 'sweetalert';
import WorkOrderTabs from '../../jsx/components/forms/WorkOrderTabs';
import { apiAxios, baseApiAxios } from './api';
import { addDataAndFormatFooter } from './databases';
import { initDataTable } from './datatable';
import {
  addCopyButton,
  configureTableColumns,
  displayInfoPopup,
  rowDetailsClickHandler,
  showErrorFromApiOperation,
} from './main';
import { addSpinner, addSpinners, addTableSpinner, removeSpinners } from './sidebar';

const checklist_rules_and_requirements = [
  'All new features and features affected by the change have been tested',
  'User documentation has been updated',
];

const checkbox_content = getCheckboxContent(
  'Before closing the Work Order, please make sure to complete the below tasks:',
  checklist_rules_and_requirements
);

export function showCompleteWorkOrderModal(callback_fct, callback_params, resolution) {
  removeSpinners();

  $('#sharedModalTitle').html('<strong>Complete Work Order</strong>');

  if (['Successful', 'Successful with Issues'].indexOf(resolution) !== -1) {
    $('#sharedModalContent').html(checkbox_content);

    const validateAndConfirm = () => {
      const checkboxes = $('#sharedModalContent input[type="checkbox"]');
      checkboxes.removeClass('is-invalid');
      for (const checkbox of Array.from(checkboxes)) {
        if (!checkbox.checked) {
          checkbox.classList.add('is-invalid');
          return;
        }
      }
      Modal.getInstance(modal_element).hide();
      const update_payload = {
        status: 'Completed',
        status_reason: resolution,
      };
      callback_fct(callback_params, update_payload);
    };

    const confirm_button = $(`<button type="button" class="btn btn-success">
      Confirm
    </button>`);
    confirm_button.on('click', validateAndConfirm);

    $('#sharedModalFooter').html([
      `<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
      Close
    </button>`,
      confirm_button,
    ]);
  } else if (resolution === 'Unsuccessful') {
    const warning_header = getWarningHeader('Please provide an explanation why the Work Order failed:');
    $('#sharedModalContent').html([
      warning_header,
      $(`<textarea id="explanation" name="explanation" class="form-control"></textarea>`),
    ]);

    const validateAndConfirm = () => {
      const explanation_textarea = $('#explanation');
      explanation_textarea.removeClass('is-invalid');
      if (!explanation_textarea.val()) {
        explanation_textarea.addClass('is-invalid');
        return;
      }
      const update_payload = {
        status: 'Completed',
        status_reason: resolution,
        explanation: explanation_textarea.val(),
      };
      Modal.getInstance(modal_element).hide();
      callback_fct(callback_params, update_payload);
    };

    const confirm_button = $(`<button type="button" class="btn btn-success">
      Confirm
    </button>`);
    confirm_button.on('click', validateAndConfirm);

    $('#sharedModalFooter').html([
      `<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
      Close
    </button>`,
      confirm_button,
    ]);
  } else {
    const resolution_content = $(`<div class="form-group row portal-dropdown no-buttons my-4">
      <label for="resolution" class="col-md-12 col-lg-3 col-xl-3 form-label-text">
        <span class="float-md-start float-lg-end mx-xl-3">Resolution</span>
      </label>
      <div class="col-md-12 col-lg-8 col-xl-8">
        <select class="form-control form-select" id="resolution" name="resolution" required>
          <option value="Successful" selected>Successful</option>
          <option value="Successful with Issues">Sucessful with Issues</option>
          <option value="Unsuccessful">Failed</option>
        </select>
      </div>
    </div>`);
    $('#sharedModalContent').html(resolution_content);

    const setResolutionAndProceed = () => {
      showCompleteWorkOrderModal(callback_fct, callback_params, $('#resolution').val());
    };

    const next_button = $(`<button type="button" class="btn btn-primary">
    Next
  </button>`);
    next_button.on('click', setResolutionAndProceed);

    $('#sharedModalFooter').html([
      `<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
      Close
    </button>`,
      next_button,
    ]);
  }

  const modal_element = document.getElementById('sharedModal');
  Modal.getInstance(modal_element).show();
}

export function showReleaseChecklistModal(confirm_callback) {
  removeSpinners();

  const modal_element = document.getElementById('sharedModal');

  $('#sharedModalTitle').html('<strong>Release Rules and Requirements</strong>');

  $('#sharedModalContent').html(checkbox_content);

  const validateAndConfirm = () => {
    const checkboxes = $('#sharedModalContent input[type="checkbox"]');
    checkboxes.removeClass('is-invalid');
    for (const checkbox of Array.from(checkboxes)) {
      if (!checkbox.checked) {
        checkbox.classList.add('is-invalid');
        return;
      }
    }
    Modal.getInstance(modal_element).hide();
    confirm_callback();
  };

  const confirm_button = $(`<button type="button" class="btn btn-success">
    Confirm
  </button>`);
  confirm_button.on('click', validateAndConfirm);

  $('#sharedModalFooter').html([
    `<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
    Close
  </button>`,
    confirm_button,
  ]);

  Modal.getInstance(modal_element).show();
}

function getCheckboxContent(headline, checkbox_labels) {
  const warning_header = getWarningHeader(headline);
  const checkbox_elements = [];

  for (const [idx, rule_text] of checkbox_labels.entries()) {
    checkbox_elements.push(
      $(`<div class="form-check mx-2">
        <input type="checkbox" id="rule_${idx}" name="rule_${idx}" class="form-check-input" />
        <label for="rule_${idx}" class="form-check-label">${rule_text}</label>
      </div>`)
    );
  }

  return [warning_header].concat(checkbox_elements);
}

function getWarningHeader(headline) {
  return $(`<div class="alert alert-danger px-3 py-2 mb-3">${headline}</div>`);
}

export const workOrderTableColumns = [
  { id: 'select_col' },
  { id: 'work_order_id_col', name: 'Work Order Id' },
  { id: 'summary_col', name: 'Summary' },
  { id: 'actual_start_date_col', name: 'Start Date' },
  { id: 'actual_end_date_col', name: 'End Date' },
  { id: 'assignee_col', name: 'Assignee' },
  { id: 'assignee_group_col', name: 'Assignee Group' },
  { id: 'request_manager_col', name: 'Request Manager' },
  { id: 'request_manager_group_col', name: 'Request Manager Group' },
  { id: 'region_col', name: 'Region' },
  { id: 'audit_updated_at_col', name: 'Last Update' },
  { id: 'audit_created_at_col', name: 'Creation Date' },
  { id: 'priority_col', name: 'Priority' },
  { id: 'status_col', name: 'Status' },
  { id: 'status_reason_col', name: 'Status Details' },
  { id: 'actions_col', name: 'Actions' },
];

const workOrderDropdownColumns = ['priority', 'status', 'status_reason', 'region'];
const workOrderSearchColumns = [
  'actual_end_date',
  'actual_start_date',
  'assignee_group',
  'assignee',
  'audit_created_at',
  'audit_updated_at',
  'request_manager_group',
  'request_manager',
  'scheduled_end_date',
  'scheduled_start_date',
  'summary',
  'work_order_id',
];

const workOrderAttributes = workOrderDropdownColumns.concat(workOrderSearchColumns);

export function updateWorkOrder(params, update_payload) {
  if (update_payload['status_reason'] && update_payload['status_reason'] !== 'Unsuccessful')
    update_payload['explanation'] = '';
  addSpinners();
  baseApiAxios
    .patch(`/workorders/${params.work_order_id}`, update_payload)
    .then(response => {
      displayInfoPopup(`Work Order Id: ${response.work_order.work_order_id}`, 'Successfully updated Work Order');
    })
    .catch(showErrorFromApiOperation('Work Order update failed'))
    .finally(() => {
      removeSpinners();
      const datatable_element = document.querySelector('.dataTables_scrollBody table.table.dataTable');
      loadWorkOrderData(datatable_element.id, null, true);
    });
}

function createActionsButtons(td, _cellData, rowData, _row, _col) {
  const buttons = $('<div class="table-action-button-group">');
  if (rowData.status !== 'Cancelled') {
    buttons.append(
      $(`<button class="btn-datatable" data-bs-toggle="tooltip" title="Complete Work Order">
          <i class="fas fa-check" />
        </button>`).on('click', () => {
        showCompleteWorkOrderModal(updateWorkOrder, { work_order_id: rowData.work_order_id });
      })
    );
  }
  if (['Completed', 'Cancelled'].indexOf(rowData.status) === -1) {
    buttons.append(
      $(`<button class="btn-datatable" data-bs-toggle="tooltip" title="Cancel Work Order">
          <i class="fas fa-ban" />
        </button>`).on('click', () => {
        const update_payload = {
          status: 'Cancelled',
          status_reason: 'Cancelled by Requester',
        };
        swal({
          title: 'Are you sure you want to update this Work Order?',
          text: `Id: ${rowData.work_order_id}`,
          icon: 'warning',
          buttons: {
            cancel: {
              text: 'Cancel',
              value: false,
              visible: true,
            },
            okay: {
              text: 'Accept',
              value: true,
              visible: true,
            },
          },
        }).then(function (response) {
          if (response) {
            updateWorkOrder({ work_order_id: rowData.work_order_id }, update_payload);
          }
        });
      })
    );
  }
  buttons.appendTo(td);
}

function renderDate(data) {
  try {
    return data.split('T')[0];
  } catch {
    return data;
  }
}

export const updateIsoValue = (id, selectedValue) => {
  try {
    $(`input[name="${id}"]`).val(new Date(selectedValue).toISOString());
  } catch (err) {
    // invalid date format
  }
};

export const updateIsoValueEOD = (id, selectedValue) => {
  try {
    let date_value = new Date(selectedValue);
    date_value.setTime(date_value.getTime() + (23 * 60 + 59) * 60 * 1000);
    $(`input[name="${id}"]`).val(date_value.toISOString());
  } catch (err) {
    // invalid date format
  }
};

export function initWorkOrdersTable(tableId) {
  configureTableColumns(tableId, workOrderTableColumns);

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        text: 'Reload Work Orders',
        ajaxReload: false,
        methodReload: loadWorkOrderData,
        titleAttr: 'Reload Work Orders (no-cache)',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
        width: '20px',
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'work_order_id',
        name: 'work_order_id_col',
        title: 'Work Order Id',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'summary',
        name: 'summary_col',
        title: 'Summary',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'actual_start_date',
        name: 'actual_start_date_col',
        title: 'Start Date',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'actual_end_date',
        name: 'actual_end_date_col',
        title: 'End Date',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'assignee',
        name: 'assignee_col',
        title: 'Assignee',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'assignee_group',
        name: 'assignee_group_col',
        title: 'Assignee Group',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'request_manager',
        name: 'request_manager',
        title: 'Request Manager',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'request_manager_group',
        name: 'request_manager_group',
        title: 'Request Manager Group',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'region',
        name: 'region_col',
        title: 'Region',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'audit_updated_at',
        name: 'audit_updated_at_col',
        title: 'Last Update',
        render: renderDate,
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'audit_created_at',
        name: 'audit_created_at_col',
        title: 'Creation Date',
        render: renderDate,
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'priority',
        name: 'priority_col',
        title: 'Priority',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status',
        name: 'status_col',
        title: 'Status',
        render: function (data) {
          let badge_type = 'secondary';
          switch (data) {
            case 'In-Progress':
              badge_type = 'primary';
              break;
            case 'Completed':
              badge_type = 'success';
              break;
            case 'Cancelled':
              badge_type = 'warning';
              break;
          }
          return `<span class="badge badge-${badge_type}">${data}</span>`;
        },
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status_reason',
        name: 'status_reason_col',
        title: 'Status Reason',
        render: function (data) {
          let badge_type = 'secondary';
          switch (data) {
            case 'Successful':
            case 'Successful with Issues':
              badge_type = 'success';
              break;
            case 'Unsuccessful':
              badge_type = 'danger';
              break;
            case 'Cancelled by Requester':
              badge_type = 'warning';
              break;
          }
          return `<span class="badge badge-${badge_type}">${data}</span>`;
        },
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        createdCell: createActionsButtons,
        class: 'details-edit',
        width: '50px',
      },
    ],
    null,
    {
      order: [[3, 'desc']],
    }
  );

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatWorkOrderRow });
  loadWorkOrderData(tableId);
}

function formatWorkOrderRow(row) {
  const data = row.data();
  row.child(<WorkOrderTabs data={data} />).show();
  row.child()[0].setAttribute('class', 'rowDetails');
  removeSpinners();
}

export function loadWorkOrderData(tableId, headers, forceReload) {
  $(() => addSpinner());
  $(() => addTableSpinner());

  if (forceReload) {
    headers = { ...headers, 'Cache-Control': 'max-age=0, must-revalidate' };
  }

  const params = { attribute_names: workOrderAttributes.join() };

  apiAxios
    .get('/workorders', { headers, params })
    .then(response => {
      addDataAndFormatFooter({
        tableId: tableId,
        dataArray: response.data.work_orders,
        tableColumns: workOrderTableColumns,
        dropdownColumns: workOrderDropdownColumns,
        searchColumns: workOrderSearchColumns,
      });
    })
    .catch(showErrorFromApiOperation('Error fetching Work Orders'))
    .finally(() => {
      removeSpinners();
    });
}

import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import Qs from 'qs';
import swal from 'sweetalert';
import { CONF } from './environment';
import { displayErrorPopup, displaySuccessPopup } from './main';
import { getSearchParamsUrl } from './search_params';

const COGNITO = {
  user: {},
  userpool: new CognitoUserPool({
    UserPoolId: CONF.userPoolId,
    ClientId: CONF.clientId,
  }),
};

export { COGNITO };

let loginProvider = localStorage.getItem('loginProvider');

export function setLoginProvider(newLoginProvider) {
  if (newLoginProvider) {
    localStorage.setItem('loginProvider', newLoginProvider);
  } else {
    localStorage.removeItem('loginProvider');
  }
  loginProvider = newLoginProvider;
}

export function getLoginViaWebEAMUrl(target, search) {
  // Encode the target page after login in state so we will have it available after the redirect
  const encodedTarget = target ? btoa(JSON.stringify({ target })) : undefined;
  const encodedSearch = search ? btoa(JSON.stringify({ search })) : undefined;
  const state = {
    target: encodedTarget,
    search: encodedSearch,
  };
  const encodedState = btoa(JSON.stringify(state));
  const query = {
    ...CONF.webEAMAuthorizeParams,
    state: encodedState,
  };
  return `https://${CONF.authDomain}/oauth2/authorize?${Qs.stringify(query)}`;
}

/**
 * Use the Cognito Auth Code to retrieve JSON Web Tokens (JWT) from the Cognito Auth Endpoint
 * @param {string} code authorization code
 */
export async function initJwt(code) {
  const response = await fetch(CONF.authEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: Qs.stringify({
      grant_type: 'authorization_code',
      client_id: CONF.clientId,
      redirect_uri: CONF.redirectUrl,
      code: code,
    }),
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  storeJwtData(await response.json());
}

/**
 * In order to keep the table settings and the visibility of the overviews (e.g. dashboard login and active AWS
 * accounts) we store all localStorage items with given prefixes, clear the whole localStorage to get rid of the login
 * information and restore the values that we wanted to keep.
 */
function clearStorage() {
  // Temporary storage of the target localStorage values
  const keeper = {};
  // Prefixes of localStorage values that we want to keep
  const keeperPrefixes = ['DataTables_', 'hidden_', 'customer_portal_aws_', 'quick_links_'];

  // Create a copy of the localStorage items
  keeperPrefixes.forEach(prefix => {
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(prefix)) {
        keeper[key] = localStorage.getItem(key);
      }
    });
  });

  // Clear the whole localStorage
  localStorage.clear();
  sessionStorage.clear();

  // Restore the localStorage items that we wanted to keep
  for (const [key, value] of Object.entries(keeper)) {
    localStorage.setItem(key, value);
  }
}

/**
 * Store the JWT data in the Browser Local Storage
 * @param {object} jwt the JSON web token (JWT)
 * @param {string} jwt.access_token
 * @param {string} jwt.refresh_token
 * @param {string} jwt.id_token
 */
function storeJwtData(jwt) {
  const tokenPayload = JSON.parse(atob(jwt.id_token.split('.')[1]));

  let userId = tokenPayload.identities[0].userId;
  // Some features still look for the permissions in local storage
  localStorage.setItem('permissions', tokenPayload.portal_permissions);
  // Some features look for the account areas in local storage. We don't actually need them for the sidebar
  localStorage.setItem('account_areas', tokenPayload.account_areas);
  localStorage.setItem('CognitoIdentityServiceProvider.' + CONF.clientId + '.LastAuthUser', userId);
  localStorage.setItem(
    'CognitoIdentityServiceProvider.' + CONF.clientId + '.' + userId + '.accessToken',
    jwt.access_token
  );
  localStorage.setItem(
    'CognitoIdentityServiceProvider.' + CONF.clientId + '.' + userId + '.refreshToken',
    jwt.refresh_token
  );
  localStorage.setItem('CognitoIdentityServiceProvider.' + CONF.clientId + '.' + userId + '.idToken', jwt.id_token);
}

export function change_password(old_pw, new_pw, callback) {
  let cognitoUser = COGNITO.userpool.getCurrentUser();
  let username = cognitoUser.getUsername();

  let authenticationData = {
    Username: username,
    Password: old_pw,
  };

  let authenticationDetails = new AuthenticationDetails(authenticationData);

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function () {
      cognitoUser.changePassword(old_pw, new_pw, function (err, result) {
        if (err) {
          callback(err);
          return;
        }
        callback(result);
      });
    },
    onFailure: function (err) {
      callback(err);
    },
  });
}

function errorAlertCallback(err) {
  if (err) {
    alert(err);
  } else {
    window.open('#success', '_self');
  }
}

export function cognito_signup() {
  const email = document.getElementById('username').value;
  const password = document.getElementById('password').value;
  const passwordRepeat = document.getElementById('password-repeat').value;

  if (password === passwordRepeat) {
    const attributeList = [new CognitoUserAttribute({ Name: 'email', Value: email })];

    COGNITO.userpool.signUp(email, password, attributeList, null, errorAlertCallback);
  } else {
    alert("Passwords don't match - please enter your passwords again");
  }
}

export function cognito_confirm() {
  let email = document.getElementById('email').value;
  let userData = {
    Username: email,
    Pool: COGNITO.userpool,
  };
  let cognitoUser = new CognitoUser(userData);
  let confirmcode = document.getElementById('token').value;
  cognitoUser.confirmRegistration(confirmcode, true, errorAlertCallback);
}

export function cognito_login(callbackFunction) {
  setLoginProvider('cognito');
  let email = document.getElementById('username').value;
  let password = document.getElementById('password').value;
  let authenticationData = {
    Username: email,
    Password: password,
  };
  let authenticationDetails = new AuthenticationDetails(authenticationData);
  let userData = {
    Username: email,
    Pool: COGNITO.userpool,
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: callbackFunction,
    onFailure: function (err) {
      alert(err);
    },
  });
}

export function cognito_reset_password() {
  let email = document.getElementById('username').value;
  let userData = {
    Username: email,
    Pool: COGNITO.userpool,
  };
  let cognitoUser = new CognitoUser(userData);

  cognitoUser.forgotPassword({
    onSuccess: function (data) {
      // successfully initiated reset password request
      console.debug('CodeDeliveryData from forgotPassword: ' + data);
    },
    onFailure: function (err) {
      if (err.code === 'UserNotFoundException') {
        const content = $('<div>').append(
          'Your user ',
          $('<strong>').text(email),
          ' is probably not registered for this portal yet. Please try to register your email using the "Register here" link'
        );

        swal({
          title: 'User not found',
          content: content.get(),
          icon: 'error',
        });
      } else {
        displayErrorPopup(err);
      }
    },
    //Optional automatic callback
    inputVerificationCode: function () {
      swal({
        icon: 'info',
        title: 'Verification Code',
        closeOnClickOutside: false,
        content: {
          element: 'input',
          attributes: {
            placeholder: 'Enter your verification code',
            type: 'text',
          },
        },
        button: 'Next',
      })
        .then(function (verificationCode) {
          console.debug(verificationCode);

          if (verificationCode === '') {
            throw Error('You need to enter the verification code!');
          }

          swal({
            icon: 'info',
            title: 'New Password',
            closeOnClickOutside: false,
            content: {
              element: 'input',
              attributes: {
                placeholder: 'Enter your new password',
                type: 'password',
              },
            },
          }).then(function (newPassword) {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
              onSuccess: function () {
                displaySuccessPopup('Password reset was successful', 'Password reset').then(function () {
                  window.open('login.html', '_self');
                });
              },
              onFailure: function (err) {
                const err_content = document.createElement('div');
                err_content.innerHTML =
                  err.message +
                  '<br><br> <strong>Cognito Password Policy:</strong><br>' +
                  'Require numbers<br>' +
                  'Require special characters<br>' +
                  'Require uppercase letters<br>' +
                  'Require lowercase letters<br>';

                swal({
                  title: 'Password reset error',
                  content: err_content,
                  icon: 'error',
                });
              },
            });
          });
        })
        .catch(displayErrorPopup);
    },
  });
}

/**
 * Interceptor for axios to intercept requests before they are handled by then or catch.
 * Here you can find more information about the interceptors https://axios-http.com/docs/interceptors
 *
 * @param {object} config
 * @returns {object}
 */
export function authInterceptor(config) {
  return getCognitoAuthToken()
    .then(token => {
      config.headers.Authorization = token;
      return config;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

/**
 * Function to retrieve the access token for all API calls incl. the wrapper
 * to handle the logout if the access token and refresh token aren't valid anymore.
 *
 * @returns {string[]}
 */
export function getCognitoAuthToken() {
  return getCognitoSession()
    .then(session => session.getIdToken().getJwtToken())
    .catch(receivedExpiredCognitoSession);
}

/**
 * Function to retrieve the current Cognito user session. If there's a valid session,
 * set the display name of the user in the user menu.
 */
function getCognitoSession() {
  return new Promise((resolve, reject) => {
    const cognitoUser = COGNITO.userpool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        }

        // Set the display username in the header navigation bar
        COGNITO.user = session.idToken.payload;
        $('.display-username').text(session.idToken.payload.email);
        $('.display-auth-provider').text(session.idToken.payload.identities ? '' : '(Cognito)');

        // Return the actual Cognito user session
        resolve(session);
      });
    } else {
      reject(new Error('Unauthenticated'));
    }
  });
}

/**
 * Show a notification popup if the Cognito user session has expired and
 * trigger the logout/redirect to the login page
 *
 * @param {object} err
 * @returns
 */
export function receivedExpiredCognitoSession() {
  return new Promise((_resolve, _reject) => {
    swal({
      title: 'Session Expired',
      text: 'Please refresh the page or log in again.',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        reload: {
          text: 'Refresh',
          value: true,
          visible: true,
        },
      },
    }).then(() => {
      getCognitoSession()
        .then(() => {
          location.reload();
        })
        .catch(sessionError => {
          console.error(sessionError);
          logoutFromIdp().then(() => {
            // we don't resolve the promise in order to avoid
            // that follow up processes will fire another alert
            return;
          });
        });
    });
  });
}

/**
 * Logout from Cognito and if required from the IdP. If the session is already fully expired,
 * redirect the user to the login page.
 */
export function logoutFromIdp() {
  return new Promise((resolve, _reject) => {
    if (loginProvider === 'idp') {
      // If the user login was established via an IdP, we need to logout from Cognito and the IdP
      try {
        // Always try to logout from Cognito. If the logout works as expected, continue
        // with the logout from the actual IdP.
        let cognitoUser = COGNITO.userpool.getCurrentUser();
        cognitoUser.signOut();
        clearStorage();
        window.open(CONF.logoutEndpoint, '_self');
        resolve();
      } catch (err) {
        // If the logout from Cognito was not successful, we can expect that the session
        // has already fully expired and we can redirect the user to the login page
        console.error(err);
        gotoLoginPage();
        resolve();
      }
    } else {
      // If the user login was established via an Cognito User Pool user, it's enough to logout from Cognito
      let cognitoUser = COGNITO.userpool.getCurrentUser();
      cognitoUser?.signOut();
      clearStorage();
      window.open('login.html', '_self');
      resolve();
    }
  });
}

/**
 * Forward the user to the login page. In addition, we take the current location of the user
 * from the session storage of the browser and build the custom login link. After a successful
 * login, the user should be forwarded to the same page again.
 */
function gotoLoginPage() {
  if (!getSearchParamsUrl('code')) {
    const targetHash = sessionStorage.getItem('lastHash');
    const targetSearch = sessionStorage.getItem('lastSearch');
    const loginUrl = getLoginViaWebEAMUrl(targetHash, targetSearch);
    window.location.href = loginUrl;
  }
}

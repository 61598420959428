import { Datatable } from '../../../Datatable';

export default function TileTable(props) {
  const { id, title, headline, colspan, children } = props;

  return (
    <div id={id} class={`col-sm-12 col-md-12 col-lg-12 col-xl-${colspan || 12} mb-4`}>
      <div class="card dashboard-card">
        <div class={`card-body d-flex flex-column position-relative pt-4 px-4`}>
          <div class="row">
            <div class="position-relative">
              <div class={`tile-text-secondary tile-title`}>{title}</div>
              <div>
                <span class="tile-text-tertiary">{headline}</span>
              </div>
            </div>
          </div>
          <div class="mt-3"></div>
          <div class="tile-content">
            <Datatable tableId={`table-${id}`} />
            <div class="tile-content-children">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { baseApiAxios } from './js/portal/api';
import { VPCAPI, VPCFormOperations } from './js/portal/networks_create';
import { removeSearchParamsUrl } from './js/portal/search_params';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { hasAccess } from './js/portal/main';
import { Tooltip } from 'bootstrap';

export default function NetworksCreatePage() {
  const vpcAPI = new VPCAPI(baseApiAxios);
  const vpcFormOperations = new VPCFormOperations(vpcAPI);

  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="network-create-form" id="network-create-form" method="POST">
          <div class="card form-card">
            <div id="header-title" class="card-header">
              Create New VPC
            </div>
            <div class="card-body">
              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>
              <div id="infobox" class="card infobox">
                <div class="card-body">
                  <span id="card-body-span">With this form you can request a new VPC in your target AWS Account</span>.
                  For more information please have a look in our{' '}
                  <a
                    href="https://developer.bmw.com/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/network/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Developer Portal
                    <i class="px-1 fas fa-external-link-alt" />
                  </a>
                  .
                </div>
              </div>
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="tabs" class="tab-content">
                <div id="vpc-settings" class="tab">
                  {/* Account ID selection */}
                  <div class="form-group row">
                    <label for="aws-account-id" class="col-form-label">
                      AWS Account Id
                    </label>
                    <div class="col-md-12 col-lg-9 col-xl-10">
                      <AccountDropdown
                        id="aws-account-id"
                        label="none"
                        onchange={(
                          _eventTypeId,
                          _eventCategory,
                          _selectedOption,
                          accountId,
                          accountType,
                          accountStage
                        ) => {
                          document.getElementById('submitButton').disabled = !hasAccess(accountId, ['manage_networks']);
                          vpcFormOperations.onAccountChange(
                            _eventTypeId,
                            _eventCategory,
                            _selectedOption,
                            accountId,
                            accountType,
                            accountStage
                          );
                        }}
                        lookup
                        short/>
                    </div>
                  </div>

                  {/* VPC ID for EDIT only informational */}
                  <div class="form-group row" style="display: none">
                    <label for="vpc-id" class="col-form-label">
                      VPC ID
                    </label>
                    <div class="col">
                      <input disabled readonly type="text" class="form-control" id="vpc-id" name="vpc-id" />
                    </div>
                  </div>

                  {/* Network Type selection and trigger Region and Network Size dropdown */}
                  <div class="form-group row" id="network-type-container">
                    <label for="network-type" class="col-form-label">
                      Network Type
                      <span class="loading-form-inline loading-network-types">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </span>
                    </label>
                    <div class="col-md-12 col-lg-9 col-xl-10">
                      <select
                        required
                        class="form-control form-select selectpicker"
                        data-live-search="true"
                        data-show-subtext="true"
                        id="network-type"
                        name="network-type"
                        data-dropdown-align-right="auto">
                        <option value="" disabled selected>
                          - Please select a network type -
                        </option>
                      </select>

                      {/* Network Type Infobox */}
                      <div class="accordion infobox mt-3 mb-1" id="network-type-infobox">
                        {/* Accordion Item Private VPCs */}
                        <div class="accordion-item">
                          <h4 class="accordion-header" id="networkTypeInfoHeaderPrivate">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#networkTypeInfoCollapsePrivate"
                              aria-expanded="true"
                              aria-controls="networkTypeInfoCollapsePrivate">
                              <i class="fas fa-info-circle" /> &nbsp;Private VPCs
                            </button>
                          </h4>
                          <div
                            id="networkTypeInfoCollapsePrivate"
                            class="accordion-collapse collapse show"
                            aria-labelledby="networkTypeInfoHeaderPrivate"
                            data-bs-parent="#network-type-infobox">
                            <div class="accordion-body">
                              <p>
                                Private VPCs are only available for account type(s){' '}
                                <strong id="network-type-infobox-private-account-types">default</strong> and account
                                stage(s) <strong id="network-type-infobox-private-account-stages">dev</strong>. Find
                                more information about the{' '}
                                <a
                                  href="https://developer.bmw.com/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/cloudroom/cloudroom/#overview-bmw-customer-accounts"
                                  target="_blank"
                                  rel="noopener noreferer">
                                  BMW AWS account types
                                </a>
                              </p>
                              <p>
                                As we need to provide a shared services infrastructure (DNS, Direct Connect, Proxy, ...)
                                in every region that will be connected with the BMW on premise network, we only support
                                limited number of AWS regions. Find more information about the{' '}
                                <a
                                  href="https://developer.bmw.com/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/network/hybrid_network/hybrid_network/"
                                  target="_blank"
                                  rel="noopener noreferer">
                                  supported regions for Private VPCs
                                </a>
                                .
                              </p>
                              <p>
                                <strong class="portal-danger">Attention: </strong>Every Private VPC is connected to a
                                Transit Gateway (TGW). This will add <strong>additional costs</strong> to your monthly
                                bill. The basic monthly fee is between $36.5 and $43.8 (depending on your AWS region)
                                plus $0.02 per processed GB. Find more information about the{' '}
                                <a
                                  href="https://aws.amazon.com/transit-gateway/pricing/?nc1=h_ls"
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  Transit Gateway costs here
                                </a>
                                .
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* Accordion Item Public VPCs */}
                        <div class="accordion-item">
                          <h4 class="accordion-header" id="networkTypeInfoHeaderPublic">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#networkTypeInfoCollapsePublic"
                              aria-expanded="false"
                              aria-controls="networkTypeInfoCollapsePublic">
                              <i class="fas fa-info-circle" /> &nbsp;Public VPCs
                            </button>
                          </h4>

                          <div
                            class="accordion-collapse collapse"
                            id="networkTypeInfoCollapsePublic"
                            aria-labelledby="networkTypeInfoHeaderPublic"
                            data-bs-parent="#network-type-infobox">
                            <div class="accordion-body">
                              <p>
                                Public VPCs are only available for accounts with account type(s){' '}
                                <strong id="network-type-infobox-public-account-types">default</strong> and account
                                stage(s) <strong id="network-type-infobox-public-account-stages">dev</strong>
                              </p>
                              <p>
                                Public VPCs are basically available in all AWS regions. Find more information about the{' '}
                                <a
                                  href="https://developer.bmw.com/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/network/supported_regions/regions/"
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  supported regions for Public VPCs
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Hide the network details until you've chosen an account and the target network type */}
                  <div id="network-configuration-details" style="display:none;">
                    {/* Region Selection triggered based on Network Type selection */}
                    <div class="form-group row">
                      <label for="region" class="col-form-label">
                        Region
                        <span class="loading-form-inline loading-network-region">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
                      </label>
                      <div class="col">
                        <select
                          required
                          class="form-control form-select selectpicker"
                          data-live-search="true"
                          data-show-subtext="true"
                          data-size="10"
                          id="region"
                          name="region">
                          <option value="" class="defaultDisabled" disabled selected>
                            - Please select an AWS region -
                          </option>
                        </select>
                      </div>
                    </div>

                    {/* Network Name Text field: name tag for the VPC */}
                    <div class="form-group row">
                      <label for="network-name" class="col-form-label">
                        Network Name
                      </label>
                      <div class="col">
                        <input
                          required
                          type="text"
                          class="form-control"
                          id="network-name"
                          name="network-name"
                          placeholder="e.g. test-app-private"
                          minlength="3"
                          maxlength="100"
                          pattern="[a-zA-Z0-9-_]{3,100}"
                          title="Please enter a valid Network Name. Length between 3 and 100 alphanumerical characters: ^([a-zA-Z0-9\-\_ ]{3,100})$"/>
                      </div>
                    </div>

                    {/* CIDRs section, for private user cannot specify the CIDR, for public need to start from 10.19?
                    in this section the user can specify also the secondary CIDRs, up to 4 of them regardless of the type */}
                    <div id="network-cidr" class="row">
                      <label for="network-address" class="col-form-label">
                        Network Address and Size
                      </label>
                      <div class="col input-group gap-10">
                        <input
                          required
                          type="text"
                          class="form-control w-60"
                          id="network-address"
                          name="network-address"
                          aria-describedby="network-address-valid-feedback network-address-invalid-feedback"/>
                        <select
                          required
                          class="form-control form-select selectpicker w-35"
                          data-live-search="true"
                          data-show-subtext="true"
                          id="network-size"
                          name="network-size">
                          <option value="" disabled selected>
                            - Please select a network size -
                          </option>
                        </select>
                        <div class="valid-feedback" id="network-address-valid-feedback" />
                        <div class="invalid-feedback" id="network-address-invalid-feedback" />
                      </div>
                    </div>

                    {/* Secondary CIDRs section */}
                    <div id="secondary-network-cidr" style="display: none" />

                    {/* button to trigger the insertion of the secondary CIDR */}
                    <div class="row">
                      <label class="col-form-label" />
                      <div class="col m-20" style="cursor: not-allowed">
                        <button type="button" disabled class="btn btn-custom btn-transparent" id="add-secondary-cidr">
                          <i class="fas fa-plus icon-color" />
                          <span class="ms-2 link">Add Secondary CIDR</span>
                        </button>
                      </div>
                    </div>

                    <div id="subnet-settings" class="tab">
                      {/* info for network type/CIDR input and button for secondary CIDRs */}
                      <div class="row">
                        <label class="col-form-label" />
                        <div class="col">
                          {/* info for network type/CIDR input */}
                          <div class="card infobox">
                            <div class="card-body">
                              <ul>
                                <li>
                                  Public VPCs aren't connected to the Corporate Network, so you can choose your own
                                  network address like 10.19.1.0 or 10.19.0.0 (without ending /##).
                                </li>
                                <li>
                                  <strong>We recommend</strong> to use the reserved CIDR range{' '}
                                  <strong>10.19.0.0/16</strong> which is reserved for this purpose. This CIDR range is
                                  blocked and won't be used for any private VPCs or for any networks on premise to avoid
                                  routing problems.
                                </li>
                                <li>
                                  Private VPCs are connected to the Corporate Network, however you can add secondary
                                  CIDRs to it, but be aware that you would need to choose addresses with the first 2
                                  octet fixed with value <strong>10.19.</strong> like 10.19.1.0 or 10.19.0.0 (without
                                  ending /##).
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* dropdown selection for the type and number of subnets */}
                      <div class="form-group row">
                        <label for="subnets" class="col-form-label">
                          Number of Subnets
                        </label>
                        <div class="col-md-12 col-lg-9 col-xl-10">
                          <dic class="row gx-2">
                            {/* Public selection for the number of subnets */}
                            <div class="col-4">
                              <div id="publicSubnetsHelp" class="form-text mb-1">
                                Public{' '}
                                <i
                                  class="fas fa-info-circle"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-custom-class="custom-tooltip"
                                  title="Public Subnets have a direct route to an Internet Gateway. All resources must have a public IP address in order to have an Internet connection"/>
                              </div>
                              <select
                                class="form-control form-select selectpicker"
                                data-live-search="true"
                                data-show-subtext="true"
                                id="public-subnets-number"
                                name="public-subnets-number"
                                data-subnet-type="public"
                                aria-describedby="publicSubnetsHelp">
                                <option value="" disabled selected>
                                  - Please select the target number of public subnets -
                                </option>
                              </select>
                            </div>
                            {/* Private selection for the number of subnets */}
                            <div class="col-4">
                              <div id="privateSubnetsHelp" class="form-text mb-1">
                                Private{' '}
                                <i
                                  class="fas fa-info-circle"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-custom-class="custom-tooltip"
                                  title="Private Subnets do not have a direct connection to the Internet or to the BMW on premise network"/>
                              </div>
                              <select
                                class="form-control form-select selectpicker"
                                data-live-search="true"
                                data-show-subtext="true"
                                id="private-subnets-number"
                                name="private-subnets-number"
                                data-subnet-type="private"
                                aria-describedby="privateSubnetsHelp">
                                <option value="" disabled selected>
                                  - Please select the target number of private subnets -
                                </option>
                              </select>
                            </div>
                            {/* Intranet selection for the number of subnets */}
                            <div class="col-4">
                              <div id="intranetSubnetsHelp" class="form-text mb-1">
                                Intranet{' '}
                                <i
                                  class="fas fa-info-circle"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-custom-class="custom-tooltip"
                                  title="Intranet Subnets do not have a direct connection to the Internet but have connection to the BMW on premise network"/>
                              </div>
                              <select
                                class="form-control form-select selectpicker"
                                data-live-search="true"
                                data-show-subtext="true"
                                id="intranet-subnets-number"
                                name="intranet-subnets-number"
                                data-subnet-type="intranet"
                                aria-describedby="intranetSubnetsHelp">
                                <option value="" disabled selected>
                                  - Please select the target number of intranet subnets -
                                </option>
                              </select>
                            </div>
                          </dic>
                        </div>
                      </div>

                      {/* Row to show the options for each subnet type */}
                      <div class="form-group row">
                        <label class="col-form-label" />
                        <div class="col m-10" style="cursor: not-allowed">
                          <button
                            type="button"
                            disabled
                            class="btn btn-custom btn-transparent"
                            id="display-subnets-options">
                            <i class="fas fa-chevron-right icon-color" />
                            <span class="ms-2 link">More subnet options</span>
                          </button>
                        </div>
                      </div>

                      {/* Details for each type of subnets */}
                      <div class="form-group row">
                        <label class="col-form-label" />
                        <div class="col-md-12 col-lg-9 col-xl-10" id="subnets-container" />
                      </div>

                      {/* Information about the IPs unused/reserved/free with the currect configuration, calculated based on input */}
                      <div
                        class="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-5 text-center"
                        id="ip-summary"
                        style="display: none">
                        <div class="col">
                          <h4>
                            <strong>
                              <span id="vpc-ips-unused" />
                            </strong>{' '}
                            <small>unused IPs in your new VPC</small>
                          </h4>
                          <p class="hint">
                            Those IPs are not assigned to any subnet and can't be used for your VPC internal resources
                            like EC2 instances.
                          </p>
                        </div>
                        <div class="col">
                          <h4>
                            <strong>
                              <span id="aws-ips-reserved" />
                            </strong>{' '}
                            <small>reserved IPs in your new VPC</small>
                          </h4>
                          <p class="hint">
                            AWS reserves 5 IPs per subnet by default. Those IPs can't be used for your VPC internal
                            resources like EC2 instances.
                          </p>
                        </div>
                        <div class="col">
                          <h4>
                            <strong>
                              <span id="subnet-ips-free" />
                            </strong>{' '}
                            <small>available IPs in each Subnet</small>
                          </h4>
                          <p class="hint">Those IPs are available to you in every single subnet.</p>
                        </div>
                        <div class="col">
                          <h4>
                            <strong>
                              <span id="vpc-ips-free" />
                            </strong>{' '}
                            <small>available IPs in your new VPC</small>
                          </h4>
                          <p class="hint">
                            Those IPs are avaiable in total in your VPC (sum of all available IPs of all subnets).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit button */}
            <div class="card-footer">
              <div class="row px-2">
                <div class="col">
                  <input
                    type="button"
                    name="resetFormBtn"
                    class="btn btn-secondary"
                    onclick={() => vpcFormOperations.resetForm()}
                    value="Reset"/>
                </div>
                <div class="col" align="right">
                  <button type="submit" class="btn btn-success" id="submitButton" value="Create VPC">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Create VPC
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    // Enable tooltips
    [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach(el => {
      new Tooltip(el); // NOSONAR
    });

    $('#sidebarItemCreateVPC > a').on('click', () => removeSearchParamsUrl());
    vpcFormOperations.initForm();
  }

  return [page, onPageReady];
}

import { addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import {
  addCopyButton,
  addLoadAccountDetailsButton,
  checkValueAgainstList,
  configureTableColumns,
  createAndAddInfoTable,
  displayErrorPopup,
  displaySuccessPopup,
  rowDetailsClickHandler,
} from './main';
import { baseApiAxios } from './api';
import { accountIdPattern } from './accounts';
import { getData } from './databases';
import swal from 'sweetalert';
import { initDataTable } from './datatable';

export const idpTableColumns = [
  { id: 'select_col' },
  { id: 'arn_col', name: 'Provider ARN' },
  { id: 'account_id_col', name: 'Account Id' },
  { id: 'region_name_col', name: 'Region' },
  { id: 'creation_date_col', name: 'Creation Date' },
  { id: 'idp_type_col', name: 'IDP Type' },
  { id: 'service_col', name: 'Service' },
  { id: 'url_col', name: 'Provider URL' },
  { id: 'compliance_status_col', name: 'Compliance Status' },
  { id: 'status_col', name: 'Provider Status' },
  { id: 'status_update_col', name: 'Status Update' },
  { id: 'actions_col', name: 'Action' },
];

const idpInfoFields = [
  { Name: 'Account Id', Value: 'account_id' },
  { Name: 'Region', Value: 'region_name' },
  { Name: 'Creation Date', Value: 'creation_date' },
  { Name: 'Service', Value: 'service' },
  { Name: 'IdP Type', Value: 'idp_type' },
  { Name: 'Compliance Status', Value: 'compliance_status' },
  { Name: 'ARN', Value: 'arn' },
  { Name: 'Identity Provider URL', Value: 'url' },
  { Name: 'OIDC Client ID List', Value: 'client_id_list' },
  { Name: 'OIDC Thumbprint List', Value: 'thumbprint_list' },
  { Name: 'SAML Entity ID', Value: 'saml_entity_id' },
  { Name: 'SAML SSO Login', Value: 'saml_sso_login' },
  { Name: 'Tags', Value: 'tags' },
  { Name: 'IdP Status', Value: 'status' },
  { Name: 'Status Details', Value: 'status_message' },
  { Name: 'Last Status Update (UTC)', Value: 'status_update' },
];

const idpDeleteStatus = ['active', 'unknown', undefined];
const idpNoDeleteStatus = ['AccessDenied', 'AccountDeleted'];

export function initIdpTable(tableId) {
  configureTableColumns(tableId, idpTableColumns);
  const permissions = localStorage.permissions ?? false;

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        methodReload: loadIdpData,
        titleAttr: 'Reload Identity Provider (no-cache)',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
      },
      {
        visible: false,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'arn',
        name: 'arn_col',
        title: 'Provider ARN',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'account_id',
        name: 'account_id_col',
        title: 'Account ID',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);
          if (!accountIdPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'region_name',
        name: 'region_name_col',
        title: 'Region',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'creation_date',
        name: 'creation_date_col',
        title: 'Creation Date',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'idp_type',
        name: 'idp_type_col',
        title: 'IDP Type',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'service',
        name: 'service_col',
        title: 'Service',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'url',
        name: 'url_col',
        title: 'Provider URL',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'compliance_status',
        name: 'compliance_status_col',
        title: 'Compliance Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'COMPLIANT') {
            $(td).addClass('portal-success');
          } else {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'status',
        name: 'status_col',
        title: 'Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'active') {
            $(td).addClass('portal-success');
          } else {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status_update',
        name: 'status_update_col',
        title: 'Last Status Update (UTC)',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        createdCell: function (td, _cellData, rowData, row) {
          const buttons = $('<div class="table-action-button-group">').appendTo(td);

          if (idpNoDeleteStatus.indexOf(rowData.status) < 0 || checkValueAgainstList('manage_idp', permissions)) {
            // DELETE BUTTON
            buttons.append(
              $('<button class="btn btn-custom btn-xs" data-bs-toggle="tooltip" title="Delete Identity Provider">')
                .on('click', () => deleteIdP(row))
                .append('<i class="fas fa-trash-alt">')
            );
          }
        },
      },
    ],
    function (row, data) {
      if (data.status === 'AccountDeleted') {
        $(row).addClass('row-deleted');
      } else if (data.status === 'NoSuchHostedZone') {
        $(row).addClass('row-inactive');
      }
    },
    {
      order: [[1, 'asc']],
    }
  );

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatIdpDetails });
  loadIdpData(tableId);
}

function loadIdpData(tableId, headers) {
  $(() => addSpinner());
  $(() => addTableSpinner());

  getData({
    apiPath: 'idp',
    tableId: tableId,
    tableColumns: idpTableColumns,
    dropdownColumns: ['idp_type', 'compliance_status', 'status', 'service', 'region_name'],
    searchColumns: ['arn', 'account_id', 'creation_date', 'url'],
    headers: headers,
  });
}

function formatIdpDetails(row) {
  const data = row.data();
  const idpDetailNavigation = [{ Value: 'idp', Name: 'Identity Provider' }];

  // DETAIL ROW
  let detailContainer = document.createElement('div');
  detailContainer.setAttribute('class', 'detailsContainer');

  let nav = document.createElement('ul');
  nav.setAttribute('class', 'nav nav-tabs');
  detailContainer.appendChild(nav);

  let navContent = document.createElement('div');
  navContent.setAttribute('class', 'tab-content detailsTab');
  detailContainer.appendChild(navContent);

  idpDetailNavigation.forEach(function (item) {
    let navItem = document.createElement('li');
    if (item['Value'] === 'idp') {
      navItem.setAttribute('class', 'nav-item active');
    } else {
      navItem.setAttribute('class', 'nav-item');
    }
    nav.appendChild(navItem);

    let navLink = document.createElement('a');
    if (item['Value'] === 'idp') {
      navLink.setAttribute('class', 'nav-link active');
      navLink.setAttribute('aria-selected', true);
      navLink.setAttribute('aria-expanded', true);
    } else {
      navLink.setAttribute('class', 'nav-link');
      navLink.setAttribute('aria-selected', false);
      navLink.setAttribute('aria-expanded', false);
    }
    navLink.setAttribute('role', 'tab');
    navLink.setAttribute('data-bs-toggle', 'tab');
    navLink.setAttribute('href', '#' + item['Value'] + data.arn);
    navLink.innerText = item['Name'];
    navItem.appendChild(navLink);

    let navItemBody = document.createElement('div');
    if (item['Value'] === 'idp') {
      navItemBody.setAttribute('class', 'tab-pane fade active show');
    } else {
      navItemBody.setAttribute('class', 'tab-pane fade');
    }
    navItemBody.setAttribute('id', item['Value'] + data.arn);
    navItemBody.setAttribute('role', 'tabpanel');
    navContent.appendChild(navItemBody);

    if (item['Value'] === 'idp') {
      let detailContainerAccount = document.createElement('div');
      detailContainerAccount.setAttribute('class', 'col-lg-12 col-md-12');
      detailContainerAccount.setAttribute('name', 'idp-details-container');
      navItemBody.appendChild(detailContainerAccount);
      formatChildRowIdpDetails(data, detailContainerAccount, row);
    }
  });

  row.child(detailContainer).show();
  row.child()[0].setAttribute('class', 'rowDetails');
  removeSpinners();
}

function formatChildRowIdpDetails(data, content, _row) {
  // LEFT SIDE
  const leftContent = document.createElement('div');
  leftContent.setAttribute('class', 'col-lg-12 col-md-12');
  content.appendChild(leftContent);

  // Identity Provider Details
  formatChildRowIdpDetailsLeft(data, leftContent);
}

function formatChildRowIdpDetailsLeft(data, container) {
  let mainContainer = document.createElement('div');
  mainContainer.setAttribute('class', 'detailsContent');
  container.appendChild(mainContainer);

  let header = document.createElement('h4');
  header.innerText = 'Identity Provider Details';
  mainContainer.appendChild(header);

  let details = document.createElement('div');
  mainContainer.appendChild(details);

  createAndAddInfoTable(data, idpInfoFields, details);
}

function deleteIdP(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-idps').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  if (idpDeleteStatus.indexOf(data.status) < 0) {
    deleteIdpFromDB(data);
  } else {
    deleteIdpOrder(data);
  }
}

function deleteIdpFromDB(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-idps').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  let content = document.createElement('div');
  content.innerHTML =
    '<p>Are you sure you want to delete <br><strong>' +
    data.arn +
    '?</strong></p><p><strong>The DB item will be immediately removed from our database!</strong>';

  swal({
    title: 'Delete from DB',
    content: content,
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Remove Item from DB',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      baseApiAxios
        .getProvider(data.arn)
        .then(result => {
          displaySuccessPopup(result.message).then(function () {
            const dt = $('#table-idps').DataTable({
              retrieve: true,
            });
            dt.rows({ order: 'index' })
              .data()
              .each(function (value, index) {
                if (value.arn === data.arn) {
                  dt.row(index).remove().draw();
                }
              });
          });
        })
        .catch(displayErrorPopup)
        .finally(() => removeSpinners());
    }
  });
}

function deleteIdpOrder(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-idps').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  let content = document.createElement('div');
  content.innerHTML =
    '<p>Are you sure you want to delete <br><strong>' +
    data.arn +
    '?</strong></p><p>If you confirm, we will create a new order ' +
    'which will <strong>delete your Identity Provider</strong> from your AWS Account.';

  swal({
    title: 'New Delete Identity Provider Order',
    content: content,
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Create Order',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      const payload = {
        action: 'delete-idp',
        description: 'Delete ' + data.service + ' ' + data.idp_type + ' IdP from AWS account ' + data.account_id,
        account_id: data.account_id,
        service: data.service,
        idp_type: data.idp_type,
        arn: data.arn,
        user_pool_id: data.user_pool_id,
        provider_name: data.provider_name,
      };

      baseApiAxios
        .createOrder(payload)
        .then(result => {
          displaySuccessPopup(result.message).then(function () {
            const dt = $('#table-idps').DataTable({
              retrieve: true,
            });
            dt.rows({ order: 'index' })
              .data()
              .each(function (value, index) {
                if (value.arn === data.arn) {
                  data.status = 'MarkedForDeletion';
                  dt.row(index).data(data).draw();
                }
              });
          });
        })
        .catch(displayErrorPopup)
        .finally(() => removeSpinners());
    }
  });
}

import { baseApiAxios } from './js/portal/api';
import { showErrorFromApiOperation } from './js/portal/main';
import { getSearchParamsUrl, removeAllSearchParamsUrlExcept } from './js/portal/search_params';
import { addSpinner, removeSpinners } from './js/portal/sidebar';
import ClusterDropdown from './jsx/components/forms/ClusterDropdown';
import ClusterTabs from './jsx/components/forms/ClusterTabs';

function loadClusterData(_eventTypeId, _eventCategory, _option, cluster_id) {
  setTimeout(async () => {
    try {
      addSpinner();
      const cluster_result = await baseApiAxios.getCluster(cluster_id, {}, {});
      removeSpinners();
      $('#four-wheels-details-main').html(<ClusterTabs data={cluster_result.cluster_item} />);
    } catch (err) {
      showErrorFromApiOperation('Fetching Cluster information failed.')(err);
    }
  }, 0);
}

export default function FourWheelsDetailsPage() {
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div class="my-2">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#list4wheels">Clusters</a>
                </li>
                <li class="breadcrumb-item"></li>
                <li id="resource-dropdown-container">
                  <ClusterDropdown label="none" onchange={loadClusterData} urlParams />
                </li>
                <li class="breadcrumb-item edit-account-show" style="display:none;"></li>
              </ol>
            </nav>
          </div>
          <div id="four-wheels-details-main" class="edit-account-hide my-5"></div>
        </div>
      </div>
    </>
  );

  function onPageReady() {
    removeAllSearchParamsUrlExcept(['cluster_id']);
    const cluster_id_param = getSearchParamsUrl('cluster_id');
    if (!cluster_id_param) {
      $('#cluster-id').val(1);
      $('#cluster-id').selectpicker('refresh');
    }
    $('#four-wheels-details-main').html(<ClusterTabs data={{}} />);
  }

  return [page, onPageReady];
}

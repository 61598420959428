/**
 *
 * @param {string} id - the id of the selectpicker
 * @param {string} selectedValue - the target date which will be used as initial value
 * @param {function} onchange - a function that will be triggered once the date value changes
 * @param {boolean} disabled - defines if the input will be disabled
 * @param {string} minValue - disables date value before minValue
 * @param {string} maxValue - disables date values after maxValue
 *
 * @returns JSX.Element
 */
export default function DatePickerField({ id, selectedValue, onchange, disabled, minValue, maxValue }) {
  const input = (
    <input
      type="date"
      id={id}
      class="form-control"
      value={selectedValue}
      min={minValue}
      max={maxValue}
      required
      disabled={disabled ? true : false}/>
  );
  if (onchange) {
    $(input).on('change', evt => {
      onchange(id, evt.target.value);
    });
  }

  return <div class="input-group datetime-input-group has-validation">{input}</div>;
}

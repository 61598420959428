import { initDnsTable } from './js/portal/zones';
import { Datatable } from './Datatable';

const tableId = 'table-zones';

export default function HostedZonesListPage() {
  const page = (
    <>
      <div class="row row-cols-auto justify-content-end gy-3" style="margin-bottom: 20px">
        <div id="show-all-hostedzones-div" class="col col-auto align-self-center d-none">
          <div class="form-check form-switch">
            <input class="form-check-input danger" type="checkbox" id="show-all-hostedzones-switch" />
            <label class="form-check-label" for="show-all-hostedzones-switch">
              Show all hosted zones
            </label>
          </div>
        </div>
      </div>

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initDnsTable(tableId);
  }

  return [page, onPageReady];
}

import { baseApiAxios } from '../../../js/portal/api';
import {
  addToast,
  checkValueAgainstList,
  createJsonCodeBlock,
  hasAccess,
  InfoTable,
  InfoTableWide,
  showErrorFromApiOperation,
} from '../../../js/portal/main';
import { addSpinner, removeSpinners } from '../../../js/portal/sidebar';
import { deleteZoneFromDB, deleteZoneOrder } from '../../../js/portal/zones';

async function hostedZoneUpdateHandler(fqdn) {
  try {
    addSpinner();
    const trigger_update_response = await baseApiAxios.triggerHostedZoneUpdate(fqdn);
    removeSpinners();
    addToast('Hosted Zone Update', trigger_update_response.message, 6000);
  } catch (err) {
    removeSpinners();
    showErrorFromApiOperation('Triggering Hosted Zone Update failed.')(err);
  }
}

function hostedZoneDeleteHandler(zone_data) {
  if (['active', 'unknown', undefined].indexOf(zone_data.status) < 0) {
    deleteZoneFromDB(zone_data);
  } else {
    deleteZoneOrder(zone_data);
  }
}

export default function HostedZoneTabs(props) {
  const { data } = props;

  if (!data.account_item) data.account_item = {};
  if (!data.hosted_zone) data.hosted_zone = {};

  const { account_item, hosted_zone } = data;

  const associatedVPCsEnabled = hosted_zone.vpcs && Object.keys(hosted_zone.vpcs).length;
  const nameServersEnabled = hosted_zone.ns_records && Object.keys(hosted_zone.ns_records).length;
  const dnssecEnabled = hosted_zone.dnssec && Object.keys(hosted_zone.dnssec).length;
  const queryLoggingConfigEnabled =
    hosted_zone.query_logging_config && Object.keys(hosted_zone.query_logging_config).length;

  const permissions = localStorage.permissions ? localStorage.permissions : false;

  // shared
  const hosted_zone_data = {
    account_id: hosted_zone.account_id,
    fqdn: hosted_zone.fqdn,
    status: hosted_zone.status,
    zone_details: hosted_zone.zone_details,
    zone_id: hosted_zone.zone_id || hosted_zone.hosted_zone_id,
  };

  const hostedZoneButtonHelper = () => {
    return (
      <div class="detailsContent">
        <div class="btn-group detail-actions-btn-group">
          {['AccountDeleted'].indexOf(hosted_zone.status) < 0 && (
            <button class="btn-sm" onclick={() => hostedZoneUpdateHandler(hosted_zone.fqdn)}>
              <span class="fas fa-sync-alt" /> Trigger Update
            </button>
          )}
          {(['AccountDeleted', 'NoSuchHostedZone'].indexOf(hosted_zone.status) < 0 ||
            checkValueAgainstList('manage_dns', permissions)) &&
            hasAccess(data.account_item.account_id, ['manage_dns']) && (
              <button class="btn-sm" onclick={() => hostedZoneDeleteHandler(hosted_zone_data)}>
                <span class="fas fa-trash-alt" /> Delete Hosted Zone
              </button>
            )}
        </div>
      </div>
    );
  };

  const fqdn_id = hosted_zone.fqdn && hosted_zone.fqdn.replaceAll('.', '-');

  return (
    <div class="detailsContainer">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            data-bs-target={'#hosted_zone_' + fqdn_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="true"
            aria-expanded="true">
            Hosted Zone
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (associatedVPCsEnabled ? '' : ' disabled')}
            data-bs-target={'#associated_vpcs_' + fqdn_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Associated VPCs
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (nameServersEnabled ? '' : ' disabled')}
            data-bs-target={'#name_servers_' + fqdn_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Name Server Records
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (dnssecEnabled ? '' : ' disabled')}
            data-bs-target={'#dnssec_' + fqdn_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            DNSSEC
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (queryLoggingConfigEnabled ? '' : ' disabled')}
            data-bs-target={'#query_logging_config_' + fqdn_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Query Logging Config
          </a>
        </li>
      </ul>
      <div class="tab-content detailsTab">
        <div class="tab-pane fade active show" id={'hosted_zone_' + fqdn_id} role="tabpanel">
          <div class="row" name="hosted-zone-container">
            <div class="col-lg-12 col-xl-6">
              <div class="detailsContent">
                <h4>Hosted Zone Details</h4>
                <div>
                  <InfoTable
                    data={hosted_zone}
                    rowInfos={[
                      { Name: 'Account ID', Value: 'account_id' },
                      { Name: 'Name', Value: 'fqdn' },
                      { Name: 'Hosted Zone ID', Value: 'zone_id' },
                      { Name: 'Zone Type', Value: 'zone_type' },
                      { Name: 'Comment', Value: 'comment' },
                      { Name: 'Resource Record Set Count', Value: 'zone_details.ResourceRecordSetCount' },
                      { Name: 'Proxy Record Value', Value: 'proxy_record_value' },
                      { Name: 'Proxy Record Type', Value: 'proxy_record_type' },
                      { Name: 'Status', Value: 'status' },
                      { Name: 'Error Details', Value: 'error_details' },
                      { Name: 'Status Update', Value: 'status_update' },
                    ]}/>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-xl-6">
              <div class="detailsContent">
                <h4>Account Details</h4>
                <div>
                  <InfoTable
                    data={account_item}
                    rowInfos={[
                      { Name: 'Account Id', Value: 'account_id' },
                      { Name: 'Root Account', Value: 'account_root_email' },
                      { Name: 'Friendly Name', Value: 'account_friendly_name' },
                      { Name: 'Description', Value: 'description' },
                      { Name: 'FPC Status', Value: 'fpc_status' },
                      { Name: 'Account Area', Value: 'account_area' },
                      { Name: 'Account Type', Value: 'account_type' },
                      { Name: 'Account Stage', Value: 'account_stage' },
                      { Name: 'AWS Support Plan', Value: 'support_plan' },
                      { Name: 'IT Responsible', Value: 'it_responsible' },
                      { Name: 'Primary Responsible', Value: 'primary_responsible' },
                      { Name: 'Secondary Responsible', Value: 'sec_responsible' },
                      { Name: 'Creation Date', Value: 'creation_date' },
                    ]}/>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-xl-12">{hostedZoneButtonHelper()}</div>
          </div>
        </div>
        <div class="tab-pane fade" id={'associated_vpcs_' + fqdn_id} role="tabpanel">
          <div class="row" name="associated-vpcs-container">
            <div class="col-lg-12 col-xl-6">
              <div class="detailsContent">
                <h4>Associated VPCs</h4>
                <div>
                  <InfoTableWide data={hosted_zone.vpcs || []} colInfos={['VPCRegion', 'VPCId']} rowNumbers={true} />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-xl-12">{hostedZoneButtonHelper()}</div>
          </div>
        </div>
        <div class="tab-pane fade" id={'name_servers_' + fqdn_id} role="tabpanel">
          <div class="row" name="name-servers-container">
            <div class="col-lg-12 col-xl-12">
              <div class="detailsContent">
                <h4>Name Servers</h4>
                <div>
                  <InfoTableWide
                    data={hosted_zone.ns_records || []}
                    colInfos={['Name', 'ResourceRecords']}
                    rowNumbers={true}/>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-xl-12">{hostedZoneButtonHelper()}</div>
          </div>
        </div>
        <div class="tab-pane fade" id={'dnssec_' + fqdn_id} role="tabpanel">
          <div class="row" name="dnssec-container">
            <div class="col-lg-12 col-xl-6">
              <div class="detailsContent">
                <h4>Raw DNSSEC Data</h4>
                {createJsonCodeBlock(hosted_zone.dnssec || '{}')}
              </div>
            </div>
            <div class="col-lg-12 col-xl-12">{hostedZoneButtonHelper()}</div>
          </div>
        </div>
        <div class="tab-pane fade" id={'query_logging_config_' + fqdn_id} role="tabpanel">
          <div class="row" name="query-logging-config-container">
            <div class="col-lg-12 col-xl-6">
              <div class="detailsContent">
                <h4>Raw Query Logging Config</h4>
                {createJsonCodeBlock(hosted_zone.query_logging_config || '{}')}
              </div>
            </div>
            <div class="col-lg-12 col-xl-12">{hostedZoneButtonHelper()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

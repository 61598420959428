import { baseApiAxios, showError } from '../../../js/portal/api';
import { getSearchParamsUrl, setSearchParamsUrl } from '../../../js/portal/search_params';

/**
 * Creates a new selectpicker dropdown including a label and a refresh button.
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} id - the id of the selectpicker
 * @param {function} onchange - a function that will be triggered once the selectpicker value changes
 * @param {function} onrefresh - function that is triggered when the refresh button is clicked
 * @param {type[]} selectedValues - a list of Hosted Zones that should be selected if available
 * @param {boolean} deselect - defines if the deselect Hosted Zones button is added to the end of the selectpicker
 * @param {boolean} multiple - defines if you can select one or multiple items in the Hosted Zone dropdown
 * @param {boolean} listAll - display all resources that the user has permission to see/edit
 * @param {boolean} disableUrlParams - disable setting and reading URL parameters
 *
 * @returns div including a label, the dropdown and a refresh button
 */
export default function HostedZoneDropdown({
  label,
  id,
  onchange,
  onrefresh,
  selectedValues,
  deselect,
  multiple,
  listAll,
  disableUrlParams,
}) {
  const labelText = label ? label : 'Hosted Zone Id';
  const selectId = id ? id : 'hosted-zone-id';
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = {
    list_all: listAll ? listAll : false,
  };
  const multiSelect = multiple ? true : false;
  const urlParameters = !disableUrlParams ? true : false;

  // selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/
  const select = (
    <select
      required
      class="form-control form-select selectpicker"
      data-live-search="true"
      data-show-subtext="true"
      id={selectId}
      name={selectId}
      data-size="10"
      data-max-options="5"
      data-none-selected-text="- Select one or more Hosted Zones -"
      data-selected-text-format="count > 1"
      data-count-selected-text="{0} Hosted Zones selected"
      data-dropdown-align-right="auto"
      multiple={multiSelect}>
      <option class="defaultDisabled" value="" disabled selected={!multiSelect}>
        - Select a Hosted Zone -
      </option>
    </select>
  );

  // Refresh button to reload the Hosted Zone data
  const refreshIcon = <i class="fas fa-sync" />;
  const refreshButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      id="refresh-hosted-zones"
      title="Reload Hosted Zones">
      {refreshIcon}
    </button>
  );

  // De-select button
  const deselectButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-mid"
      id="deselect-hosted-zone"
      title="De-select all Hosted Zones">
      <i class="fa fa-user-alt-slash" />
    </button>
  );

  // Represents the whole row including the label, the selectpicker and the refresh button
  const hostedZoneDropdown = (
    <div class="form-group row portal-dropdown">
      {labelText != 'none' && (
        <label for="hosted-zone-id" class="col-form-label">
          {labelText}
        </label>
      )}
      <div class="col input-group">
        {select}
        {deselect ? deselectButton : undefined}
        {refreshButton}
      </div>
    </div>
  );

  // Refresh the selectpicker and add an on change event
  $(select).selectpicker('refresh');
  $(select).on('changed.bs.select', () => {
    const selectedOption = $(select).find(':selected');
    const selectedHostedZoneId = selectedOption.val();

    if (!multiSelect && urlParameters) {
      const previousUrlParam = getSearchParamsUrl('domain');
      if (selectedHostedZoneId && selectedHostedZoneId !== previousUrlParam) {
        setSearchParamsUrl({ domain: selectedHostedZoneId });
      }
    }

    if (onchange && selectedHostedZoneId) {
      onchange('domain', 'hostedzone', selectedOption, selectedHostedZoneId);
    }
  });

  $(select).on('reload-dropdown-options', event => {
    const dropdownSettings = Object.assign(
      {
        selectedValues,
        multiSelect,
        urlParameters,
      },
      event.detail
    );

    const additionalParams = {
      list_all: event?.detail?.allResources,
    };

    loadHostedZones(
      select,
      refreshIcon,
      dropdownSettings.selectedValues,
      dropdownSettings.multiSelect,
      dropdownSettings.urlParameters,
      headers,
      additionalParams
    );
  });

  // Reload the Hosted Zone records from the REST API (with cache-control headers)
  $(refreshButton).on('click', event => {
    if (onrefresh) {
      onrefresh(event, select);
    } else {
      loadHostedZones(select, refreshIcon, selectedValues, multiSelect, urlParameters, headers, params);
    }
  });

  // De-select all Hosted Zones from the selectpicker
  $(deselectButton).on('click', () => {
    $(select).selectpicker('deselectAll');
    $(select).selectpicker('refresh');
  });

  // Load the Hosted Zone records from the REST API (without cache-control headers)
  loadHostedZones(select, refreshIcon, selectedValues, multiSelect, urlParameters, {}, params);
  return hostedZoneDropdown;
}

/**
 * Populates the selectpicker dropdown with the values from the REST API endpoint. Deletes all active dropdown items
 * before adding the new items.
 *
 * @param {JSX.IntrinsicElements.select} select
 * @param {JSX.Element} refreshIcon
 * @param {type[]} selectedValues
 * @param {boolean} multiSelect
 * @param {boolean} urlParameters
 * @param {object} headers
 * @param {object} params
 */
async function loadHostedZones(select, refreshIcon, selectedValues, multiSelect, urlParameters, headers, params) {
  $(refreshIcon).addClass('fa-spin');
  select.querySelectorAll('option:not(.defaultDisabled)').forEach(el => el.remove());
  $(select).attr('disabled', true).selectpicker('refresh');

  try {
    const payload = Object.assign(
      {
        attribute_names: ['fqdn', 'account_id', 'zone_type', 'zone_id', 'status'],
      },
      params
    );
    const url_params = new URLSearchParams(payload).toString();
    const hosted_zones_response = await baseApiAxios.getHostedZones(url_params, headers);

    // Retrieve a domain from the URL search parameters
    const selected_domain = !multiSelect && urlParameters && getSearchParamsUrl('domain');

    let number_zones = { private: 0, public: 0, other: 0 };
    // Add all account items as options to the selectpicker
    const options = hosted_zones_response.hosted_zones.map(hosted_zone => {
      let opt = document.createElement('option');
      opt.value = hosted_zone.fqdn;
      opt.dataset.content = `<span class="bs-dropdown-badge ${hosted_zone.status}">${
        hosted_zone.fqdn
      }</span><span class="bs-dropdown-item-text">${hosted_zone.zone_type}</span><span class="bs-dropdown-badge new">${
        hosted_zone.account_id
      }</span><div class="bs-dropdown-badge text-overflow">${hosted_zone.zone_id?.replace('/hostedzone/', '')}</div>`;
      if (Object.prototype.hasOwnProperty.call(number_zones, hosted_zone.zone_type)) {
        number_zones[hosted_zone.zone_type] += 1;
      } else {
        number_zones.other += 1;
      }
      opt.selected =
        (selected_domain && selected_domain == hosted_zone.fqdn) ||
        (selectedValues && selectedValues.includes(hosted_zone.fqdn))
          ? true
          : false;
      return opt;
    });

    // Sort the selectpicker options in asc order by the option text
    options.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    // Add all options to the selectpicker
    options.forEach(option => {
      select.appendChild(option);
    });
    const event = new CustomEvent('dropdown-options-loaded', {
      detail: {
        number_active: options.length,
        number_private: number_zones.private,
        number_public: number_zones.public,
        number_other: number_zones.other,
        list_all: params.list_all,
      },
    });
    select.dispatchEvent(event);
    $(select).trigger('changed.bs.select');
  } catch (err) {
    showError(err);
  }

  // Refresh the selectpicker to show the new options
  $(refreshIcon).removeClass('fa-spin');
  $(select).attr('disabled', false);
  $(select).selectpicker('refresh');
}

import swal from 'sweetalert';
import { baseApiAxios, showError } from './api';
import {
  addCopyButton,
  addLoadAccountDetailsButton,
  addToast,
  checkValueAgainstList,
  loadRegionsAndUpdateDropdown,
  configureTableColumns,
  rowDetailsClickHandler,
  addLinkHostedZoneIdButton,
  showErrorFromApiOperation,
  hasAccess,
  removeDisabledOptions,
  displayError,
  displayErrorPopup,
  displaySuccessPopup,
} from './main';
import { addButtonSpinner, addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import { accountIdPattern } from './accounts';
import { centralFillVPCs, centralLoadVPCs } from './networks';
import { getData } from './databases';
import HostedZoneTabs from '../../jsx/components/forms/HostedZoneTabs';
import { initDataTable } from './datatable';

export const zoneTableColumns = [
  { id: 'select_col' },
  { id: 'domain_name_col', name: 'Domain Name' },
  { id: 'aws_account_id_col', name: 'Account ID' },
  { id: 'zone_id_col', name: 'Hosted Zone ID' },
  { id: 'zone_type_col', name: 'Hosted Zone Type' },
  { id: 'creation_date_col', name: 'Creation Date' },
  { id: 'records_col', name: 'Number of Records' },
  { id: 'ns_records_col', name: 'Number of NS Records' },
  { id: 'vpcs_col', name: 'Number of Associated VPCs' },
  { id: 'query_logging_configs_col', name: 'Number of Query Logging Configs' },
  { id: 'proxy_record_type_col', name: 'Proxy Record Type' },
  { id: 'proxy_record_value_col', name: 'Proxy Record' },
  { id: 'status_col', name: 'Status' },
  { id: 'status_update_col', name: 'Last Status Update (UTC)' },
  { id: 'actions_col', name: 'Actions' },
];

const hostedZonesDetailsAttributes = [
  'fqdn',
  'account_id',
  'zone_id',
  'zone_type',
  'creation_date',
  'numbers',
  'proxy_record_type',
  'proxy_record_value',
  'status',
  'status_update',
];

const hostedZoneDeleteStatus = ['active', 'unknown', undefined];
const hostedZoneNoTriggerUpdateStatus = ['AccountDeleted'];
const hostedZoneNoDeleteStatus = ['AccountDeleted', 'NoSuchHostedZone'];

let dashboardShowAllHostedZones = false;

export function hideZoneEditForm() {
  $('#edit-zone-form').hide();
}

function addDNSRowButtons(td, _cellData, rowData, row) {
  const buttons = $('<div class="table-action-button-group">').appendTo(td);
  const permissions = localStorage.permissions ? localStorage.permissions : false;
  const accessibleDNS = hasAccess(rowData.account_id, ['manage_dns']);
  if (hostedZoneNoTriggerUpdateStatus.indexOf(rowData.status) < 0) {
    // TRIGGER UPDATE BUTTON
    buttons.append(
      $('<button class="btn btn-custom btn-xs" data-bs-toggle="tooltip" title="Trigger DB Update">')
        .on('click', () => triggerHostedZoneUpdate(row))
        .append('<i class="fas fa-sync-alt">')
    );
  }

  if (hostedZoneNoDeleteStatus.indexOf(rowData.status) < 0 || checkValueAgainstList('manage_dns', permissions)) {
    // DELETE BUTTON
    buttons.append(
      $(
        '<button class="btn btn-custom btn-xs" data-bs-toggle="tooltip" title="Delete Hosted Zone"' +
          (accessibleDNS ? '' : ' disabled ') +
          '>'
      )
        .on('click', () => deleteZone(row))
        .append('<i class="fas fa-trash-alt">')
    );
  }

  return buttons;
}

export function initDnsTable(tableId) {
  configureTableColumns(tableId, zoneTableColumns);

  let permissions = localStorage.permissions ? localStorage.permissions : false;

  const hasPermissionToViewAll =
    checkValueAgainstList('manage_accounts', permissions) ||
    checkValueAgainstList('view_all_accounts', permissions) ||
    checkValueAgainstList('manage_dns', permissions) ||
    localStorage.account_areas;

  $('#show-all-hostedzones-div').toggleClass('d-none', !hasPermissionToViewAll);

  $('#show-all-hostedzones-switch')
    .attr('checked', dashboardShowAllHostedZones)
    .on('change', function () {
      if (dashboardShowAllHostedZones !== this.checked) {
        dashboardShowAllHostedZones = this.checked;
        loadDnsData(tableId);
      }
    });

  const dt = initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        text: 'Update all hosted zones',
        // action: checkValueAgainstList('manage_dns', permissions) ? triggerAllHostedZonesUpdate : null,
        action: triggerAllHostedZonesUpdate,
        className: 'update-all-zones',
      },
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        methodReload: loadDnsData,
        titleAttr: 'Reload DNS records (no-cache)',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
        width: '20px',
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'fqdn',
        name: 'domain_name_col',
        title: 'Domain Name',
        createdCell: function (td /*, cellData */) {
          addCopyButton(td);
          addLinkHostedZoneIdButton(td);
        },
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'account_id',
        name: 'aws_account_id_col',
        title: 'Account ID',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);
          if (!accountIdPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'zone_id',
        name: 'zone_id_col',
        title: 'Hosted Zone ID',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'zone_type',
        name: 'zone_type_col',
        title: 'Hosted Zone Type',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'creation_date',
        name: 'creation_date_col',
        title: 'Creation Date',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.records',
        name: 'records_col',
        title: 'Number of Records',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.ns_records',
        name: 'ns_records_col',
        title: 'Number of NS Records',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.vpcs',
        name: 'vpcs_col',
        title: 'Number of Associated VPCs',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.query_logging_configs',
        name: 'query_logging_configs_col',
        title: 'Number of Query Logging Configs',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'proxy_record_type',
        name: 'proxy_record_type_col',
        title: 'Proxy Record Type',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'proxy_record_value',
        name: 'proxy_record_value_col',
        title: 'Proxy Record',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'status',
        name: 'status_col',
        title: 'Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'active') {
            $(td).addClass('portal-success');
          } else {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status_update',
        name: 'status_update_col',
        title: 'Last Status Update (UTC)',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        createdCell: addDNSRowButtons,
        width: '50px',
      },
    ],
    function (row, data) {
      if (data.status === 'AccountDeleted') {
        $(row).addClass('row-deleted');
      } else if (data.status === 'NoSuchHostedZone') {
        $(row).addClass('row-inactive');
      }
    },
    {
      order: [[1, 'asc']],
    }
  );

  // Hide a button if user is not portal admin
  if (!checkValueAgainstList('manage_dns', permissions)) {
    dt.buttons('.update-all-zones').nodes().addClass('d-none');
  }

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatZoneRow });
  loadDnsData(tableId);
}

function loadDnsData(tableId, headers, forceReload) {
  $(() => addSpinner());
  $(() => addTableSpinner());

  const dropdownColumns = ['zone_type', 'proxy_record_type', 'status'];
  const searchColumns = [
    'fqdn',
    'account_id',
    'zone_id_col',
    'creation_date',
    'records_col',
    'ns_records_col',
    'vpcs_col',
    'query_logging_configs_col',
    'proxy_record_value',
    'status_update_col',
  ];

  const params = {
    list_all: dashboardShowAllHostedZones,
  };

  getData({
    apiPath: '/dns',
    dataAttribute: 'hosted_zones',
    tableId: tableId,
    dropdownColumns: dropdownColumns,
    searchColumns: searchColumns,
    headers: headers,
    params: params,
    forceReload: forceReload,
    attributeNames: hostedZonesDetailsAttributes,
  });
}

async function formatZoneRow(row) {
  const data = row.data();
  try {
    const hosted_zone_result = await baseApiAxios.getHostedZone(data.fqdn, {}, {});
    row.child(
      <div class="detailsContainer">
        <HostedZoneTabs data={hosted_zone_result} />
      </div>
    );
    row.child()[0].setAttribute('class', 'rowDetails');
  } catch (err) {
    showErrorFromApiOperation('Fetching Hosted Zone data failed.')(err);
  }
}

function triggerHostedZoneUpdate(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-zones').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  const payload = {
    action: 'trigger-update-hostedzone',
    fqdn: data.fqdn,
  };

  addSpinner();
  baseApiAxios.displayResponseAsToast(baseApiAxios.postDnsData(payload), 'Trigger Update');
}

function triggerAllHostedZonesUpdate() {
  const payload = { action: 'trigger-update-all-hostedzones' };
  addSpinner();
  baseApiAxios.displayResponseAsToast(baseApiAxios.postDnsData(payload), 'Trigger Update');
}

export function deleteZoneOrder(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-zones').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  let content = document.createElement('div');
  content.innerHTML =
    '<p>Are you sure you want to delete <br><strong>' +
    data.fqdn +
    '?</strong></p><p>If you confirm, we will create a new order ' +
    'which will <strong>delete your Route 53 Private Hosted Zone</strong> from your AWS Account ' +
    '<strong>including your currently existing DNS records</strong>!</p>';

  swal({
    title: 'New Delete Hosted Zone Order',
    content: content,
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Create Order',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      const payload = {
        action: 'destroy-hostedzone',
        description: 'Destroy hosted zone ' + data.fqdn,
        zone_name: data.fqdn,
        hosted_zone_id: data.zone_id,
        force_deletion: true,
        account_id: data.account_id,
      };
      baseApiAxios.createOrder(payload).then(updateListZonePage);
    }
  });
}

export function deleteZoneFromDB(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-zones').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  let content = document.createElement('div');
  content.innerHTML =
    '<p>Are you sure you want to delete <br><strong>' +
    data.fqdn +
    '?</strong></p><p><strong>The DB item will be immediately removed from our database!</strong> ' +
    "The Route 53 Private Hosted Zone itself won't be modified by this action, but somebody else could order a " +
    'new zone with the same DNS name.</p>';

  swal({
    title: 'Delete from DB',
    content: content,
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Remove Item from DB',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      const payload = {};
      baseApiAxios
        .deleteHostedZone(data.fqdn, payload)
        .then(result => {
          addToast('Deleted Zone from DB', result.message, 5000);
          const dt = $('#table-zones').DataTable({
            retrieve: true,
          });
          dt.rows({ order: 'index' })
            .data()
            .each(function (value, index) {
              if (value.fqdn === data.fqdn) {
                dt.row(index).remove().draw();
              }
            });
        })
        .catch(displayErrorPopup)
        .finally(() => removeSpinners());
    }
  });
}

function deleteZone(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-zones').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  let zone_id = '';
  if (data.zone_id) {
    zone_id = data.zone_id;
  } else if (data.hosted_zone_id) {
    zone_id = data.hosted_zone_id;
  }

  let zone = {
    account_id: data.account_id,
    fqdn: data.fqdn,
    status: data.status,
    zone_details: data.zone_details,
    zone_id: zone_id,
  };

  if (hostedZoneDeleteStatus.indexOf(zone.status) < 0) {
    deleteZoneFromDB(zone);
  } else {
    deleteZoneOrder(zone);
  }
}

function updateListZonePage(result) {
  removeSpinners();
  switch (result.status) {
    case 200:
      addToast('Create Order', result.data.message, 5000);
      break;
    case 400:
      $('#form-error').html('<strong>Error: </strong>' + result.data.message);
      $('#form-error').show();
      break;
  }
}

export function setZoneInfo() {
  let selected_hz_id = document.getElementById('hosted-zone-id-text');
  let zone_id = document.getElementById('fqdn');
  if (selected_hz_id && zone_id && zone_id.value) {
    selected_hz_id.value = zone_id.value.split(',')[0].split('/')[2];
  }
}

export function loadHostedZones(listId, accountId, nameFilter) {
  const loading_hosted_zones = document.querySelector('.loading-form-inline');
  if (loading_hosted_zones) loading_hosted_zones.classList.add('loading-animation');
  let sel = document.getElementById(listId);
  let account_id = document.getElementById(accountId).value.split(';')[0];

  if (account_id) {
    for (let i = sel.options.length - 1; i >= 0; i--) {
      sel.remove(i);
    }

    let payload = {
      action: 'list-hostedzones',
      account_id: account_id,
    };

    if (nameFilter) {
      payload['name_filter'] = nameFilter;
    }

    addSpinner();
    addButtonSpinner();

    baseApiAxios
      .postDnsData(payload)
      .then(result => {
        $('#fqdn').find('optgroup').remove();
        $('#fqdn').find('option').remove();
        $('#fqdn').find('li').remove();

        let select = document.getElementById(listId);

        if (select.options.length === 0) {
          let opt = document.createElement('option');
          opt.value = '';
          opt.innerText = '- Select a Hosted Zone -';
          opt.disabled = true;
          opt.selected = true;
          select.appendChild(opt);
        }

        if (result.zones && result.zones.length > 0) {
          jQuery.each(result.zones, function () {
            let opt = document.createElement('option');
            let mainText = this.Name;
            let subText = this.Id + ' (' + this.zone_type + ')';
            opt.value = [this.Id, this.Name];
            opt.innerText = mainText;
            opt.setAttribute('data-subtext', subText);
            select.appendChild(opt);
          });
        }

        setZoneInfo();

        $('.selectpicker').selectpicker('refresh');
        $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
      })
      .catch(err => displayError(`<strong>Warning</strong>: ${err.message}`))
      .finally(() => {
        removeSpinners();
        const loading_hosted_zones = document.querySelector('.loading-form-inline');
        if (loading_hosted_zones) loading_hosted_zones.classList.remove('loading-animation');
      });
  }
}

export function notifyDhcpChange() {
  if ($('#create-dhcp')[0].checked) {
    swal({
      title: 'Are you sure?',
      text:
        'This option will create a new DHCP Option Set and associate it with the chosen VPC. This will change your ' +
        'current DNS settings and might break services. You can change these settings also manually by yourself at ' +
        'a later time.',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
        },
        okay: {
          text: 'Accept',
          value: true,
          visible: true,
        },
      },
    }).then(function (response) {
      if (!response) {
        $('#create-dhcp')[0].checked = false;
      }
    });
  }
}

export function checkValidityHostedZone(event) {
  const regexString = event.srcElement.getAttribute('regex-string');
  const re = RegExp(regexString);

  const targetId = event.target.id;
  const selectedItem = $(`#${targetId}`)[0].value;
  let zoneName = selectedItem.substring(selectedItem.indexOf(',') + 1, selectedItem.length);

  if (targetId == 'dns-prefix') {
    zoneName += $('#dns-suffix')[0].textContent;
  }

  if (!re.test(zoneName)) {
    $(`#${targetId}`).removeClass('is-valid');
    $(`#${targetId}-container`).removeClass('is-valid');
    $(`#${targetId}-container`).addClass('is-invalid');
    $(
      `#${targetId}-invalid-feedback`
    )[0].textContent = `This hosted zone does not meet the requirements. It must match the pattern: ${regexString}`;
    $('#submitButton').prop('disabled', true);
  } else {
    $(`#${targetId}`).removeClass('is-invalid');
    $(`#${targetId}-container`).removeClass('is-invalid');
    $(`#${targetId}-container`).addClass('is-valid');
    $('#submitButton').prop('disabled', false);
  }
}

/**
 * Updated the DNS suffix text field based on the selected zone type and region.
 */
export function changeDNSText() {
  removeSpinners();

  // Collect the current zone type and the available suffix(es) from the dropdown.
  const zoneTypeDropdown = document.querySelector('#zone-type option:checked');
  const zoneType = zoneTypeDropdown.value;
  const zoneSuffix = zoneTypeDropdown.getAttribute('suffix');

  if (zoneType === 'private' && zoneSuffix) {
    // Show the domain name container
    document.getElementById('domain-name').style.display = '';

    // For private hosted zones we need to add the region between the prefix and the suffix.
    const zoneRegion = document.querySelector('#region option:checked').value;
    document.getElementById('dns-suffix').textContent = '.' + zoneRegion + '.' + zoneSuffix;

    // Private hosted zones needs to be associated with a least one VPC in the same AWS account.
    // Here we load the available VPCs from the given AWS account and region.
    centralLoadVPCs('region', 'aws-account-id', centralFillVPCs, { listId: 'network-id-a', disableSharedVPCs: true });
  } else if (zoneType === 'public' && zoneSuffix && zoneSuffix.split(',').length > 0) {
    // Show the domain name container
    document.getElementById('domain-name').style.display = '';

    // Use the first available zone suffix from the list of public zone suffixes.
    document.getElementById('dns-suffix').textContent = '.' + zoneSuffix.split(',')[0];
  } else {
    // Hide the domain name container if something goes wrong
    document.getElementById('domain-name').style.display = 'none';
    swal({
      title: 'Missing DNS configuration',
      text: `The zone type "${zoneType}" is not (yet) supported or the target DNS configuration is missing.`,
      icon: 'error',
    });
  }
}

export function updateZoneType() {
  let zoneType = $('#zone-type')[0].value;
  $('#dns-prefix').prop('required', true);

  if (zoneType === 'private') {
    loadRegionsAndUpdateDropdown(zoneType, ['region']);
    $('#private-zone-region').show();
    $('#private-vpc-id').show();
    $('#private-vpc-dhcp-options').show();

    $('#region').prop('required', true);
    $('#network-id-a').prop('required', true);
  } else {
    $('#private-zone-region').hide();
    $('#private-vpc-id').hide();
    $('#private-vpc-dhcp-options').hide();

    $('#region').prop('required', false);
    $('#network-id-a').prop('required', false);
    changeDNSText();
  }
}

export function checkHostedZone(callback) {
  let dnsPrefix = $('#dns-prefix')[0].value;
  let dnsSuffix = $('#dns-suffix')[0].textContent;
  const dnsPrefixPattern = /^[a-z0-9][a-z0-9._-]{1,200}[a-z0-9]$/;

  if (!dnsPrefixPattern.test(dnsPrefix)) {
    $('#dns-prefix').removeClass('is-valid');
    $('#dns-prefix').addClass('is-invalid');
    $('#dns-prefix-invalid-feedback')[0].textContent =
      'Please enter a valid domain prefix with the following pattern: ' + dnsPrefixPattern;
  } else {
    const dnsURL = dnsPrefix + dnsSuffix;
    const payload = {
      action: 'check-hostedzone',
      zone_name: dnsURL,
    };

    baseApiAxios
      .postDnsData(payload)
      .then(result => {
        callback(result);
      })
      .catch(err => {
        const dnsPrefixField = document.getElementById('dns-prefix');
        dnsPrefixField.classList.remove('is-valid');
        dnsPrefixField.classList.add('is-invalid');
        document.getElementById('dns-prefix-invalid-feedback').textContent = err.message;
      });
  }
}

export function callCheckHostedZone(dnsURL) {
  const payload = {
    action: 'check-hostedzone',
    zone_name: dnsURL,
  };
  addButtonSpinner();

  const submitButton = document.getElementById('submitButton.onboard-hosted-zone-button');

  baseApiAxios
    .postDnsData(payload)
    .then(() => {
      submitButton.disabled = false;
    })
    .catch(() => {
      submitButton.disabled = true;
    })
    .finally(() => removeSpinners());
}

export function showHostedZoneAvailability(result) {
  $('#dns-prefix').removeClass('is-invalid');
  $('#dns-prefix').addClass('is-valid');
  $('#dns-prefix-valid-feedback')[0].textContent = result.message;
}

export function submitHostedZoneOrder() {
  const dnsPrefix = $('#dns-prefix')[0].value;
  const dnsSuffix = $('#dns-suffix')[0].textContent;
  const zoneType = $('#zone-type')[0].value;

  let payload = {
    action: 'create-hostedzone',
    description: 'Create hosted zone ' + dnsPrefix + dnsSuffix,
    account_id: $('#aws-account-id')[0].value.split(';')[0],
    zone_name: dnsPrefix + dnsSuffix,
    zone_type: zoneType,
  };

  if (zoneType === 'private') {
    payload['region'] = $('#region')[0].value;
    payload['vpc_id'] = $('#network-id-a')[0].value;
    payload['create_dhcp'] = $('#create-dhcp')[0].checked;
  }

  baseApiAxios
    .createOrder(payload)
    .then(orderResponse => {
      addToast('Create Order', orderResponse.message, 6000);

      const form = document.getElementById('create-route53-hosted-zone-form');
      $('#domain-name').hide();
      $('#private-zone-region').hide();
      $('#private-vpc-id').hide();
      $('#private-vpc-dhcp-options').hide();
      form.reset();
      $('#dns-prefix').removeClass('is-valid');
      $('#dns-prefix').removeClass('is-invalid');
      $('#dns-prefix-invalid-feedback')[0].textContent = '';
      $('#dns-prefix-valid-feedback')[0].textContent = '';
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
      $('#dns-prefix').prop('required', false);
      $('#region').prop('required', false);
      $('#network-id-a').prop('required', false);
      updateZoneType();
    })
    .catch(orderError => {
      showError(orderError);
    });
}

export function resultAssociateHostedZoneFunction(result) {
  removeSpinners();
  displaySuccessPopup(result.message, 'Create Order');
  $('#aws-account-id').val('');
  $('#hosted-zone-id').val('');
  $('#aws-account-id-b').val('');
  $('#network-id-a').val('');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
  $('.selectpicker').selectpicker('refresh');
}

export function resultOnboardHostedZoneFunction(result) {
  removeSpinners();
  displaySuccessPopup(result.message, 'Create Order');
  $('#aws-account-id').val('');
  $('#hosted-zone-id').val('');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
  $('.selectpicker').selectpicker('refresh');
}

/**
 * Collect the DNS configuration from the backend and add the hosted zone options (private/public) to the dropdown
 * depending on the available private/public domain suffixes and the selected AWS account type.
 *
 * @param {string} _searchParam
 * @param {string} _label
 * @param {object} _selectedOption
 * @param {string} _accountId
 * @param {string} accountType
 * @param {string} _accountStage
 */
export function updateAvailableZoneTypesDropdown(
  _searchParam,
  _label,
  _selectedOption,
  _accountId,
  accountType,
  _accountStage
) {
  // Show the Hosted Zone Type dropdown once the AWS account was selected
  document.getElementById('zone-type-container').style.display = '';

  addSpinner();
  addButtonSpinner();

  // Collect the DNS configuration from the backend
  baseApiAxios.getDnsConfig().then(config => {
    const private_hosted_zones_account_types = config.private_hosted_zones_account_types || [];
    const public_hosted_zones_account_types = config.public_hosted_zones_account_types || [];
    const private_domain_suffix = config.private_domain_suffix || undefined;
    const public_domains = config.public_domains || [];

    // Update the info text with the currently configured values from the backend
    if (private_hosted_zones_account_types) {
      document.getElementById('zone-type-infobox-private-account-types').textContent =
        private_hosted_zones_account_types.join(', ');
    }
    if (public_hosted_zones_account_types) {
      document.getElementById('zone-type-infobox-public-account-types').textContent =
        public_hosted_zones_account_types.join(', ');
    }

    // Get the zone type dropdown and reset to the available items
    const select = document.getElementById('zone-type');
    removeDisabledOptions(select);

    // Prepare and add the option for private hosted zones
    // Adds the private domain suffix as an attribute to the option
    if (private_domain_suffix && private_hosted_zones_account_types.includes(accountType)) {
      let opt = document.createElement('option');
      opt.innerText = 'Private Hosted Zone';
      opt.value = 'private';
      opt.setAttribute('suffix', private_domain_suffix);
      select.appendChild(opt);
    }

    // Prepare and add the option for public hosted zones
    // Adds the public domain suffixes as a comma-separated list to the option
    if (public_domains && public_domains.length > 0 && public_hosted_zones_account_types.includes(accountType)) {
      let opt = document.createElement('option');
      opt.innerText = 'Public Hosted Zone';
      opt.value = 'public';
      opt.setAttribute('suffix', public_domains.join(','));
      select.appendChild(opt);
    }

    removeSpinners();
    $('.selectpicker').selectpicker('refresh');
    updateZoneType();
  });
}

import { baseApiAxios, showError } from '../../../js/portal/api';
import { getSearchParamsUrl, setSearchParamsUrl } from '../../../js/portal/search_params';

/**
 * Creates a new selectpicker dropdown including a label and a refresh button.
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} id - the id of the selectpicker
 * @param {function} onchange - a function that will be triggered once the selectpicker value changes
 * @param {function} onrefresh - function that is triggered when the refresh button is clicked
 * @param {type[]} selectedValues - a list of DXGWs that should be selected if available
 * @param {boolean} deselect - defines if the deselect DXGW button is added to the end of the selectpicker
 * @param {boolean} multiple - defines if you can select one or multiple items in the DXGW dropdown
 * @param {boolean} listAll - display all accounts that the user has permission to see/edit
 * @param {boolean} disableUrlParams - disable setting and reading URL parameters
 *
 * @returns div including a label, the dropdown and a refresh button
 */
export default function DXGWDropdown({
  label,
  id,
  onchange,
  onrefresh,
  selectedValues,
  deselect,
  multiple,
  listAll,
  disableUrlParams,
}) {
  const labelText = label ? label : 'DXGW Id';
  const selectId = id ? id : 'dxgw-id';
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = {
    list_all: listAll ? listAll : false,
  };
  const multiSelect = multiple ? true : false;
  const urlParameters = !disableUrlParams ? true : false;

  // selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/
  const select = (
    <select
      required
      class="form-control form-select selectpicker"
      data-live-search="true"
      data-show-subtext="true"
      id={selectId}
      name={selectId}
      data-size="10"
      data-max-options="5"
      data-none-selected-text="- Select one or more DXGWs -"
      data-selected-text-format="count > 1"
      data-count-selected-text="{0} DXGWs selected"
      data-dropdown-align-right="auto"
      multiple={multiSelect}>
      <option class="defaultDisabled" value="" disabled selected={!multiSelect}>
        - Select a DXGW -
      </option>
    </select>
  );

  // Refresh button to reload the DXGW data
  const refreshIcon = <i class="fas fa-sync" />;
  const refreshButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      id="refresh-dxgw"
      title="Reload DXGWs">
      {refreshIcon}
    </button>
  );

  // De-select button
  const deselectButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-mid"
      id="deselect-dxgw"
      title="De-select all DXGWs">
      <i class="fa fa-user-alt-slash" />
    </button>
  );

  // Represents the whole row including the label, the selectpicker and the refresh button
  const dxgwDropdown = (
    <div class="form-group row portal-dropdown">
      {labelText != 'none' && (
        <label for="dxgw-id" class="col-form-label">
          {labelText}
        </label>
      )}
      <div class="col input-group">
        {select}
        {deselect ? deselectButton : undefined}
        {refreshButton}
      </div>
    </div>
  );

  // Refresh the selectpicker and add an on change event
  $(select).selectpicker('refresh');
  $(select).on('changed.bs.select', () => {
    const selectedOption = $(select).find(':selected');
    const selectedDXGWId = selectedOption.val();
    const selectedId = selectedOption.data('id');
    const selectedUId = selectedOption.data('uuid');

    if (!multiSelect && urlParameters) {
      const previousUrlParam = getSearchParamsUrl('dx_gw_id');
      if (selectedDXGWId && selectedDXGWId !== previousUrlParam) {
        setSearchParamsUrl({ dx_gw_id: selectedDXGWId });
      }
    }

    if (onchange && selectedDXGWId) {
      onchange('dx_gw_id', 'network', selectedOption, selectedDXGWId, selectedId, selectedUId);
    }
  });

  $(select).on('reload-dropdown-options', event => {
    const dropdownSettings = Object.assign(
      {
        selectedValues,
        multiSelect,
        urlParameters,
      },
      event.detail
    );

    const additionalParams = {
      list_all: event?.detail?.allResources,
    };

    loadDXGWs(
      select,
      refreshIcon,
      dropdownSettings.selectedValues,
      dropdownSettings.multiSelect,
      dropdownSettings.urlParameters,
      headers,
      additionalParams
    );
  });

  // Reload the DXGW records from the REST API (with cache-control headers)
  $(refreshButton).on('click', event => {
    if (onrefresh) {
      onrefresh(event, select);
    } else {
      loadDXGWs(select, refreshIcon, selectedValues, multiSelect, urlParameters, headers, params);
    }
  });

  // De-select all DXGWs from the selectpicker
  $(deselectButton).on('click', () => {
    $(select).selectpicker('deselectAll');
    $(select).selectpicker('refresh');
  });

  // Load the DXGW records from the REST API (without cache-control headers)
  loadDXGWs(select, refreshIcon, selectedValues, multiSelect, urlParameters, {}, params);
  return dxgwDropdown;
}

/**
 * Populates the selectpicker dropdown with the values from the REST API endpoint. Deletes all active dropdown items
 * before adding the new items.
 *
 * @param {JSX.IntrinsicElements.select} select
 * @param {JSX.Element} refreshIcon
 * @param {type[]} selectedValues
 * @param {boolean} multiSelect
 * @param {boolean} urlParameters
 * @param {object} headers
 * @param {object} params
 */
async function loadDXGWs(select, refreshIcon, selectedValues, multiSelect, urlParameters, headers, params) {
  $(refreshIcon).addClass('fa-spin');
  select.querySelectorAll('option:not(.defaultDisabled)').forEach(el => el.remove());
  $(select).attr('disabled', true).selectpicker('refresh');

  try {
    let payload = Object.assign(
      {
        action: 'list-network-items',
        attributes: ['dx_gw_id', 'id', 'uuid', 'name', 'netbond_cidr', 'aws_account_id'],
        item_type: 'DX_GW',
        table_data: {
          length: 0, // set to 500 for sequential requests of 500 items
        },
      },
      params
    );

    let page = 0;
    let dx_gw_length = -1;
    let options = [];

    while (dx_gw_length === 500 || page === 0) {
      const dx_gw_response = await baseApiAxios.getNetworkItems(payload, headers);
      const json = dx_gw_response?.items;
      dx_gw_length = json?.length;

      // Retrieve an dx_gw_id from the URL search parameters
      const selected_dx_gw_id = !multiSelect && urlParameters && getSearchParamsUrl('dx_gw_id');

      // Add all account items as options to the selectpicker
      options = options.concat(
        dx_gw_response.items.map(dxgw => {
          let opt = document.createElement('option');
          opt.value = dxgw.dx_gw_id;
          opt.dataset.cidr = dxgw.netbond_cidr;
          opt.dataset.id = dxgw.id;
          opt.dataset.uuid = dxgw.uuid;
          opt.dataset.content = `<span class="bs-dropdown-badge cidr">${dxgw.netbond_cidr}</span><span class="bs-dropdown-item-text">${dxgw.name}</span><div class="bs-dropdown-badge text-overflow">${dxgw.dx_gw_id}</div>`;
          opt.selected =
            (selected_dx_gw_id && selected_dx_gw_id == dxgw.dx_gw_id) ||
            (selectedValues && selectedValues.includes(dxgw.dx_gw_id))
              ? true
              : false;
          return opt;
        })
      );

      // Sort the selectpicker options in asc order by the option text
      options.sort(function (a, b) {
        return a.dataset.cidr.localeCompare(b.dataset.cidr);
      });

      // Add all options to the selectpicker
      options.forEach(option => {
        select.appendChild(option);
      });
      const event = new CustomEvent('dropdown-options-loaded', {
        detail: {
          number_active: options.length,
          list_all: params.list_all,
          page,
        },
      });
      select.dispatchEvent(event);
      if (dx_gw_length !== 500) $(select).trigger('changed.bs.select');

      if (dx_gw_length) {
        payload['exclusive_start_key'] = {
          item_type: 'DX_GW',
          id: json[json.length - 1].id,
          uuid: json[json.length - 1].uuid,
          dx_gw_id: json[json.length - 1].dx_gw_id,
        };
      }
      page += 1;
    }
  } catch (err) {
    showError(err);
  }

  // Refresh the selectpicker to show the new options
  $(refreshIcon).removeClass('fa-spin');
  $(select).attr('disabled', false);
  $(select).selectpicker('refresh');
}

import { baseApiAxios } from './js/portal/api';
import { showErrorFromApiOperation } from './js/portal/main';
import { getSearchParamsUrl, removeAllSearchParamsUrlExcept } from './js/portal/search_params';
import { addSpinner, removeSpinners } from './js/portal/sidebar';
import OrderDropdown from './jsx/components/forms/OrderDropdown';
import OrderTabs from './jsx/components/forms/OrderTabs';

function loadOrderData(_eventTypeId, _eventCategory, _option, order_id) {
  if (!order_id) return;
  setTimeout(async () => {
    try {
      addSpinner();
      const order_result = await baseApiAxios.getOrder(order_id, {}, {});
      removeSpinners();
      $('#order-details-main').html(<OrderTabs data={order_result.order} />);
    } catch (err) {
      showErrorFromApiOperation('Fetching Order information failed.')(err);
    }
  }, 0);
}

export default function OrdersDetailsPage() {
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div class="my-2">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#orders">Orders</a>
                </li>
                <li class="breadcrumb-item"></li>
                <li id="resource-dropdown-container">
                  <OrderDropdown label="none" daysThreshold={7} onchange={loadOrderData} urlParams />
                </li>
                <li class="breadcrumb-item edit-account-show" style="display:none;"></li>
              </ol>
            </nav>
          </div>
          <div id="order-details-main" class="edit-account-hide my-5"></div>
        </div>
      </div>
    </>
  );

  function onPageReady() {
    removeAllSearchParamsUrlExcept(['order_id']);
    const $orderDropdown = $('#order-id');
    if (!getSearchParamsUrl('order_id')) {
      $orderDropdown.val(1);
      $orderDropdown.selectpicker('refresh');
    }
    $('#order-details-main').html(<OrderTabs data={{}} />);
  }

  return [page, onPageReady];
}

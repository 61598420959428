import ChartLegend from './ChartLegend';

export default function TileDonutChart(props) {
  const { id, title, headline, values, colors, labels, defaultLabel, partial, colspan } = props;

  const arcPartial = partial ? Math.floor(360 * partial) : 360;

  const labelsParam = labels || [];
  const valuesParam = values || [];
  const colorsParam = colors || [];
  const sum = values ? valuesParam.reduce((a, b) => a + b) : 1;
  const shares = values ? valuesParam.map(item => item / sum) : [];
  const headlineParam = values ? headline || `out of ${sum}` : '';

  let segmentBorder = 0;

  let donutTitle, donutZoom;

  return (
    <div id={id} class={`col-sm-12 col-md-12 col-lg-6 col-xl-${colspan || 4} mb-4`}>
      <div class="card dashboard-card">
        <div class="card-body d-flex flex-column pt-4 px-4">
          <div class="row">
            <div class="tile-text-secondary tile-title">{title}</div>
            <div>{<span class="tile-text-tertiary">{headlineParam}</span>}</div>
          </div>
          <div class="py-3"></div>
          <div class="mt-1 mb-3 text-center">
            <div class="d-flex flex-row py-3">
              <div class="flex-grow-1"></div>
              <div
                class="connectivity-donut-chart "
                style={{
                  background: `conic-gradient(
                      ${shares.map((share, index) => {
                        const text = `${colorsParam[index % colorsParam.length]} ${segmentBorder * arcPartial}deg ${
                          (segmentBorder + share) * arcPartial
                        }deg`;
                        segmentBorder += share;
                        return text;
                      })}
                      ${arcPartial !== 360 ? ',transparent ' + arcPartial + 'deg 360deg' : ''})`,
                }}>
                <div
                  class="donut-chart-zoom"
                  onmousemove={
                    values &&
                    (evt => {
                      if (!donutZoom) {
                        donutZoom = evt.target;
                        donutTitle = evt.target.parentElement.querySelector('.donut-title');
                      }
                      if (!donutTitle) return;
                      const clientRect = evt.target.getBoundingClientRect();
                      const centerX = (clientRect.right - clientRect.left) / 2 + clientRect.left;
                      const centerY = (clientRect.bottom - clientRect.top) / 2 + clientRect.top;
                      let pos = Math.atan2(evt.clientY - centerY, evt.clientX - centerX) * (180 / Math.PI) + 90;
                      if (pos < 0) pos = pos + 360;
                      let percentage = pos / arcPartial;
                      let sumOfShares = 0;
                      const index = shares.findIndex(share => {
                        sumOfShares += share;
                        return percentage < sumOfShares;
                      });
                      donutTitle.innerText =
                        index !== -1 ? `${labelsParam ? labelsParam[index] + '\n' : ''}${valuesParam[index]}` : sum;
                      donutZoom.style.background = `conic-gradient(${colorsParam[index]} 0deg, ${colorsParam[index]} ${
                        shares[index] * arcPartial
                      }deg, transparent ${shares[index] * arcPartial}deg)`;
                      donutZoom.style.transform = `translate(-50%,-50%) rotate(${
                        (sumOfShares - shares[index]) * arcPartial
                      }deg)`;
                    })
                  }
                  onmouseout={
                    values &&
                    (evt => {
                      evt.target.style.background = `transparent`;
                      evt.target.style.tranform = `translate(-50%,-50%)`;
                    })
                  }></div>
                <div class="donut-hole"></div>
                {values && <h1 class="donut-title">{defaultLabel || sum}</h1>}
              </div>
              <div class="flex-grow-1"></div>
            </div>
            <div class="mt-4">{values && <ChartLegend labels={labelsParam} colors={colorsParam}></ChartLegend>}</div>
          </div>
          <div class="flex-grow-1"></div>
        </div>
      </div>
    </div>
  );
}

import { initPrincipalsTable, loadPrincipalsTableData, principalsConfirm } from './js/portal/user-management';
import { Datatable } from './Datatable';
import AccountDropdown from './jsx/components/forms/AccountDropdown';

const tableId = 'table-principals';

export default function UsersListAndConfirmPage() {
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <form name="confirm-users" id="confirm-users" method="POST">
            <div class="card form-card">
              <div class="card-header">Select AWS Account</div>
              <div class="card-body">
                <div id="form-error" class="alert alert-danger" style="display: none">
                  <ul />
                </div>

                <div id="form-success" class="alert alert-success" style="display: none">
                  <ul />
                </div>

                <div id="infobox" class="card infobox">
                  <div class="card-body">
                    List all centrally managed Active Directory and IAM users with permissions to switch into the given
                    AWS Account. This data is generated once an hour.
                  </div>
                </div>

                <AccountDropdown id="aws-account-id" onchange={() => loadPrincipalsTableData(tableId)} short lookup />
              </div>

              <div class="card-footer d-none" id="confirmation-footer">
                <div class="alert w-auto d-flex justify-content-center p-2 m-2" role="alert" id="confirmation-info">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center gap-3">
                      <span>
                        <em id="approval-icon" class="fas fa-2x" />
                      </span>
                      <div>
                        <h6 id="approval-info" />
                        <h6 class="mb-0 me-4 d-none" id="approval-required" />
                      </div>
                    </div>

                    <span id="confirm-divider" class="divider bg-white d-none" />

                    <button id="confirm-user-button" type="submit" class="btn btn-primary d-none">
                      Confirm Users
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initPrincipalsTable(tableId);

    $('#confirm-users')
      .on('submit', function (e) {
        e.preventDefault();
      })
      .validate({
        submitHandler: function () {
          principalsConfirm(tableId);
        },
      });
  }

  return [page, onPageReady];
}

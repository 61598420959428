import { apiAxios, showError } from '../../../js/portal/api';
import { getSearchParamsUrl, setSearchParamsUrl } from '../../../js/portal/search_params';
import { removeDisabledOptions } from '../../../js/portal/main';

/**
 * Creates a new selectpicker dropdown including a label and a refresh button. The dropdown list will be populated
 * automatically with all AD users from our caching DB.
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} id - the id of the selectpicker
 * @param {function} onchange - a function that will be triggered once the selectpicker value changes
 * @param {type[]} selectedValues - a list of users that should be selected if available
 * @param {boolean} deselect - defines if the deselect user button is added to the end of the selectpicker
 * @param {boolean} multiple - defines if you can select one or multiple items in the user dropdown
 * @param {boolean} disableUrlParams - disable setting and reading URL parameters
 * @param {boolean} disabled - disable the select field
 *
 * @returns div including a label, the dropdown and a refresh button
 */
export default function UserDropdown({
  label,
  id,
  onchange,
  selectedValues,
  deselect,
  multiple,
  disableUrlParams,
  disabled,
}) {
  const labelText = label ? label : 'Federated AD User';
  const selectId = id ? id : 'user-email';
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = {};
  const multiSelect = multiple ? true : false;
  const urlParameters = !disableUrlParams ? true : false;
  const disabledAttribute = !disabled ? true : false;

  // Transfer the list of target selected values to lowercase to make it easier to compare. Otherwise we might see
  // quite some cases where the the actual user exists, just with a different format
  const selectedValuesLowerCase = selectedValues?.map(email => email.toLowerCase());

  // selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/
  const select = (
    <select
      required
      class="form-control form-select selectpicker"
      data-live-search="true"
      data-show-subtext="true"
      id={selectId}
      name={selectId}
      data-size="10"
      data-max-options="5"
      data-none-selected-text="- Select one or more AD users -"
      data-selected-text-format="count > 1"
      data-count-selected-text="{0} users selected"
      data-dropdown-align-right="auto"
      multiple={multiSelect}
      disabled={disabledAttribute}>
      <option class="defaultDisabled" value="" disabled selected={!multiSelect}>
        - Select an AD user -
      </option>
    </select>
  );

  // Refresh button to reload the user data
  const refreshIcon = <i class="fas fa-sync" />;
  const refreshButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      id="refresh-users"
      title="Reload User List">
      {refreshIcon}
    </button>
  );

  // De-select button
  const deselectButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-mid"
      id="deselect-users"
      title="De-select all users">
      <i class="fa fa-user-alt-slash" />
    </button>
  );

  // Represents the whole row including the label, the selectpicker and the refresh button
  const userDropdown = (
    <div class="form-group row portal-dropdown">
      {labelText != 'none' && (
        <label for="user-email" class="col-form-label">
          {labelText}
        </label>
      )}
      <div class="col input-group">
        {select}
        {deselect ? deselectButton : undefined}
        {refreshButton}
      </div>
    </div>
  );

  // Refresh the selectpicker and add an on change event
  $(select).selectpicker('refresh');
  $(select).on('changed.bs.select', () => {
    const selectedOption = $(select).find(':selected');
    const selectedEmail = selectedOption.attr('email');

    if (!multiSelect && urlParameters) {
      const previousUrlParam = getSearchParamsUrl('email');
      if (selectedEmail && selectedEmail !== previousUrlParam) {
        setSearchParamsUrl({ email: selectedEmail });
      }
    }

    if (onchange) {
      onchange(selectedOption, selectedEmail);
    }
  });

  // Reload the account records from the REST API (with cache-control headers)
  $(refreshButton).on('click', () => {
    loadUsers(select, refreshIcon, selectedValuesLowerCase, multiSelect, urlParameters, headers, params);
  });

  // De-select all users from the selectpicker
  $(deselectButton).on('click', () => {
    $(select).selectpicker('deselectAll');
    $(select).selectpicker('refresh');
  });

  // Load the user records from the REST API (without cache-control headers)
  loadUsers(select, refreshIcon, selectedValuesLowerCase, multiSelect, urlParameters);

  return userDropdown;
}

/**
 * Populates the selectpicker dropdown with the values from the REST API endpoint. Deletes all active dropdown items
 * before adding the new items.
 *
 * @param {JSX.IntrinsicElements.select} select
 * @param {JSX.Element} refreshIcon
 * @param {type[]} selectedValuesLowerCase
 * @param {boolean} multiSelect
 * @param {boolean} urlParameters
 * @param {object} headers
 * @param {object} params
 */
async function loadUsers(select, refreshIcon, selectedValuesLowerCase, multiSelect, urlParameters, headers, params) {
  $(refreshIcon).addClass('fa-spin');
  $(select).attr('disabled', true).selectpicker('refresh');

  apiAxios
    .get('/ad-users', { headers, params })
    .then(response => {
      // Delete all active options from the dropdown
      removeDisabledOptions(select);

      if (response.data.user_names) {
        // Retrieve an email from the URL search parameters
        const email = !multiSelect && urlParameters ? getSearchParamsUrl('email') : undefined;

        // Add all account items as options to the selectpicker
        const options = response.data.user_names.map(item => {
          const opt = document.createElement('option');
          opt.value = item;
          opt.innerText = item;
          opt.setAttribute('email', item);
          opt.selected =
            (email && email.toLowerCase() == item) || selectedValuesLowerCase?.includes(item) ? true : false;
          return opt;
        });

        // Sort the selectpicker options in asc order by the option text
        options.sort(function (a, b) {
          return a.text < b.text ? -1 : 1;
        });

        // Add all options to the selectpicker
        options.forEach(option => {
          select.appendChild(option);
          if (option.selected) {
            $(select).trigger('changed.bs.select');
          }
        });
      }
    })
    .catch(error => {
      showError(error);
    })
    .finally(() => {
      // Refresh the selectpicker to show the new options
      $(refreshIcon).removeClass('fa-spin');
      $(select).attr('disabled', false);
      $(select).selectpicker('refresh');
    });
}

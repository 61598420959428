export default function TileText(props) {
  const {
    id,
    title,
    latestValue,
    previousValue,
    headline,
    infoText,
    infoColor,
    selectOptions,
    selectChange,
    selectDefault,
    hideDifference,
    collapse,
    colspan,
    children,
    highlightColor,
  } = props;

  let diff = (((latestValue ? latestValue / previousValue : 1) - 1) * 100).toFixed(1);
  if (isNaN(diff)) diff = 0;

  let badgeClass;
  if (diff == 0.0) {
    badgeClass = 'tile-badge--gray';
  } else if (diff > 0) {
    badgeClass = 'tile-badge--green';
  } else {
    badgeClass = 'tile-badge--red';
  }

  return (
    <div id={id} class={`col-sm-12 col-md-12 col-lg-6 col-xl-${colspan || 4} mb-4`}>
      <div class="card dashboard-card py-0">
        <div
          class={`card-body d-flex flex-column overflow-hidden position-relative my-0 pt-4 px-4${
            collapse && ' card-body--collapsed'
          }`}
          onclick={
            collapse
              ? evt => {
                  evt.target.closest('.card-body').classList.toggle('card-body--collapsed');
                }
              : undefined
          }
          style={highlightColor ? { borderBottom: '1px solid', borderColor: highlightColor } : {}}>
          <div class="row">
            <div class="position-relative">
              <div class="tile-text-secondary tile-title">{title}</div>
              <div>
                {headline ? (
                  <span class="tile-text-tertiary">{headline}</span>
                ) : (
                  <>
                    <span class="tile-text-primary tile-latest-value">
                      {latestValue || (
                        <div class="col-form-label">
                          <span class="loading-form-inline loading-animation">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
                        </div>
                      )}
                    </span>
                    <span class="tile-text-secondary tile-previous-value">
                      {previousValue ? `from ${previousValue}` : ''}
                      <span style={infoColor ? { color: infoColor } : {}}>{infoText || ''}</span>
                    </span>
                  </>
                )}
              </div>
              {selectOptions && (
                <select class="form-select card-select-field" onchange={selectChange}>
                  {selectOptions.map(item => (
                    <option value={item} selected={selectDefault && selectDefault === item}>
                      {item}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          {!headline && !hideDifference && (
            <div class="tile-top-right">
              <div class={`tile-badge ${badgeClass}`}>{diff || '0.0'}%</div>
            </div>
          )}
          <div class="mt-3"></div>
          <div class="tile-content">
            <div class="tile-content-overflow">{children}</div>
          </div>
          {collapse && (
            <div class="tile-expand-button">
              <i class="fa fa-angle-down"></i>
              <i class="fa fa-angles-down"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default function TileValueInRange(props) {
  const { id, title, latestValue, previousValue, headline, range, rangeLabel, collapse, colspan, children } = props;

  const isFloat = n => {
    return Number(n) === n && n % 1 !== 0;
  };

  const latestValueParam = isFloat(latestValue) ? latestValue.toFixed(2) : latestValue;
  const previousValueParam = isFloat(previousValue) ? previousValue.toFixed(2) : previousValue;

  return (
    <div id={id} class={`col-sm-12 col-md-12 col-lg-6 col-xl-${colspan || 4} mb-4`}>
      <div class="card dashboard-card">
        <div
          class={`card-body d-flex flex-column position-relative pt-4 px-4${collapse && ' card-body--collapsed'}`}
          onclick={
            collapse
              ? evt => {
                  evt.target.closest('.card-body').classList.toggle('card-body--collapsed');
                }
              : undefined
          }>
          <div class="row">
            <div class="position-relative">
              <div class={`tile-text-secondary tile-title`}>{title}</div>
              <div>
                {headline ? (
                  <span class="tile-text-tertiary">{headline}</span>
                ) : (
                  <>
                    <span class={`tile-text-primary tile-latest-value tile-label--${range}`}>
                      {latestValueParam || (
                        <div class="col-form-label">
                          <span class="loading-form-inline loading-animation">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
                        </div>
                      )}
                    </span>
                    <span class="tile-text-secondary tile-previous-value">
                      {previousValueParam ? `from ${previousValueParam}` : ''}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          {range && (
            <div class="tile-top-right">
              <div class={`tile-badge tile-badge--${range}`}>{rangeLabel}</div>
            </div>
          )}
          <div class="mt-3"></div>
          <div class="tile-content">{children}</div>
          {collapse && (
            <div class="tile-expand-button">
              <i class="fa fa-angle-down"></i>
              <i class="fa fa-angles-down"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import { removeDisabledOptions } from '../../../js/portal/main';
import { baseApiAxios, showError } from '../../../js/portal/api';

/**
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} idPrefix - the id prefix of the selectpicker
 * @param {string} id - the id of the selectpicker
 * @param {function} onchange - a function that will be triggered once the selectpicker value changes
 * @param {string} selectedValue - a training type that should be selected if available
 * @param {boolean} disabled - defines if the selectpicker will be disabled
 *
 * @returns JSX.Element
 */
export default function WorkOrderGroupDropdown({ label, idPrefix, id, onchange, selectedValue, disabled }) {
  const labelText = label ? label : 'Request Assignee Support Group';
  const selectId = id ? id : 'assignee-group';
  const selectIdPrefix = idPrefix ? idPrefix : '';
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = {};

  // selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/
  const select = (
    <select
      class="form-control form-select selectpicker"
      data-live-search="true"
      data-show-subtext="true"
      id={selectIdPrefix + selectId}
      name={selectIdPrefix + selectId}
      data-size="10"
      data-none-selected-text="- Select the Request Assignee Support Group -"
      data-dropdown-align-right="auto"
      disabled={disabled ? true : false}
      required>
      <option class="defaultDisabled" value="" disabled selected>
        - Select the Request Assignee Support Group -
      </option>
    </select>
  );

  // Refresh button to reload the user data
  const refreshIcon = <i class="fas fa-sync" />;
  const refreshButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      id="refresh-request-assignee-support-groups"
      title="Reload Request Assignee Support Groups"
      disabled={disabled ? true : false}>
      {refreshIcon}
    </button>
  );

  // Represents the whole row including the label, the selectpicker and the refresh button
  const groupDropdown = (
    <div class="form-group row portal-dropdown">
      {labelText != 'none' && (
        <label for={selectIdPrefix + selectId} class="col-form-label">
          {labelText}
        </label>
      )}
      <div class="col input-group">
        {select}
        {refreshButton}
      </div>
    </div>
  );

  // Refresh the selectpicker and add an on change event
  $(select).selectpicker('refresh');
  $(select).on('changed.bs.select', () => {
    if (onchange) {
      const selectedOption = $(select).find(':selected');
      const selectedName = selectedOption.data('name');
      const selectedDescription = selectedOption.data('description');
      onchange(id, selectedOption, selectedName, selectedDescription);
    }
  });

  // Reload the training types from the REST API (with cache-control headers)
  $(refreshButton).on('click', () => {
    loadWorkOrderAssigneeGroups(select, refreshIcon, selectedValue, headers, params);
  });

  // Load the training types from the REST API (without cache-control-headers)
  loadWorkOrderAssigneeGroups(select, refreshIcon, selectedValue);
  return groupDropdown;
}

/**
 *
 * @param {JSX.IntrinsicElements.select} select
 * @param {JSX.Element} refreshIcon
 * @param {string} selectedValue
 * @param {object} headers
 * @param {object} params
 */
function loadWorkOrderAssigneeGroups(select, refreshIcon, selectedValue, headers, params) {
  $(refreshIcon).addClass('fa-spin');
  $(select).attr('disabled', true).selectpicker('refresh');

  const payload = {
    action: 'get-settings',
    settings: ['iris_work_orders'],
  };

  // POST request to collect the settings
  baseApiAxios
    .getSettings(payload, headers, params)
    .then(response => {
      // Delete all active items from the selectpicker
      removeDisabledOptions(select);

      if (response.iris_work_orders.support_groups) {
        const options = Object.entries(response.iris_work_orders.support_groups).map(([key, value]) => {
          const opt = document.createElement('option');
          opt.value = value.name;
          opt.dataset.description = value.description;
          opt.dataset.content = `<span class="bs-dropdown-badge">${value.name}</span><span class="bs-dropdown-item-text">${value.description}</span>`;
          opt.selected = key === selectedValue ? true : false;
          return opt;
        });

        // Sort the selectpicker options in asc order by the option text
        options.sort(function (a, b) {
          return a.text < b.text ? -1 : 1;
        });

        // Add all options to the selectpicker
        options.forEach(option => {
          select.appendChild(option);
          if (option.selected) {
            $(select).trigger('changed.bs.select');
          }
        });
      }
    })
    .catch(error => {
      showError(error);
    })
    .finally(() => {
      // Refresh the selectpicker to show the new options
      $(refreshIcon).removeClass('fa-spin');
      $(select).attr('disabled', false);
      $(select).selectpicker('refresh');
    });
}

import { baseApiAxios } from '../../../js/portal/api';
import {
  checkValueAgainstList,
  createJsonCodeBlock,
  InfoTable,
  showErrorFromApiOperation,
} from '../../../js/portal/main';
import { addSpinner, removeSpinners } from '../../../js/portal/sidebar';

function reloadOrders() {
  const orders_table = $('#table-orders');
  if (orders_table?.length) {
    let dt = orders_table.DataTable({
      retrieve: true,
    });
    dt.clear().draw();
    dt.ajax.reload();
    return;
  }
  $('select.selectpicker').trigger('reload-dropdown-options');
}

async function orderUpdateHandler(orderId, approvalStatus) {
  const payload = {
    approval: approvalStatus,
    approval_comment: document.getElementById(`approval-comment-${orderId}`)?.value || '',
  };

  try {
    addSpinner();
    await baseApiAxios.updateOrder(orderId, payload);
    reloadOrders();
    removeSpinners();
  } catch (err) {
    showErrorFromApiOperation('Order update failed.')(err);
  }
}

export default function OrderTabs(props) {
  const { data } = props;

  let permissions = localStorage.permissions ? localStorage.permissions : false;

  const orderDetails = Object.assign(data, data.payload?.reason ? { reason: data.payload.reason } : {});
  let orderDetailsKeys = [
    { Name: 'Order Id', Value: 'id' },
    { Name: 'Action', Value: 'action' },
    { Name: 'Proposer', Value: 'proposer' },
    { Name: 'Creation Date', Value: 'creation_date' },
    { Name: 'Comment', Value: 'approval_comment' },
    { Name: 'Approved', Value: 'approved' },
    { Name: 'Approver', Value: 'approver' },
    { Name: 'Approval Date', Value: 'approval_date' },
    { Name: 'Executed', Value: 'executed' },
    { Name: 'Execution Date', Value: 'execution_date' },
    { Name: 'Execution Response', Value: 'execution_response' },
  ];

  if (data.payload?.reason) orderDetailsKeys.splice(4, 0, { Name: 'Reason', Value: 'reason', HTML: true });

  const rawResponseEnabled = !!data.lambda_response;

  // Order
  const proposer_permissions = data.proposer_permissions
    ? [
        ...(data.proposer_permissions.idp_user_roles || []),
        ...(data.proposer_permissions.ad_group_roles || []),
        ...(data.proposer_permissions.iam_user_roles || []),
        ...(data.proposer_permissions.federated_user_roles || []),
        ...(data.proposer_permissions.cdh_user_roles || []),
      ]
    : [];

  return (
    <div class="detailsContainer">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            data-bs-target={'#order_details_' + data.id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="true"
            aria-expanded="true">
            Order
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link'}
            data-bs-target={'#raw_event_' + data.id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Raw Event
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (rawResponseEnabled ? '' : ' disabled')}
            data-bs-target={'#raw_response_' + data.id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Raw Response
          </a>
        </li>
      </ul>
      <div class="tab-content detailsTab">
        <div class="tab-pane fade active show" id={'order_details_' + data.id} role="tabpanel">
          <div class="row" name="order-details-container">
            <div class="col-lg-12 col-xl-7" name="order-details-left-side">
              <div class="detailsContent">
                <h4>Order Details</h4>
                <div>
                  <InfoTable data={orderDetails} rowInfos={orderDetailsKeys} />
                </div>
              </div>
              {checkValueAgainstList('manage_orders', permissions) && (data.approved === 0 || data.approved === 'NEW') && (
                <div class="detailsContent">
                  <textarea
                    class="form-control"
                    id={'approval-comment-' + data.id}
                    name="approval-comment"
                    rows={2}
                    maxlength={500}></textarea>
                </div>
              )}
            </div>
            <div class="col-lg-12 col-xl-5" name="order-details-right-side">
              {data.proposer_permissions && (
                <div class="detailsContent">
                  <h4>Proposer Permissions</h4>
                  <ul>
                    {proposer_permissions.map(permission => {
                      return <li>{permission}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id={'raw_event_' + data.id} role="tabpanel">
          <div class="row" name="raw-event-container">
            <div class="col-lg-12 col-xl-12">
              <div class="detailsContent">
                <h4>Raw Event Data</h4>
                {data.payload && createJsonCodeBlock(data.payload)}
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id={'raw_response_' + data.id} role="tabpanel">
          <div class="row" name="raw-response-container">
            <div class="col-lg-12 col-xl-12">
              {rawResponseEnabled && (
                <div class="detailsContent">
                  <h4>Raw Event Response</h4>
                  {createJsonCodeBlock(data.lambda_response)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div class="detailsContent">
          <div class="btn-group detail-actions-btn-group">
            {checkValueAgainstList('manage_orders', permissions) && (
              <>
                {[0, '0', 'NEW'].indexOf(data.approved) > -1 && (
                  <>
                    <button
                      type="button"
                      class="btn btn-success"
                      onclick={() => orderUpdateHandler(data.id, 'approve')}>
                      <span class="fas fa-check" /> Approve
                    </button>
                    <button type="button" class="btn btn-danger" onclick={() => orderUpdateHandler(data.id, 'reject')}>
                      <span class="fas fa-times" /> Decline
                    </button>
                  </>
                )}
                {[-1, '-1', 'FAILED', 'NOT_COMPLETED'].indexOf(data.executed) > -1 && (
                  <button type="button" class="btn btn-warning" onclick={() => orderUpdateHandler(data.id, 'retry')}>
                    <span class="fas fa-redo-alt" /> Retry
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

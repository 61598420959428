export const NetworksCreateSubnet = () => (
  <>
    <div class="form-group row">
      <label class="col-form-label fz-09">Name</label>
      <div class="col">
        <input
          required
          type="text"
          class="form-control"
          data-subnet-option="name"
          placeholder="e.g. subnet"
          minlength="3"
          maxlength="100"
          pattern="^([a-zA-Z0-9\-\_ ]{3,100})$"
          title="Please a valid Subnet Name. Length between 3 and 100 alphanumerical characters: ^([a-zA-Z0-9\-\_ ]{3,100})"/>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label fz-09">Subnet CIDR</label>
      <div class="col input-group mb-1 gap-10">
        <input
          required
          readonly
          type="text"
          class="form-control"
          data-subnet-option="address"
          value="Automatically assigned"/>
        <select
          required
          disabled
          data-subnet-option="size"
          class="form-control form-select selectpicker"
          data-live-search="true"
          data-show-subtext="true"
          data-size="5">
          <option value="" disabled selected>
            - Please select a network size -
          </option>
          <option value="auto">- Automatically assigned -</option>
        </select>
        <button type="button" disabled data-action="edit" class="btn btn-light btn-xs" title="Edit Subnet CIDR">
          <i class="fas fa-pencil-alt icon-color" />
          <span class="ms-2 link">Edit</span>
        </button>
        <button
          type="button"
          disabled
          data-action="auto"
          class="btn btn-light btn-xs"
          title="Automatically assign subnet CIDR">
          <i class="fas fa-magic icon-color" />
          <span class="ms-2 link">Assign Automatically</span>
        </button>
        <div class="valid-feedback" />
        <div class="invalid-feedback" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label fz-09">Availability Zone Name</label>
      <div class="col">
        <select
          required
          class="form-control form-select selectpicker"
          data-subnet-option="availability-zone"
          data-live-search="true"
          data-show-subtext="true">
          <option value="" disabled selected>
            - Please select the availability zone -
          </option>
          <option value="auto">- Automatically Assigned -</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label fz-09">NAT Gateway</label>
      <div class="col">
        <select
          class="form-control form-select selectpicker"
          data-subnet-option="nat-gateway"
          data-live-search="true"
          data-show-subtext="true">
          <option value="" disabled selected>
            - Not Available for Subnet Type -
          </option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label fz-09">NAT Gateway IP</label>
      <div class="col">
        <select
          disabled
          class="form-control form-select selectpicker"
          data-subnet-option="allocation-id"
          data-live-search="true"
          data-show-subtext="true">
          <option value="" disabled selected>
            - Not Available -
          </option>
        </select>
      </div>
    </div>
  </>
);

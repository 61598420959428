import { defaultColors } from '../../../js/portal/statistics';

export default function TileSeries(props) {
  const { id, title, headline, values, color, sort, hideLabels, tooltip, colspan } = props;

  let entries = [],
    max;
  if (values) {
    entries = sort ? Object.entries(values).sort((a, b) => b[1] - a[1]) : Object.entries(values);
    max = Math.max(...entries.map(([_sk, value]) => value));
  }

  let tooltipElement;

  const tooltipCallback = evt => {
    const tileBar = evt.target.closest('.tile-vertical-bar');
    if (!tooltipElement) {
      const cardBodyElement = evt.target.closest('.card-body');
      tooltipElement = cardBodyElement.querySelector('.tile-tooltip');
    }
    tooltipElement.style.left = `${evt.clientX}px`;
    tooltipElement.style.top = `${evt.clientY}px`;
    tooltipElement.innerHTML = `<b>${tileBar.dataset.label}</b> ${tileBar.dataset.value}`;
  };

  return (
    <div id={id} class={`col-sm-12 col-md-12 col-lg-12 col-xl-${colspan || 12} mb-4`}>
      <div class="card dashboard-card">
        <div class={`card-body d-flex flex-column position-relative pt-4 px-4`}>
          <div class="row">
            <div class="position-relative">
              <div class={`tile-text-secondary tile-title`}>{title}</div>
              <div>
                <span class="tile-text-tertiary">{headline}</span>
              </div>
            </div>
          </div>
          <div class="my-3"></div>
          <div class="tile-content">
            <div class={`d-flex ${hideLabels ? 'flex-row' : 'flex-row-reverse'} mx-3 graph-paper`}>
              {(!hideLabels || !values) && <div class="flex-grow-1"></div>}
              {values &&
                entries.map(([sk, value], index) => (
                  <div
                    class={`tile-vertical-bar${hideLabels ? ' hide-labels' : ''}`}
                    data-label={`${sk}`}
                    data-value={`${value}`}
                    onmouseenter={tooltip ? tooltipCallback : null}>
                    <div class="flex-grow-1"></div>
                    <div class="label">{`${value}`}</div>
                    <div
                      class="bar"
                      style={{
                        height: `${(value * 100) / max}%`,
                        backgroundColor: `${color || defaultColors[index % defaultColors.length]}`,
                      }}></div>
                  </div>
                ))}
              {!values && (
                <div
                  class="flex-grow-1 d-table-cell"
                  style={{ minHeight: '250px', lineHeight: '250px', textAlign: 'center' }}>
                  no data
                </div>
              )}
              {tooltip && <div class="tile-tooltip"></div>}
            </div>
            <div class="tile-chart-axes"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function DoesNotExistPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12 mt-3">
        <h5>The requested page does not exist.</h5>
        <a href="./#dashboard">
          <button type="button" class="btn btn-secondary mt-3">
            Return to Dashboard
          </button>
        </a>
      </div>
    </div>
  );

  function onPageReady() {
    console.log('DoesNotExistPage on page ready');
  }

  return [page, onPageReady];
}

import swal from 'sweetalert';
import { baseApiAxios, showError } from './js/portal/api';
import { addToast } from './js/portal/main';
import { removeSearchParamsUrl } from './js/portal/search_params';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import DatePickerField from './jsx/components/forms/DatePickerField';
import UserDropdown from './jsx/components/forms/UserDropdown';

/**
 * Check if the user has permissions for the selected AWS account to request the temporary purchasing role.
 * If the user has enough permissions, show the rest of the form with all input fields.
 */
function contentVisibilityHandler() {
  // TODO: This check needs to be extended most probably as it does not make sense to hardcode a value to true
  // and check directly afterwards if it's really true :)
  const hasPermission = true;
  if (hasPermission) {
    document.getElementById('submitButton').removeAttribute('disabled');
  } else {
    document.getElementById('request-role-info-container').style.display = '';
  }
}

function getUTCDateText(value) {
  return `${value.toDateString()} ${('0' + value.getUTCHours()).slice(-2)}:${('0' + value.getUTCMinutes()).slice(
    -2
  )}:00 UTC`;
}

/**
 * Set initial date values
 */
function setInitialDateValues() {
  // Identify the starting date, 3 days in the future from the current date
  const startDate = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
  const startDateValue = startDate.toLocaleDateString('sv');
  const startTimeValue = startDate.toLocaleTimeString('sv').substring(0, 5);

  // Set the start date
  const fieldStartDate = document.getElementById('start_date');
  fieldStartDate.value = startDateValue;
  fieldStartDate.setAttribute('min', startDateValue);

  // Set the start time
  const fieldStartTime = document.getElementById('start_time');
  fieldStartTime.value = startTimeValue;

  const fieldStartDateUtc = document.getElementById('start_date_utc');
  fieldStartDateUtc.innerText = getUTCDateText(startDate);

  // Update the end date, based on the start date
  updateDateValues();
}

/**
 * Update the end date, based on the start date
 */
function updateDateValues() {
  // Collect the start date and time values
  const startDateValue = document.getElementById('start_date').value;
  const startTimeValue = document.getElementById('start_time').value;

  const startDateUtc = new Date(`${startDateValue} ${startTimeValue}`);
  const timezoneOffset = new Date().getTimezoneOffset();
  const startDate = new Date(startDateUtc.getTime() - timezoneOffset * 60 * 1000);

  // Set the start date
  const fieldStartDatePayload = document.getElementById('start_date_utc_payload');
  fieldStartDatePayload.value = startDateUtc.toISOString().replace(/\.\d{3}Z/, 'Z');

  // Set start date UTC text
  const fieldStartDateUtc = document.getElementById('start_date_utc');
  fieldStartDateUtc.innerText = getUTCDateText(startDateUtc);

  // Get the target timespan in hours between the startDate and the target endDate
  const timespanHours = document.getElementById('hours').value;

  // Identify the end date, based on the starting date and the number of hours in the future
  const endDate = new Date(startDate.getTime() + (timespanHours * 60 + timezoneOffset) * 60 * 1000);

  // Set the end date
  const fieldEndDate = document.getElementById('end_date');
  const endDateValue = endDate.toLocaleDateString('sv');
  fieldEndDate.value = endDateValue;
  fieldEndDate.setAttribute('min', endDateValue);

  // Set the end time
  const fieldEndTime = document.getElementById('end_time');
  fieldEndTime.value = endDate.toLocaleTimeString('sv').substring(0, 5);

  // Set end date UTC text
  const fieldEndDateUtc = document.getElementById('end_date_utc');
  fieldEndDateUtc.innerText = getUTCDateText(endDate);
}

function resetFieldValidity(evt) {
  evt.target.setCustomValidity('');
}

export default function MarketplaceRequestRoleAccessPage() {
  let upfrontPayment;

  const questions = {
    'Reserved Instance': {
      ri_1: 'Number of RIs?',
      ri_2: 'Duration of the contract?',
      ri_3: 'Was the choice of the RI aligned with TAM/CF-Team/FinOps-Team?',
    },
    Marketplace: {
      mp_1: 'Planned duration of the contract?',
    },
  };

  const updateSelectPickers = () => $('.selectpicker').selectpicker('refresh');

  const handleCostsRadioChange = () => {
    const radioValue = document.querySelector('input[name="costs-marketplace-radio"]:checked').id;
    disableFormFields(document.getElementById('ekw-container'));
    if (radioValue === 'costs-marketplace-per-month') {
      enableFormFields(document.getElementById('costs-shared'));
      upfrontPayment = false;
    } else {
      disableFormFields(document.getElementById('costs-shared'));
      upfrontPayment = true;
      swal({
        text: `Dear Customers,

        Upfront payments present a compliance challenge, create significant additional work, and are only approved, if there is no other option.
        Please contact martin.ha.hoechtl@bmw.de for more information.
        
         
        BR,
        Cloud Foundation Team`,
        icon: 'info',
        buttons: {
          reload: {
            text: 'OK',
            value: true,
            visible: true,
          },
        },
      });
    }
  };

  const handleEKWRadioChange = () => {
    const radioValue = document.querySelector('input[name="ekw-number-radio"]:checked').id;
    const textInput = document.getElementById('ekw_number');
    if (radioValue === 'ekw-number-yes') {
      textInput.disabled = false;
    } else {
      textInput.value = '';
      textInput.disabled = true;
    }
  };

  const hideAndDisable = () => {
    document.querySelectorAll('.dynamic-form-container').forEach(container => container.classList.add('d-none'));
    document.querySelectorAll('.dynamic-form-element').forEach(field => {
      if (field.type === 'radio') {
        field.checked = false;
      } else {
        field.value = '';
      }
      field.disabled = true;
    });
    updateSelectPickers();
  };

  const resetForm = form => {
    $(form).trigger('reset');
    form.classList.remove('was-validated');
    setInitialDateValues();
    removeSearchParamsUrl();
    hideAndDisable();
    enableFormFields(document.getElementById('form-reason'));
    $('.selectpicker').val('').selectpicker('deselectAll').selectpicker('refresh');
  };

  const enableDropdown = container => {
    container.classList.remove('d-none');
    const dropdownField = container.querySelector('select');
    dropdownField.setCustomValidity('required field');
    dropdownField.disabled = false;
    dropdownField.dispatchEvent(new Event('change'));
    updateSelectPickers();
  };

  const enableFormFields = container => {
    container.classList.remove('d-none');
    container.querySelectorAll('input').forEach(field => {
      field.disabled = false;
    });
    container.querySelectorAll('textarea').forEach(field => {
      field.disabled = false;
    });
  };

  const disableFormFields = container => {
    container.classList.add('d-none');
    container.querySelectorAll('input').forEach(field => {
      if (field.type === 'radio') {
        field.checked = false;
      } else {
        field.value = '';
      }
      field.disabled = true;
    });
    container.querySelectorAll('textarea').forEach(field => {
      field.value = '';
      field.disabled = true;
    });
  };

  const page = (
    <div className="row">
      <div className="col-lg-12">
        <form
          name="request-role-access-form"
          id="request-role-acccess-form"
          className="needs-validation"
          method="POST"
          noValidate>
          <div className="card form-card">
            {/* CARD HEADER */}
            <div className="card-header">Request Access to Marketplace and Reserved Instances Purchasing Role</div>

            {/* CARD BODY */}
            <div className="card-body">
              {/* Infobox */}
              <div id="infobox" className="card infobox">
                <div className="card-body">
                  <p>
                    For compliance reasons we have to limit the access to the AWS Marketplace and the purchasing of
                    Reserved Instances (RI) for example for EC2, RDS, Redshift.
                  </p>
                  <p>
                    With this form you can request 24h temporary access to a special purchasing role{' '}
                    <code>fpc/BMWPurchasing</code> in your target AWS account. This IAM role has limited access and only
                    allows you to use the AWS Marketplace and to buy RIs. After 24h your access will be automatically
                    removed again. You'll get an email notification once you get access to the IAM role and another time
                    when you lost it again.
                  </p>
                  <p>
                    Find more information in the{' '}
                    <a
                      href="https://developer.bmw.com/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/marketplace/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Developer Portal - AWS Marketplace">
                      Developer Portal
                      <i className="px-1 fas fa-external-link-alt" />
                    </a>
                  </p>
                </div>
              </div>

              <div id="request-role-form-container">
                <input type="hidden" id="action" name="action" value="grant-purchase-role" />

                {/* AWS Account */}
                <div className="row">
                  <label for="account_id" className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">AWS Account</span>
                  </label>
                  <div className="col-md-12 col-lg-10 col-xl-10">
                    <AccountDropdown id="account_id" label="none" onchange={contentVisibilityHandler} short lookup />
                    <div class="invalid-feedback">You must select an option.</div>
                  </div>
                </div>

                {/* Email / Assign to */}
                <div className="row">
                  <label for="email" className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">Assign to</span>
                  </label>
                  <div className="col-md-12 col-lg-10 col-xl-10">
                    <UserDropdown label="none" selectedValues={[]} disableUrlParams id="email" />
                    <div class="invalid-feedback">You must select an option.</div>
                  </div>
                </div>

                {/* Products */}
                <div className="form-group row portal-dropdown">
                  <label for="product_type" className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">Products</span>
                  </label>
                  <div className="col-md-12 col-lg-10 col-xl-10">
                    <div className="form-group row portal-dropdown">
                      <div className="col input-group">
                        <select
                          required
                          className="form-control form-select selectpicker"
                          data-live-search="true"
                          data-show-subtext="true"
                          id="product_type"
                          name="product_type"
                          onchange={evt => {
                            const selectedItem = $(evt.target).selectpicker('val');
                            const selectedItemSlug = selectedItem === 'Marketplace' ? 'marketplace' : 'reserved-instance';
                            hideAndDisable();
                            enableDropdown(document.getElementById(`dropdown-${selectedItemSlug}`));
                            if (selectedItem === 'Marketplace') {
                              const marketplaceService = $('#services').selectpicker('val');
                              if (marketplaceService === 'not-listed') {
                                document.getElementById('reason-container').classList.add('d-none')
                                enableFormFields(document.getElementById('service-name-container'))
                                return;
                              }
                            }
                            document.getElementById('reason-container').classList.remove('d-none')
                            enableFormFields(document.getElementById(`form-${selectedItemSlug}`));
                            enableFormFields(document.getElementById('form-reason'));
                            enableFormFields(document.getElementById(
                              `costs-${selectedItem === 'Marketplace' ? 'marketplace' : 'shared'}`
                            ));
                          }}>
                          <option className="defaultDisabled" value="" disabled selected>
                            - Select Product -
                          </option>
                          <option value="Marketplace">Marketplace</option>
                          <option value="Reserved Instance">Other Reserved Instances</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Instances */}
                <div
                  id="dropdown-reserved-instance"
                  className="dynamic-form-container form-group row portal-dropdown d-none">
                  <label for="instances" className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">Instances</span>
                  </label>
                  <div className="col-md-12 col-lg-10 col-xl-10">
                    <div className="form-group portal-dropdown">
                      <div className="col input-group">
                        <select
                          required
                          className="form-control form-select selectpicker dynamic-form-element"
                          data-live-search="true"
                          data-show-subtext="true"
                          id="instances"
                          name="instances"
                          data-size="10"
                          data-max-options="5"
                          data-none-selected-text="- Select one or more Instances -"
                          data-selected-text-format="count > 5"
                          data-count-selected-text="{0} Instances selected"
                          data-dropdown-align-right="auto"
                          onchange={evt => {
                            const selection = $(evt.target).selectpicker('val');
                            const newSelection = selection.filter(selectedItem => {
                              if (selectedItem === 'EC2') {
                                swal({
                                  text: `Dear Customers,

                                  At BMW, we use centrally managed Savings Plans to optimize the cost of compute.
                                  Hence, Reserved Instances are not supported for EC2.
                                  
                                  
                                  BR,
                                  Cloud Foundation Team`,
                                  icon: 'info',
                                  buttons: {
                                    reload: {
                                      text: 'OK',
                                      value: true,
                                      visible: true,
                                    },
                                  },
                                });
                              } else if (selectedItem === '') {
                                return false;
                              }
                              return true;
                            });
                            $(evt.target).selectpicker('val', newSelection);
                            evt.target.setCustomValidity('');
                          }}
                          multiple="true">
                          <option className="defaultDisabled" disabled selected>
                            - Select Instance -
                          </option>
                          <option value="EC2">EC2</option>
                          <option value="ElastiCache">ElastiCache</option>
                          <option value="ElasticSearch">ElasticSearch</option>
                          <option value="RDS">RDS</option>
                          <option value="Redshift">Redshift</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Services */}
                <div id="dropdown-marketplace" className="dynamic-form-container row mb-3 d-none">
                  <label for="services" className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">Services</span>
                  </label>
                  <div className="col-md-12 col-lg-10 col-xl-10">
                    <div className="form-group portal-dropdown">
                      <div className="col input-group">
                        <select
                          required
                          className="form-control form-select selectpicker dynamic-form-element"
                          data-live-search="true"
                          data-show-subtext="true"
                          id="services"
                          name="services"
                          data-size="10"
                          onchange={evt => {
                            evt.target.setCustomValidity('');
                            const selection = $(evt.target).selectpicker('val');
                            if (selection === 'not-listed') {
                              enableFormFields(document.getElementById('service-name-container'));
                              disableFormFields(document.getElementById('reason-container'));
                            } else {
                              disableFormFields(document.getElementById('service-name-container'));
                              document.getElementById('reason-container').classList.remove('d-none');
                              enableFormFields(document.getElementById('form-marketplace'));
                              enableFormFields(document.getElementById('form-reason'));
                              enableFormFields(document.getElementById('costs-marketplace'))
                            }
                          }}>
                          <option className="defaultDisabled" disabled selected>
                            - Select Services -
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Service Name */}
                <div id="service-name-container" className="form-group row d-none dynamic-form-container">
                  <label className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">Service Name</span>
                  </label>
                  <div className="col-md-12 col-lg-10 col-xl-10">
                    <input
                      type="text"
                      id="service_name"
                      name="service_name"
                      className="form-control dynamic-form-element"
                      placeholder="Name of not yet whitelisted Service"
                      required
                      disabled
                    />
                    <div class="invalid-feedback">You must provide the name of the service.</div>
                  </div>
                </div>

                {/* Reason */}
                <div id="reason-container" className="form-group row">
                  <label className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">Reason</span>
                  </label>
                  <div className="col-md-12 col-lg-10 col-xl-10">
                    <div
                      id="form-reserved-instance"
                      data-title="Reserved Instances"
                      className="form-container dynamic-form-element mb-3 d-none dynamic-form-container">
                      <div>
                        <input
                          type="text"
                          id="ri_1"
                          name="ri_1"
                          placeholder={questions['Reserved Instance']['ri_1']}
                          className="form-control form-question-field dynamic-form-element"
                          required
                          disabled
                        />
                        <div class="invalid-feedback">You must provide answers to each question.</div>
                      </div>
                      <div>
                        <input
                          type="text"
                          id="ri_2"
                          name="ri_2"
                          placeholder={questions['Reserved Instance']['ri_2']}
                          className="form-control form-question-field mt-3 dynamic-form-element"
                          required
                          disabled
                        />
                        <div class="invalid-feedback">You must provide answers to each question.</div>
                      </div>
                      <div className="form-question-field mt-3">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="px-1 align-self-center mr-4">{questions['Reserved Instance']['ri_3']}</div>
                          <div className="form-check align-self-center mx-4">
                            <input
                              className="form-check-input dynamic-form-element"
                              type="radio"
                              name="ri_3"
                              id="reserved-instance-no"
                              value="no"
                              required
                              disabled
                            />
                            <label className="form-check-label" for="reserved-instance-no">
                              No
                            </label>
                          </div>
                          <div className="form-check align-self-center mx-4">
                            <input
                              className="form-check-input dynamic-form-element"
                              type="radio"
                              name="ri_3"
                              id="reserved-instance-yes"
                              value="yes"
                              required
                              disabled
                            />
                            <label className="form-check-label" for="reserved-instance-yes">
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="form-marketplace"
                      data-title="Marketplace"
                      className="form-container dynamic-form-element mb-3 d-none dynamic-form-container">
                      <div>
                        <input
                          type="text"
                          id="mp_1"
                          name="mp_1"
                          placeholder={questions['Marketplace']['mp_1']}
                          className="form-control form-question-field dynamic-form-element"
                          required
                          disabled
                        />
                        <div class="invalid-feedback">You must provide answers to each question.</div>
                      </div>
                    </div>

                    <div id="form-reason" className="position-relative form-container form-textarea dynamic-form-container" data-title="Other">
                      <textarea
                        required
                        rows="4"
                        id="reason"
                        name="reason"
                        placeholder="Please provide the name of the service and the reason why you want to order it."
                        className="form-control position-relative form-question-field dynamic-form-element"
                        maxlength="2000"></textarea>
                      <div class="invalid-feedback">You must provide a reason for the purchase.</div>
                      <div className="form-textarea-char-counter">
                        <span className="char-count">0</span>/2000
                      </div>
                    </div>

                    <div
                      id="costs-marketplace"
                      className="form-container mt-3 d-none dynamic-form-container"
                      data-title="Payment Type">
                      <div className="form-check">
                        <input
                          className="form-check-input dynamic-form-element"
                          type="radio"
                          onchange={handleCostsRadioChange}
                          name="costs-marketplace-radio"
                          id="costs-marketplace-per-month"
                          required
                          disabled
                        />
                        <label className="form-check-label" for="costs-marketplace-per-month">
                          Per Month
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input dynamic-form-element"
                          type="radio"
                          onchange={handleCostsRadioChange}
                          name="costs-marketplace-radio"
                          id="costs-marketplace-upfront"
                          required
                          disabled
                        />
                        <label className="form-check-label" for="costs-marketplace-upfront">
                          Upfront Per Year
                        </label>
                      </div>
                    </div>

                    <div
                      id="costs-shared"
                      className="form-container dynamic-form-element mt-3 dynamic-form-container"
                      data-title="Estimated Monthly Payment">
                      <input
                        type="text"
                        id="estimated_monthly_payment"
                        name="estimated_monthly_payment"
                        placeholder="Planned monthly costs (No Upfront Payment)"
                        className="form-control form-question-field dynamic-form-element"
                        oninput={evt => {
                          const value = parseInt(evt.target.value);
                          const ekwContainer = document.getElementById('ekw-container');
                          if (value > 10000) {
                            ekwContainer.classList.remove('d-none');
                            ekwContainer.querySelectorAll('input[type="radio"]').forEach(field => (field.disabled = false));
                          } else {
                            ekwContainer.classList.add('d-none');
                            ekwContainer.querySelectorAll('input').forEach(field => {
                              if (field.type === 'radio') {
                                field.checked = false;
                              } else {
                                field.value = '';
                              }
                              field.disabled = true;
                            });
                          }
                        }}
                        required
                      />
                      <div class="invalid-feedback">You must provide the monthly costs.</div>
                    </div>

                    <div id="ekw-container" className="row dynamic-form-element mt-3 d-none dynamic-form-container" data-title="EKW">
                      <div className="col-md-12 col-lg-6 col-xl-6">
                        <div className="d-flex h-100 flex-row justify-content-between">
                          <div className="px-1 align-self-center mr-4">Do you have an EKW number?</div>
                          <div className="form-check align-self-center mx-4">
                            <input
                              className="form-check-input dynamic-form-element"
                              type="radio"
                              onchange={handleEKWRadioChange}
                              name="ekw-number-radio"
                              id="ekw-number-no"
                              required
                              disabled
                            />
                            <label className="form-check-label" for="ekw-number-no">
                              No
                            </label>
                          </div>
                          <div className="form-check align-self-center mx-4">
                            <input
                              className="form-check-input dynamic-form-element"
                              type="radio"
                              onchange={handleEKWRadioChange}
                              name="ekw-number-radio"
                              id="ekw-number-yes"
                              required
                              disabled
                            />
                            <label className="form-check-label" for="ekw-number-yes">
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6 col-xl-6">
                        <input
                          type="text"
                          id="ekw_number"
                          name="ekw_number"
                          placeholder="EKW number"
                          className="form-control form-question-field dynamic-form-element"
                          required
                          disabled
                        />
                        <div class="invalid-feedback">You must provide your EKW number.</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Date Start */}
                <div className="form-group row">
                  <label for="start_date" className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">Start</span>
                  </label>
                  <div className="col-md-12 col-lg-5 col-xl-5">
                    <DatePickerField id="start_date" onchange={updateDateValues} />
                    {/* This hidden input is used to  */}
                    <input type="hidden" id="start_date_utc_payload" name="start_date_utc" />
                    <div className="form-description-text" id="start_date_utc"></div>
                  </div>
                  <div className="col-md-12 col-lg-5 col-xl-5">
                    <input
                      type="text"
                      id="start_time"
                      onchange={updateDateValues}
                      onfocus={evt => {
                        evt.target.type = 'time';
                      }}
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Date Till */}
                <div className="form-group row">
                  <label for="start_date" className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">End</span>
                  </label>
                  <div className="col-md-12 col-lg-5 col-xl-5">
                    <DatePickerField id="end_date" disabled />
                    <input type="hidden" name="end_date" disabled />
                    <div className="form-description-text" id="end_date_utc"></div>
                  </div>
                  <div className="col-md-12 col-lg-5 col-xl-5">
                    <input type="text" id="end_time" className="form-control" disabled />
                  </div>
                </div>

                {/* Hours */}
                <div className="form-group row d-none">
                  <label for="hours" className="col-md-12 col-lg-2 col-xl-2 form-label-text">
                    <span className="float-md-start float-lg-end mx-xl-3">Hours</span>
                  </label>
                  <div className="col-md-12 col-lg-10 col-xl-10">
                    <input type="number" id="hours" name="hours" value="24" className="form-control" min="1" max="24" />
                  </div>
                </div>

                <div className="form-group row">
                  <div for="hours" className="col-md-12 col-lg-2 col-xl-2 form-label-text"></div>
                  <div className="col-md-12 col-lg-10 col-xl-10">
                    <div class="alert alert-primary" role="alert">
                      The start and end date refers to the activation of the fpc/BMWPurchasing role. If it is active,
                      you still need to switch to the role in the AWS portal before making the purchase.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* CARD FOOTER */}
            <div className="card-footer">
              <div className="row px-2">
                <div className="col">
                  <button type="button" id="resetButton" className="btn btn-secondary" onclick={resetForm}>
                    Reset
                  </button>
                </div>
                <div className="col" align="right">
                  <button type="submit" id="submitButton" className="btn btn-success">
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Request Permissions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    setInitialDateValues();

    document.querySelectorAll('input').forEach(field => field.addEventListener('input', resetFieldValidity));
    document.querySelectorAll('textarea').forEach(field => field.addEventListener('input', resetFieldValidity));

    // initialize reason text field and char counter
    const reasonFormField = document.getElementById('reason');
    const reasonCharCount = document.querySelector('.form-textarea-char-counter > .char-count');
    let charCount = 0;
    const debounceEvent =
      (fct, time = 100, timeout) =>
      parameter =>
        clearTimeout(timeout, (timeout = setTimeout(fct, time, parameter)));
    const updateCharCount = () => {
      charCount = reasonFormField.value.length;
      reasonCharCount.innerText = charCount;
    };
    const keypressCounter = evt => {
      charCount += evt.key.length === 1 || -1 * (evt.key.length === 9);
      if (charCount <= 2000) {
        if (charCount < 0) charCount = 0;
        reasonCharCount.innerText = charCount;
      }
    };
    var debouncedUpdateCharCount = debounceEvent(updateCharCount, 500);
    reasonFormField.addEventListener('input', debouncedUpdateCharCount, false);
    reasonFormField.addEventListener('keyup', keypressCounter, false);

    // get whitelisted marketplace prdoucts from the backend
    const servicesDropdown = document.getElementById('services');
    if (servicesDropdown) {
      addSpinner();
      baseApiAxios
        .getWhitelistedServices()
        .then(marketplaceResult => {
          if (marketplaceResult.services && marketplaceResult.services.listingsByProductIds) {
            marketplaceResult.services.listingsByProductIds.forEach(serviceOption => {
              const displayAttrs = serviceOption.summary.displayAttributes;
              $(servicesDropdown).append(<option value={displayAttrs.title}>{displayAttrs.title}</option>);
            });
          }
          $(servicesDropdown).append(
            <option value="not-listed" data-subtext="Service is not yet whitelisted">
              Not in list
            </option>
          );
          updateSelectPickers();
        })
        .catch(orderError => {
          showError(orderError);
        })
        .finally(() => {
          removeSpinners();
        });
    }

    updateSelectPickers();

    // Initialize the form
    const form = document.querySelector('form[name="request-role-access-form"]');
    form.addEventListener('submit', evt => {
      evt.preventDefault();
      evt.stopPropagation();

      const formData = new FormData(form);
      let orderPayload = Array.from(formData.entries()).reduce((memo, [key, value]) => {
        return {
          ...memo,
          [key]: memo[key] ? (Array.isArray(memo[key]) ? [...memo[key], value] : [memo[key], value]) : value,
        };
      }, {});

      if (orderPayload['services']) {
        if (orderPayload['services'] !== 'not-listed') {
          orderPayload['service_name'] = orderPayload['services'];
          orderPayload['service_name_in_list'] = true;
        } else {
          orderPayload['service_name_in_list'] = false;
        }
      }

      orderPayload['estimated_monthly_payment'] = parseInt(orderPayload['estimated_monthly_payment']) || 0;
      orderPayload['upfront_payment'] = upfrontPayment;

      let deleteKeys = [];
      orderPayload['reason'] += `\n`;
      const questionsArray = Object.entries(questions[orderPayload['product_type']]);
      questionsArray.forEach(([key, _question]) => {
        if (['mp_1','ri_2'].indexOf(key) !== -1) {
          orderPayload['duration'] = orderPayload[key];
        } else if (key === 'ri_1') {
          orderPayload['number_of_instances'] = parseInt(orderPayload[key]) || 0;
        } else if (key === 'ri_3') {
          orderPayload['aligned_with_team'] = orderPayload[key] === 'yes';
        }
        deleteKeys.push(key);
      });

      if (orderPayload['instances']) orderPayload['instance_names'] = orderPayload['instances'];

      ['ekw-number-radio', 'costs-marketplace-radio', 'services', 'instances']
        .concat(deleteKeys)
        .forEach(deleteKey => { delete orderPayload[deleteKey]; });

      form.classList.add('was-validated');

      if (!form.checkValidity()) {
        form.reportValidity();
        console.log('invalid form values');
        return;
      }

      orderPayload['description'] = `Grant Marketplace purchasing role for account ${orderPayload['account_id']}`;

      addSpinner();
      addButtonSpinner();
      //Create the order and show a toast with the response message
      baseApiAxios
        .createOrder(orderPayload)
        .then(orderResponse => {
          addToast('Create Order', orderResponse.message, 6000);
        })
        .catch(orderError => {
          showError(orderError);
        })
        .finally(() => {
          resetForm(form);
          removeSpinners();
        });
    });
  }

  return [page, onPageReady];
}

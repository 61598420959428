import { baseApiAxios, showError } from './js/portal/api';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { hasAccess, addToast } from './js/portal/main';
import IamUserSettings from './jsx/components/forms/IamUserSettings';

/**
 * Collect the IAM user settings for the target AWS account and display the information accordion if there is any
 * information to show. The response could contain information about user creation and about regular updates.
 *
 * @param {string} accountId the target AWS account id
 * @param {string} parentId the DOM element id of the parent of the info accordion
 */
function collectIamUserSettings(accountId, parentId) {
  // Reset the information accordion to avoid showing the wrong information
  const iamUserSettingsDiv = document.getElementById(parentId);
  iamUserSettingsDiv.textContent = '';
  iamUserSettingsDiv.classList = '';

  // Get the account specific IAM user settings to show
  baseApiAxios
    .get('/accounts/' + accountId + '/iam-user-settings')
    .then(response => {
      const userSettings = IamUserSettings(response);
      if (userSettings) {
        iamUserSettingsDiv.classList = 'form-group row';
        iamUserSettingsDiv.append(<label for="user-name-suffix" class="col-form-label"></label>);
        iamUserSettingsDiv.append(userSettings);
      }
    })
    .catch(err => {
      console.error(err);
    });
}

export default function ServiceUsersCreatePage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="service-user-create-form" id="service-user-create-form" method="POST">
          <div class="card form-card">
            <div class="card-header">Create New IAM Service User</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  <p>
                    With this form you can order a new <strong>AWS IAM Service User</strong> in your target AWS Account.
                    If you need more than one user, please raise multiple orders. Please be aware, that user names at
                    AWS are <strong>case sensitive</strong>.
                  </p>
                  <p>
                    IAM Users are used for technical machine2machine-communication and are{' '}
                    <span class="portal-danger">NOT permitted for personal use</span>. Find more information in the{' '}
                    <a
                      href="https://developer.bmw.com/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/overview_iam/iam/#iam-service-users"
                      target="_blank"
                      rel="noopener noreferrer">
                      Developer Portal
                      <i class="px-1 fas fa-external-link-alt" />
                    </a>
                  </p>
                </div>
              </div>

              <AccountDropdown
                id="aws-account-id"
                short
                lookup
                onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                  collectIamUserSettings(accountId, 'iam-user-settings');
                  document.getElementById('submitButton').disabled = !hasAccess(accountId, ['manage_accounts']);
                }}/>

              <div id="iam-user-settings"></div>

              <div class="form-group row">
                <label for="user-name-suffix" class="col-form-label">
                  User Name
                </label>
                <div class="col">
                  <div class="input-group">
                    <span class="input-group-text fw-bold" id="dns-suffix">
                      service.
                    </span>
                    <input
                      required
                      type="text"
                      class="form-control"
                      id="user-name-suffix"
                      name="user-name-suffix"
                      placeholder="example"/>
                  </div>
                </div>
              </div>

              <div class="form-group row" id="form_group_comment">
                <label for="comment" class="col-form-label">
                  Comment
                </label>
                <div class="col">
                  <textarea
                    rows="3"
                    class="form-control"
                    id="comment"
                    name="comment"
                    placeholder="some additional information"/>
                </div>
              </div>
            </div>

            <div class="card-footer" align="right">
              <input type="hidden" name="action" id="action" value="createJob" />
              <button type="submit" class="btn btn-success" id="submitButton" value="Create">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  /**
   * Will reset the input fields of the form without reloading the whole page
   */
  function resultFunction() {
    const form = document.getElementById('service-user-create-form');
    form.reset();
    const submitButton = document.getElementById('submitButton');
    $(submitButton).removeClass('loading-animation');
    $(submitButton).attr('disabled', false);
    removeSpinners();
  }

  function onPageReady() {
    $('#service-user-create-form')
      .on('submit', function (e) {
        e.preventDefault();
        $('#form-error').hide();
        $('#form-success').hide();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        rules: {
          'user-name-suffix': {
            pattern: /^[A-Za-z0-9+.,@_-]*$/,
          },
        },
        messages: {
          'user-name-suffix': {
            required: 'Please enter a user name.',
            pattern: 'The user name contains invalid characters.',
          },
        },
        submitHandler: function () {
          addSpinner();
          addButtonSpinner();
          const user_name = 'service.' + $('#user-name-suffix').val();
          const payload = {
            action: 'create-service-user',
            description: 'Create Service User "' + user_name + '"',
            user_name: user_name,
            account_id: $('#aws-account-id')[0].value.split(';')[0],
            comment: $('#comment')[0].value || false,
          };

          baseApiAxios
            .createOrder(payload)
            .then(orderResponse => {
              resultFunction();
              addToast('Create Order', orderResponse.message, 6000);
            })
            .catch(orderError => {
              showError(orderError);
            });
        },
      });
  }

  return [page, onPageReady];
}

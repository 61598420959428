import { hideApiKeyPermissionsEditForm, validateApiKeyPermissionsEditForm } from './js/portal/apikeys';

export default function ApiKeyPermissionsEditForm() {
  return (
    <>
      {/* EDIT API KEY FORM */}
      <div class="col-lg-12">
        <form name="edit-permissions-form" id="edit-permissions-form" method="POST">
          <div class="card form-card">
            <div id="card-heading-text" class="card-header">
              Edit Api Key Permissions in DynamoDB
            </div>

            <div class="card-body">
              {/* WARNING AND SUCCESS */}
              <div>
                <div id="form-error" class="alert alert-danger" style="display: none">
                  <ul />
                </div>
                <div id="form-success" class="alert alert-success" style="display: none">
                  <ul />
                </div>
              </div>

              <div>
                {/* FIRST COLUMN OF THE FORM */}
                <div class="col-lg-6 col-md-12 col-12">
                  {/* API KEY ID */}
                  <div class="form-group row" id="form_group_key_id">
                    <label for="api_key_id_permissions" class="col-form-label">
                      Key Id
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="api_key_id_permissions"
                        name="api_key_id_permissions"
                        disabled/>
                    </div>
                  </div>
                  <div id="permissions-container" />
                  {/* USAGE PLAN ID */}
                  <div class="form-group row" id="form_group_usage_plan_id" style="display: none">
                    <label for="usage_plan_id_permissions" class="col-form-label">
                      Usage Plan Id
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="usage_plan_id_permissions"
                        name="usage_plan_id_permissions"
                        disabled/>
                    </div>
                  </div>
                  {/* TARGET API ONLY FOR CONTROL */}
                  <div class="form-group row" id="form_group_target_api" style="display: none">
                    <label for="target_api_permissions" class="col-form-label">
                      Target Api
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="target_api_permissions"
                        name="target_api_permissions"
                        disabled/>
                    </div>
                  </div>
                  {/* ACCOUNT ID ONLY FOR CONTROL */}
                  <div class="form-group row" id="form_group_provider_id" style="display: none">
                    <label for="provider_id_permissions" class="col-form-label">
                      Accont Id
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="provider_id_permissions"
                        name="provider_id_permissions"
                        disabled/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer" align="right">
              <input
                type="button"
                class="btn btn-custom"
                name="hide-edit-form"
                id="hide-edit-form"
                value="Cancel"
                onclick={() => hideApiKeyPermissionsEditForm()}/>
              <input
                type="button"
                class="btn btn-success"
                name="update-api-key"
                id="update-api-key"
                value="Update Permissions"
                onclick={() => validateApiKeyPermissionsEditForm()}/>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

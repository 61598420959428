import { initWorkOrdersTable } from './js/portal/work_orders';
import { Datatable } from './Datatable';

const tableId = 'table-work-orders';

export default function AdminWorkOrdersListPage() {
  const page = (
    <>
      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initWorkOrdersTable(tableId);
  }

  return [page, onPageReady];
}

export const Fragment = Symbol('JSX Fragment');

/**
 * Map of attributes that need special handling.
 */
const specialAttrSetters = {
  style(element, value) {
    if (typeof value === 'object') {
      Object.assign(element.style, value);
    } else {
      element.setAttribute('style', value);
    }
  },

  className(element, value) {
    // Treat "className" as an alias for "class" -- some React-aware IDEs try to be smart when
    // copy-pasting HTML into JSX and replace "class" with "className"
    element.setAttribute('class', value);
  },

  class(element, value) {
    // Allow setting "class" as an array
    if (Array.isArray(value)) {
      element.setAttribute('class', value.filter(Boolean).join(' '));
    } else {
      element.setAttribute('class', value);
    }
  },
};

export function jsx(tagName, args) {
  if (typeof tagName === 'function') {
    return tagName(args);
  }

  const { children = undefined, ...attrs } = args;

  // For fragments just insert the contents, but don't create an actual DOM element for it
  if (tagName === Fragment) {
    if (Array.isArray(children)) {
      // filter(Boolean) will filter out any falsy values like undefined
      return children?.filter(Boolean);
    } else {
      return children;
    }
  }

  const element = document.createElement(tagName);

  Object.entries(attrs).forEach(([key, value]) => {
    // Some attributes need special handling.
    const specialSetter = specialAttrSetters[key];
    if (specialSetter) {
      specialSetter(element, value);
    } else if (typeof value === 'string') {
      // For string values use the standard setAttribute method
      element.setAttribute(key, value);
    } else {
      // Otherwise set the property directly on the DOM element object
      // (this includes onclick and other handlers, with setAttribute they would get
      // converted to a string)
      element[key] = value;
    }
  });

  function appendChild(child) {
    // Skip any children that are undefined, false etc
    // (to allow conditional elements in children)
    if (!child) return;

    if (Array.isArray(child)) {
      // Recursively append each child.
      child.forEach(childItem => appendChild(childItem));
    } else if (typeof child === 'string' || typeof child === 'number') {
      // Text content: create a text node and append it.
      // (Don't just set innerText because that would remove any previous siblings)
      const textValue = typeof child === 'string' ? child : String(child);
      element.appendChild(document.createTextNode(textValue));
    } else if (child instanceof Node) {
      // Insert DOM nodes directly.
      element.appendChild(child);
    } else {
      // Anything else (probably a JQuery wrapper), try passing it through JQuery.
      $(child).appendTo(element);
    }
  }

  if (children) {
    appendChild(children);
  }

  return element;
}

/*
 * This is called when we have more than one child, but our implementation does not need
 * to make a difference.
 */
// noinspection JSUnusedGlobalSymbols
export function jsxs(tagName, args) {
  return jsx(tagName, args);
}

import { validateAccountForm } from '../../../js/portal/accounts';
import UserDropdown from './UserDropdown';

function replaceUserDropdown(id, email) {
  const textField = document.getElementById(id);
  const parentNode = textField.parentNode.parentNode;

  // Create a new instance of the UserDropdown incl. the target email
  const userDropdown = <UserDropdown label="none" selectedValues={[email]} disableUrlParams id={id} disabled />;
  parentNode.replaceChildren(userDropdown);
}

export default function EditAccountsForm(props) {
  const updateRegionCategory = props.regionCategoryFct;
  const updateMasterAccountId = props.masterAccountIdFct;
  const updateFpcStatus = props.fpcStatusFct;
  const updateAccountTypes = props.accountTypesFct;
  const updateAccountStages = props.accountStagesFct;
  const hideAccountEditForm = props.hideFormFct;
  const accountId = props.accountId;
  const accountData = props.accountData;

  const managerResponsible = accountData.it_responsible ? accountData.it_responsible.toLowerCase() : '';
  const primaryResponsible = accountData.primary_responsible ? accountData.primary_responsible.toLowerCase() : '';
  const secondaryResponsible = accountData.sec_responsible ? accountData.sec_responsible.toLowerCase() : '';

  return (
    <div class="row" id="edit-account-form" style="display:none;">
      <div class="col-lg-12">
        <form name="create-or-edit-form" id="create-or-edit-form" method="POST">
          <div class="card form-card">
            <div id="card-heading-text" class="card-header">
              Edit or Insert AWS Account Record in DynamoDB
            </div>

            <div class="card-body">
              {/* WARNING AND SUCCESS */}
              <div>
                <div id="form-error" class="alert alert-danger" style="display: none">
                  <ul />
                </div>
                <div id="form-success" class="alert alert-success" style="display: none">
                  <ul />
                </div>
              </div>

              <div class="row row-cols-1 row-cols-xl-2">
                {/* FIRST COLUMN OF THE FORM */}
                <div class="col">
                  <h4 style="margin-bottom: 15px; font-weight: bold">Account details</h4>

                  {/* REGION CATEGORY */}
                  <div class="form-group row" id="form_group_region_category">
                    <label for="region_category" class="col-form-label">
                      Region Category
                    </label>
                    <div class="col">
                      <select
                        class="form-select"
                        id="region_category"
                        name="region_category"
                        onchange={updateRegionCategory}
                        disabled
                        required>
                        <option value="Global" selected>
                          Global
                        </option>
                        <option value="China">China</option>
                      </select>
                    </div>
                  </div>

                  {/* MASTER ACCOUNT ID */}
                  <div class="form-group row" id="form_group_master_account">
                    <label for="master_account" class="col-form-label">
                      Master Account
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="master_account"
                        name="master_account"
                        placeholder="e.g. 123456789123"
                        onchange={updateMasterAccountId}
                        disabled/>
                    </div>
                  </div>

                  {/* AWS ACCOUNT ID */}
                  <div class="form-group row" id="form_group_account_id">
                    <label for="account_id" class="col-form-label">
                      AWS Account ID
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="account_id"
                        name="account_id"
                        placeholder="e.g. 123456789123"
                        disabled
                        required/>
                    </div>
                  </div>

                  {/* AWS ACCOUNT ROOT EMAIL */}
                  <div class="form-group row" id="form_group_account_root_email">
                    <label for="account_root_email" class="col-form-label">
                      Root Email
                    </label>
                    <div class="col">
                      <input
                        type="email"
                        class="form-control"
                        id="account_root_email"
                        name="account_root_email"
                        placeholder="e.g. awsaccount-####@bmw.de"
                        disabled
                        required/>
                    </div>
                  </div>

                  {/* CREATION DATE */}
                  <div class="form-group row" id="form_group_account_creation_date">
                    <label for="creation_date" class="col-form-label">
                      Creation Date
                    </label>
                    <div class="col">
                      <input
                        type="date"
                        class="form-control"
                        id="creation_date"
                        name="creation_date"
                        placeholder="2017-01-01"
                        disabled/>
                    </div>
                  </div>

                  {/* FPC STATUS */}
                  <div class="form-group row" id="form_group_fpc_status">
                    <label for="fpc_status" class="col-form-label">
                      FPC Status
                    </label>
                    <div class="col">
                      <select
                        class="form-select"
                        id="fpc_status"
                        name="fpc_status"
                        onchange={updateFpcStatus}
                        disabled
                        required/>
                    </div>
                  </div>

                  {/* ACCOUNT FRIENDLY NAME */}
                  <div class="form-group row" id="form_group_account_friendly_name">
                    <label for="account_friendly_name" class="col-form-label">
                      Friendly Name
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="account_friendly_name"
                        name="account_friendly_name"
                        placeholder="e.g. BMW Serverless PoC"
                        disabled
                        required/>
                    </div>
                  </div>

                  {/* ACCOUNT DESCRIPTION */}
                  <div class="form-group row" id="form_group_description">
                    <label for="description" class="col-form-label">
                      Description
                    </label>
                    <div class="col">
                      <textarea
                        rows="3"
                        class="form-control"
                        id="description"
                        name="description"
                        placeholder="some additional information"
                        disabled/>
                    </div>
                  </div>

                  {/* Account Area */}
                  <div class="form-group row" id="form_group_account_area">
                    <label for="account_area" class="col-form-label">
                      Account Area
                    </label>
                    <div class="col">
                      <select
                        class="form-select capitalize-options"
                        id="account_area"
                        name="account_area"
                        onchange={updateAccountTypes}
                        disabled/>
                    </div>
                  </div>

                  {/* ACCOUNT TYPE */}
                  <div class="form-group row" id="form_group_account_type">
                    <label for="account_type" class="col-form-label">
                      Account Type
                    </label>
                    <div class="col">
                      <select
                        class="form-select capitalize-options"
                        id="account_type"
                        name="account_type"
                        required
                        onchange={updateAccountStages}
                        disabled>
                        <option selected>default</option>
                      </select>
                    </div>
                  </div>

                  {/* ACCOUNT STAGE */}
                  <div class="form-group row" id="form_group_account_stage">
                    <label for="account_stage" class="col-form-label">
                      Account Stage
                    </label>
                    <div class="col-md-12 col-lg-9 col-xl-8">
                      <select
                        class="form-select capitalize-options"
                        id="account_stage"
                        name="account_stage"
                        required
                        disabled>
                        <option selected>dev</option>
                      </select>
                      <strong>
                        <br />
                        Only admins can manually change the account stage. To update the stage visit the Billing DB
                        portal.
                      </strong>
                    </div>
                  </div>

                  {/* IT RESPONSIBLE*/}
                  <div class="form-group row" id="form_group_it_responsible">
                    <div class="col-md-12 col-lg-3 col-xl-4">
                      <label for="it_responsible" class="col-form-label" style="width:100%;">
                        IT Manager
                      </label>
                    </div>
                    <div class="col-md-12 col-lg-9 col-xl-8">
                      <div class="col input-group">
                        <input
                          type="text"
                          class="form-control button-control"
                          id="it_responsible"
                          name="it_responsible"
                          placeholder="name.surname@company.com"
                          value={managerResponsible}
                          readOnly
                          required/>
                        <button
                          class="btn btn-light-grey input-group-btn portal-selectpicker-end"
                          type="button"
                          id="it_responsible_edit"
                          onclick={() => replaceUserDropdown('it_responsible', managerResponsible)}
                          disabled>
                          <i class="fas fa-pencil-alt" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* PRIMARY RESPONSIBLE */}
                  <div class="form-group row" id="form_group_primary_responsible">
                    <div class="col-md-12 col-lg-3 col-xl-4">
                      <label for="primary_responsible" class="col-form-label" style="width:100%;">
                        Primary Responsible
                      </label>
                    </div>
                    <div class="col-md-12 col-lg-9 col-xl-8">
                      <div class="col input-group">
                        <input
                          type="text"
                          class="form-control button-control"
                          id="primary_responsible"
                          name="primary_responsible"
                          placeholder="name.surname@company.com"
                          value={primaryResponsible}
                          readOnly
                          required/>
                        <button
                          class="btn btn-light-grey input-group-btn portal-selectpicker-end"
                          type="button"
                          id="primary_responsible_edit"
                          onclick={() => replaceUserDropdown('primary_responsible', primaryResponsible)}
                          disabled>
                          <i class="fas fa-pencil-alt" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* SECONDARY RESPONSIBLE */}
                  <div class="form-group row" id="form_group_sec_responsible">
                    <div class="col-md-12 col-lg-3 col-xl-4">
                      <label for="sec_responsible" class="col-form-label" style="width:100%;">
                        Secondary Responsible
                      </label>
                    </div>
                    <div class="col-md-12 col-lg-9 col-xl-8">
                      <div class="col input-group">
                        <input
                          type="text"
                          class="form-control button-control"
                          id="sec_responsible"
                          name="sec_responsible"
                          placeholder="name.surname@company.com"
                          value={secondaryResponsible}
                          readOnly
                          required/>
                        <button
                          class="btn btn-light-grey input-group-btn portal-selectpicker-end"
                          type="button"
                          id="sec_responsible_edit"
                          onclick={() => replaceUserDropdown('sec_responsible', secondaryResponsible)}
                          disabled>
                          <i class="fas fa-pencil-alt" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <hr class="d-block d-xl-none my-5" />
                  <h4 style="margin-bottom: 15px; font-weight: bold">Cost data details</h4>

                  {/* APP-ID */}
                  <div class="form-group row" id="form_group_app_id">
                    <label for="app_id" class="col-form-label">
                      APP-ID
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="app_id"
                        name="app_id"
                        placeholder="e.g. APP-123456"
                        onchange={updateAccountStages}
                        disabled/>
                    </div>
                  </div>

                  {/* APPD-ID */}
                  <div class="form-group row" id="form_group_appd_id">
                    <label for="appd_id" class="col-form-label">
                      APPD-ID
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="appd_id"
                        name="appd_id"
                        placeholder="e.g. APPD-123456"
                        disabled/>
                    </div>
                  </div>

                  {/* COSTCENTER */}
                  <div class="form-group row" id="form_group_cost_center">
                    <label for="cost_center" class="col-form-label">
                      Cost Center
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="cost_center"
                        name="cost_center"
                        placeholder="e.g. TBD"
                        disabled/>
                    </div>
                  </div>

                  {/* PSO/PSP */}
                  <div class="form-group row" id="form_group_pso">
                    <label for="psp" class="col-form-label">
                      PSO/PSP
                    </label>
                    <div class="col">
                      <input type="text" class="form-control" id="psp" name="psp" placeholder="e.g. ABCDEF" disabled />
                    </div>
                  </div>

                  <div class="form-group row" id="form_group_billing_db">
                    <label for="billing db" class="col-form-label"></label>
                    <div class="col">
                      <div id="infobox" class="card infobox">
                        <div class="card-body">
                          Visit the billing DB to update the cost data.
                          <br />
                          Data can be updated only from <b>IT manager</b>, <b>primary responsible</b> and{' '}
                          <b>secondary responsible</b>.
                        </div>
                      </div>
                      <div class="btn-group detail-actions-btn-group">
                        <a
                          class="btn-sm space"
                          target="_blank"
                          style="text-decoration: none"
                          href={'https://cloudbilling.bmwgroup.net/user/billingdata?roomid=' + accountId}>
                          <i
                            class="fas fa-external-link-alt"
                            style="margin-right: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"/>
                          Update Cost Data{' '}
                        </a>
                        <a
                          class="btn-sm space"
                          target="_blank"
                          style="text-decoration: none"
                          href={'https://cloudbilling.bmwgroup.net/user/appdata?roomid=' + accountId}>
                          <i
                            class="fas fa-external-link-alt"
                            style="margin-right: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"/>
                          Update Application Data{' '}
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* Cost Data Button */}
                  <div class="form-group row" id="form_group_cost_data_btn" />
                </div>
              </div>
            </div>

            <div class="card-footer" align="right">
              <input
                type="button"
                class="btn btn-custom"
                name="hide-edit-form"
                id="hide-edit-form"
                value="Cancel"
                onclick={hideAccountEditForm}/>
              <button
                id="submitButton"
                type="button"
                class="btn btn-success update-account"
                onclick={validateAccountForm}>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Update Account
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default function ChartHorizontalBar(props) {
  const { title, value, valueText, color } = props;

  return (
    <div class="mt-2">
      <div class="tile-text-tertiary">
        <b>{title}</b>
      </div>
      <div class="tile-chart-bar">
        <div class="tile-chart-bar-background" style={{ backgroundColor: '#ededed' }}>
          <div
            class="tile-chart-bar-body position-absolute"
            style={{ width: `${value}%`, backgroundColor: color }}></div>
        </div>
        <div class="tile-chart-bar-value">{valueText ? valueText : `${value}%`}</div>
      </div>
    </div>
  );
}

import { BannerOperations, BannersApi, BannersTable, CreateBannerForm } from './js/portal/banner_admin';
import { Datatable } from './Datatable';

const tableId = 'table-banners';

export default function AdminBannersPage() {
  const page = (
    <>
      <div id="createBannerFormContainer" class="row" style="display: none">
        <div class="col-lg-12">
          <form id="createBannerForm" name="create-banner-form" method="POST">
            <div class="card form-card">
              <div class="card-header">Create Banner</div>
              <div class="card-body">
                <div class="row row-cols-1 row-cols-lg-2">
                  {/* FORM FIELDS */}
                  <div class="col">
                    <h4>Banner Content</h4>

                    {/* Banner Title */}
                    <div class="form-group row">
                      <label for="bannerTitle" class="col-form-label">
                        Title
                      </label>
                      <div class="col">
                        <input
                          type="text"
                          id="bannerTitle"
                          name="bannerTitle"
                          class="form-control"
                          required
                          placeholder="Banner Title"/>
                      </div>
                    </div>

                    {/* Banner Severity */}
                    <div class="form-group row">
                      <label for="bannerSeverity" class="col-form-label">
                        Severity
                      </label>
                      <div class="col">
                        <select id="bannerSeverity" name="bannerSeverity" class="form-select" required>
                          <option value="info" selected>
                            Info
                          </option>
                          <option value="warning">Warning</option>
                          <option value="alert">Alert</option>
                        </select>
                      </div>
                    </div>

                    {/* Banner Text */}
                    <div class="form-group row">
                      <label for="bannerText" class="col-form-label">
                        Text
                      </label>
                      <div class="col">
                        <textarea
                          id="bannerText"
                          name="bannerText"
                          class="form-control"
                          rows="6"
                          style="font-family: monospace"/>

                        <div class="help-block mt-3">
                          The text supports a limited markdown syntax for formatting:
                          <ul>
                            <li>Paragraphs: Two consecutive line breaks</li>
                            <li>
                              Text emphasis: <code>**bold**</code>, <code>*italic*</code>
                            </li>
                            <li>
                              Links: <code>[https://example.com](Link text)</code>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <h4 style="margin-top: 2em">Active Timeframe</h4>

                    <p class="help-block">
                      Users of the portal will see the banner only during the specified timeframe.
                    </p>

                    {/* Start time (active from) */}

                    <div class="checkbox">
                      <label style="margin-left: 0; font-size: 14px">
                        <input type="checkbox" id="checkboxActiveImmediately" name="activeImmediately" checked />
                        Active immediately
                      </label>
                    </div>

                    <div class="form-group row">
                      <label for="bannerActiveFromDate" class="col-form-label">
                        Active from (UTC)
                      </label>
                      <div class="col">
                        <div id="inputGroupActiveFrom" class="input-group datetime-input-group has-validation">
                          <input
                            type="date"
                            id="bannerActiveFromDate"
                            name="bannerActiveFromDate"
                            class="form-control"
                            disabled/>
                          <label for="bannerActiveFromTime" class="input-group-text">
                            Time
                          </label>
                          <input
                            type="time"
                            id="bannerActiveFromTime"
                            name="bannerActiveFromTime"
                            class="form-control"
                            value="00:00"
                            disabled/>
                          <input type="hidden" id="bannerActiveFrom" name="bannerActiveFrom" />
                        </div>
                      </div>
                    </div>

                    {/* End time (active until) */}
                    <div class="form-group row">
                      <label for="bannerActiveUntilDate" class="col-form-label">
                        Active until (UTC)
                      </label>
                      <div class="col">
                        <div id="inputGroupActiveUntil" class="input-group datetime-input-group has-validation">
                          <input
                            type="date"
                            id="bannerActiveUntilDate"
                            name="bannerActiveUntilDate"
                            class="form-control"/>
                          <label for="bannerActiveUntilTime" class="input-group-text">
                            Time
                          </label>
                          <input
                            type="time"
                            id="bannerActiveUntilTime"
                            name="bannerActiveUntilTime"
                            class="form-control"
                            value="23:59"
                            required/>
                          <input type="hidden" id="bannerActiveUntil" name="bannerActiveUntil" />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* BANNER PREVIEW */}
                  <div class="col">
                    <h4>Preview</h4>
                    <p id="bannerPreviewMissingTitleText">Please enter a title to show a preview for the banner.</p>
                    <div id="createBannerPreview" style="display: none" />
                  </div>
                </div>
              </div>
              <div class="card-footer" align="right">
                <input type="button" id="btnCancelCreateBanner" class="btn btn-custom" value="Cancel" />
                <input type="submit" class="btn btn-primary" value="Create Banner" />
              </div>
            </div>
          </form>
        </div>
      </div>

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    const bannersApi = new BannersApi();
    const bannerOperations = new BannerOperations(bannersApi);
    const createBannerForm = new CreateBannerForm(bannerOperations);
    const bannersTable = new BannersTable(bannerOperations, createBannerForm.showForm);

    bannerOperations.onBannersLoaded(bannersTable.updateBanners);
    bannerOperations.reloadBanners();
  }

  return [page, onPageReady];
}

import AccountDropdown from './jsx/components/forms/AccountDropdown';
import {
  accountGetDetails,
  closeAccount,
  checkAccountResources,
  accountDisplayRemainingResources,
} from './js/portal/accounts';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import { hasAccess } from './js/portal/main';
import { removeSearchParamsUrl } from './js/portal/search_params';

export default function AccountClosePage() {
  let step = 'account-selection';

  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="close-account-form" id="close-account-form" method="POST">
          <div class="card form-card">
            <div class="card-header">Close AWS Account</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  <p>
                    With this form you can start the deletion process for an AWS account. After submitting the form, the
                    order will be approved and processed. This can not be undone. Once the AWS account is suspended, BMW
                    loses access to all data and resources hosted in this account.
                    <br />
                    Find more information in our{' '}
                    <a
                      class="nobr"
                      href="https://developer.bmw.com/docs/public-cloud-platform-aws/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <i class="fas fa-book" /> Developer Portal
                    </a>
                    &nbsp;
                    <small>(internal Documentation)</small>
                  </p>

                  <p>
                    <strong>Attention:</strong> Please delete all your resources before you create the "Close AWS
                    Account" order. We won't accept your request until you've deleted your resources like EC2 instances,
                    databases or storage. You have to pay for the account until we've closed your account.
                  </p>
                </div>
              </div>

              <div class="tab-content">
                <div id="account-selection" class="tab">
                  <AccountDropdown
                    id="aws-account-id"
                    short
                    lookup
                    onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                      document.getElementById('submitButton').disabled = !hasAccess(accountId, ['manage_orders']);
                    }}/>
                </div>
                <div id="account-confirmation" class="tab">
                  <div class="form-group row">
                    <label class="col-form-label" />
                    <div class="col" id="account-details" />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row px-2">
                <div class="col">
                  <input type="button" id="resetFormBtn" name="resetFormBtn" class="btn btn-secondary" value="Reset" />
                </div>
                <div class="col" align="right">
                  <button id="submitButton" type="submit" class="btn btn-success">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    <span id="submitButtonLabel">Continue</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    const accountSelectionTab = $('#account-selection');
    const accountConfirmationTab = $('#account-confirmation');
    accountConfirmationTab.hide();
    accountSelectionTab.show();
    const submitButton = $('#submitButton');
    const submitButtonLabel = $('#submitButtonLabel');
    const resetButton = $('#resetFormBtn');

    const reset = () => {
      submitButton.attr('disabled', false);
      submitButtonLabel.text('Continue');
      submitButton.removeClass('btn-danger').addClass('btn-success');
      accountConfirmationTab.hide();
      accountSelectionTab.show();
      step = 'account-selection';
      $('#aws-account-id').val('').selectpicker('refresh');
      removeSearchParamsUrl();
    };

    resetButton.on('click', reset);

    const proceed = () => {
      removeSpinners();
      submitButtonLabel.text('Close Account');
      submitButton.removeClass('btn-success').addClass('btn-danger');
      accountSelectionTab.hide();
      accountConfirmationTab.show();
      step = 'account-confirmation';
    };

    $('#close-account-form')
      .on('submit', function (e) {
        e.preventDefault();
        const account_id = $('#aws-account-id')[0].value.split(';')[0];

        if (!account_id) {
          $('#account-selector').addClass('has-error');
        } else {
          $('#account-selector').removeClass('has-error');
        }
        $('#form-error').hide();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        submitHandler: function () {
          switch (step) {
            case 'account-selection':
              addSpinner();
              addButtonSpinner();
              checkAccountResources().then(function (response) {
                const isAccountDeletable = response.length === 0 ? true : false;
                const account_id = document.getElementById('aws-account-id').value;
                if (isAccountDeletable && hasAccess(account_id, ['manage_orders'])) {
                  accountGetDetails('close-account-form').then(proceed);
                } else {
                  submitButton.attr('disabled', true);
                  accountDisplayRemainingResources(response);
                  proceed();
                }
              });
              break;

            case 'account-confirmation':
              addSpinner();
              addButtonSpinner();
              closeAccount(reset);
              break;
          }
        },
      });
  }

  return [page, onPageReady];
}

import { addOrderData } from './js/portal/orders';
import { Datatable } from './Datatable';
import { getSearchParamsUrl } from './js/portal/search_params';
import { addSpinner } from './js/portal/sidebar';

const tableId = 'table-orders';

export default function OrdersListPage() {
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div id="response-error" class="alert alert-danger" style="display: none">
            <ul />
          </div>

          <div id="response-warning" class="alert alert-warning" style="display: none">
            <ul />
          </div>

          <div id="response-success" class="alert alert-success" style="display: none">
            <ul />
          </div>
        </div>
      </div>

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    $(() => addSpinner());
    addOrderData(tableId, getSearchParamsUrl('approved'), getSearchParamsUrl('executed'));
  }

  return [page, onPageReady];
}

import { removeDisabledOptions } from '../../../js/portal/main';
import { apiAxios, showError } from '../../../js/portal/api';

/**
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} idPrefix - the id prefix of the selectpicker
 * @param {string} id - the id of the selectpicker
 * @param {function} onchange - a function that will be triggered once the selectpicker value changes
 * @param {string} selectedValue - a training type that should be selected if available
 * @param {boolean} disabled - defines if the selectpicker will be disabled
 *
 * @returns JSX.Element
 */
export default function TrainingTypeDropdown({ label, idPrefix, id, onchange, selectedValue, disabled }) {
  const labelText = label ? label : 'Type';
  const selectId = id ? id : 'trainingType';
  const selectIdPrefix = idPrefix ? idPrefix : '';
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = {};

  // selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/
  const select = (
    <select
      class="form-control form-select selectpicker"
      data-live-search="true"
      data-show-subtext="true"
      id={selectIdPrefix + selectId}
      name={selectIdPrefix + selectId}
      data-size="10"
      data-none-selected-text="- Please select the target training type -"
      data-dropdown-align-right="auto"
      disabled={disabled ? true : false}
      required>
      <option class="defaultDisabled" value="" disabled selected>
        - Please select the target training type -
      </option>
    </select>
  );

  // Refresh button to reload the user data
  const refreshIcon = <i class="fas fa-sync" />;
  const refreshButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      id="refresh-training-types"
      title="Reload Training Types"
      disabled={disabled ? true : false}>
      {refreshIcon}
    </button>
  );

  // Represents the whole row including the label, the selectpicker and the refresh button
  const typeDropdown = (
    <div class="form-group row portal-dropdown">
      <label for="trainingType" class="col-form-label">
        {labelText}
      </label>
      <div class="col input-group">
        {select}
        {refreshButton}
      </div>
    </div>
  );

  // Refresh the selectpicker and add an on change event
  $(select).selectpicker('refresh');
  $(select).on('changed.bs.select', () => {
    if (onchange) {
      const selectedOption = $(select).find(':selected');
      const selectedName = selectedOption.attr('training-name');
      const selectedDescription = selectedOption.attr('training-description');
      const selectedPreparationDays = selectedOption.attr('training-preparation-days');
      const selectedCleanupDays = selectedOption.attr('training-cleanup-days');
      onchange(id, selectedOption, selectedName, selectedDescription, selectedPreparationDays, selectedCleanupDays);
    }
  });

  // Reload the training types from the REST API (with cache-control headers)
  $(refreshButton).on('click', () => {
    loadTrainingTypes(select, refreshIcon, selectedValue, headers, params);
  });

  // Load the training types from the REST API (without cache-control-headers)
  loadTrainingTypes(select, refreshIcon, selectedValue);
  return typeDropdown;
}

/**
 *
 * @param {JSX.IntrinsicElements.select} select
 * @param {JSX.Element} refreshIcon
 * @param {string} selectedValue
 * @param {object} headers
 * @param {object} params
 */
function loadTrainingTypes(select, refreshIcon, selectedValue, headers, params) {
  $(refreshIcon).addClass('fa-spin');
  $(select).attr('disabled', true).selectpicker('refresh');

  // GET request to collect the training settings
  apiAxios
    .get('/settings/training', { headers, params })
    .then(response => {
      // Delete all active items from the selectpicker
      removeDisabledOptions(select);

      if (response.data.training.types) {
        // Create the new training config options for the selectpicker
        const options = Object.entries(response.data.training.types).map(([key, value]) => {
          const mainText = value.name;
          const subText = value.description;
          const opt = document.createElement('option');
          opt.value = key;
          opt.innerText = mainText;
          opt.setAttribute('data-subtext', subText);
          opt.setAttribute('training-name', value.name);
          opt.setAttribute('training-description', value.description);
          opt.setAttribute('training-preparation-days', value.preparation_days);
          opt.setAttribute('training-cleanup-days', value.cleanup_days);
          opt.selected = key === selectedValue ? true : false;
          return opt;
        });

        // Sort the selectpicker options in asc order by the option text
        options.sort(function (a, b) {
          return a.text < b.text ? -1 : 1;
        });

        // Add all options to the selectpicker
        options.forEach(option => {
          select.appendChild(option);
          if (option.selected) {
            $(select).trigger('changed.bs.select');
          }
        });
      }
    })
    .catch(error => {
      showError(error);
    })
    .finally(() => {
      // Refresh the selectpicker to show the new options
      $(refreshIcon).removeClass('fa-spin');
      $(select).attr('disabled', false);
      $(select).selectpicker('refresh');
    });
}

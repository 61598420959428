import { encodeEntities } from './main';
import { removeSpinners } from './sidebar';

export function initDataTable(tableId, domConfig, buttons, columns, rowCallback, additionalAttributes) {
  let dt_config = {
    autoWidth: true,
    retrieve: true,
    bScrollCollapse: true,
    scrollX: true,
    processing: true,
    language: {
      processing:
        '<span class="spinner-border-container"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></span>',
    },
    lengthMenu: [
      [10, 25, 50, -1],
      [10, 25, 50, 'All'],
    ],
    dom: domConfig,
    stateSave: true,
    buttons: buttons,
    columns: columns,
    rowCallback: rowCallback,
    columnDefs: [
      {
        targets: '_all',
        render: {
          display: encodeEntities,
        },
      },
    ],
    initComplete: function () {
      $(window).trigger('resize');
    },
    drawCallback: function () {
      $(window).trigger('resize');
      removeSpinners();
    },
  };

  if (rowCallback) {
    dt_config.rowCallback = rowCallback;
  }

  if (additionalAttributes) {
    dt_config = Object.assign(dt_config, additionalAttributes);
  }

  return $('#' + tableId).DataTable(dt_config);
}

// content

/**
 * Creates a new datatable incl. the wrapper divs to have the same layout on all pages
 *
 * @param {string} tableId - the target id of the datatable
 * @returns HTML object
 */
export function Datatable({ tableId }) {
  return (
    <div class="row">
      <div class="col-lg-12">
        <div id="dataTable_wrapper">
          <div class="table-responsive">
            <table id={tableId} class="table table-hover dataTable row-border nowrap" style="width: 100%" role="grid" />
          </div>
        </div>
      </div>
    </div>
  );
}

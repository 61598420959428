import { initTrainingsTable } from './js/portal/trainingsList';
import { Datatable } from './Datatable';

const tableId = 'table-trainings';

export default function TrainingsListPage() {
  const page = (
    <>
      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initTrainingsTable(tableId);
  }

  return [page, onPageReady];
}

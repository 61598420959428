import { OverviewSwitch, toggleOverview } from './js/portal/main';
import { getFreeDxGwNetbondCIDR, initDXGWTable } from './js/portal/directconnect';
import { Datatable } from './Datatable';

const tableId = 'table-dx-gws';

export default function NetworksListDxGwPage() {
  const page = (
    <>
      {/* STATISTICS */}
      <div id="dx-gw-numbers" class="collapse show statsOverview" />

      {/* TABLE AND CONFIGURE TABLE BUTTON */}

      <div class="row row-cols-auto justify-content-end gy-3" style="margin-bottom: 20px">
        <div class="btn-group">
          <button
            id="dx-gw-get-free-netbond-cidr"
            class="btn-sm"
            onclick={() => {
              getFreeDxGwNetbondCIDR();
              return false;
            }}>
            <i class="fas fa-search" /> Find Free Netbond CIDR
          </button>
        </div>

        <OverviewSwitch targets="#dx-gw-numbers" localStorageItem="hidden_dxgateway_overview" />
      </div>

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    toggleOverview('hidden_dxgateway_overview');
    initDXGWTable(tableId);
  }

  return [page, onPageReady];
}

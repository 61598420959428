export default function ChartLegend(props) {
  const { labels, colors, minHeight, largeText } = props;

  return (
    <div class="tile-chart-legend d-flex flex-column text-center" style={{ minHeight }}>
      <div class="flex-grow-1"></div>
      <div>
        {labels.map((label, index) => (
          <div
            class={`tile-chart-legend-entry${largeText ? ' tile-chart-legend-entry--large' : ''}`}
            style={{ color: colors[index % colors.length] }}>
            <span>{label}</span>
          </div>
        ))}
      </div>
      <div class="flex-grow-1"></div>
    </div>
  );
}

import axios from 'axios';
import { CONF } from './environment';

const ACTIVE_BANNERS_URL = CONF.apiEndpoint + '/banner/active';
const DISMISSED_BANNERS_KEY = 'customer_portal_aws_bannersDismissed';

/**
 * Loads the active banners from the backend.
 * @returns {Promise<object[]>} the array of banners
 */
async function loadActiveBanners() {
  const response = await axios.get(ACTIVE_BANNERS_URL);
  return response.data['banners'];
}

/**
 * Filters the banners so that only active banners are included in the result.
 * Banners are active during the timeframe indicated by `start_time` and `end_time`.
 *
 * @param banners {object[]} an array of banners as returned from the API
 * @returns {object[]} the banners from the input which are currently active
 */
function filterActiveBanners(banners) {
  const now = new Date();
  return banners.filter(banner => new Date(banner['start_time']) <= now && new Date(banner['end_time']) >= now);
}

/**
 * Retrieves the dismissed banner IDs from local storage.
 * @returns {string[]} array of dismissed banner IDs
 */
function retrieveDismissedIds() {
  return (localStorage.getItem(DISMISSED_BANNERS_KEY) || '').split(/\s*,\s*/);
}

/**
 * Stores the dismissed banner IDs in local storage.
 * @param newDismissedIds {string[]} the new dismissed banner IDs to store
 */
function storeDismissedIds(newDismissedIds) {
  if (!newDismissedIds || !newDismissedIds.length) {
    localStorage.removeItem(DISMISSED_BANNERS_KEY);
  } else {
    localStorage.setItem(DISMISSED_BANNERS_KEY, newDismissedIds.join(','));
  }
}

/**
 * Clean up the dismissed IDs in local storage. Any stored dismissed banner ID that is not in the response
 * will be removed from local storage, because the banner will not be returned anymore.
 * @param dismissedIds {string[]} the currently stored list of dismissed banner IDs
 * @param banners {object[]} the fresh banners response from the API
 */
function cleanupDismissedIds(dismissedIds, banners) {
  const newDismissedIds = dismissedIds.filter(dismissedId => banners.some(banner => banner.id === dismissedId));
  if (newDismissedIds.length !== dismissedIds.length) {
    storeDismissedIds(newDismissedIds);
  }
}

/**
 * Filter the given banners so that the result does not contain banners that have been dismissed.
 * @param banners {object[]} an array of banners, as returned from the API
 * @returns {object[]} the items from the input that represent non-dismissed banners
 */
function filterDismissedBanners(banners) {
  const dismissedIds = retrieveDismissedIds();
  cleanupDismissedIds(dismissedIds, banners);
  return banners.filter(banner => !dismissedIds.includes(banner.id));
}

/**
 * Dismiss a single banner.
 * This updates the list of dismissed banner IDs in local storage.
 * @param bannerId {string} the banner ID to dismiss
 */
function dismissBanner(bannerId) {
  storeDismissedIds([...retrieveDismissedIds(), bannerId]);
}

const BANNER_SEVERITY_CLASS_MAP = {
  info: 'alert-info',
  warning: 'alert-warning',
  alert: 'alert-danger',
};

/**
 * Render a single banner from the API model as an HTML DOM element.
 * @param banner {object} a single banner object, as returned from the API
 * @param options {object?} config options
 * @param options?.noDismiss if true, don't display a dismiss/close button.
 * @returns {JQuery<HTMLElement>} a JQuery object that contains the HTML DOM element for the banner
 */
export function renderBanner(banner, options) {
  const bannerId = banner.id;
  if (!options) options = {};
  const canDismiss = !options.noDismiss;
  const bannerAlert = $('<div class="alert fade show" role="alert">')
    .addClass(BANNER_SEVERITY_CLASS_MAP[banner.severity.toLowerCase()])
    .toggleClass('alert-dismissible', canDismiss)
    .append(
      canDismiss && $('<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Dismiss">'),
      $('<h4>').text(banner.title),
      ...bannerTextToParagraphs(banner.text)
    );

  if (!options.noDismiss) {
    bannerAlert.on('closed.bs.alert', () => {
      console.debug('Banner dismissed: ', bannerId);
      dismissBanner(bannerId);
    });
  }
  return bannerAlert;
}

/**
 * Renders the banners from the API model as HTML DOM elements.
 * @param banners {object[]} an array of banners, as returned from the API
 * @returns {jQuery} a JQuery object that contains the HTML DOM elements for each banner
 */
function renderBanners(banners) {
  return $((banners || []).map(renderBanner));
}

/**
 * Parse the banner text into a JQuery object.
 * A single `<p>` element will be rendered for each paragraph (separated by two consecutive newlines)
 * in the input.
 *
 * @param bannerText {string} the banner text
 * @return {jQuery} a JQuery object that contains one `<p>` element for each paragraph in the banner text.
 */
function bannerTextToParagraphs(bannerText) {
  return $(
    (bannerText || '')
      .split(/\n{2,}/)
      .map(rawParagraphText => $('<p>').html(parseSimpleMarkdownToHtml(rawParagraphText)))
  );
}

const EMPHASIS_REGEX = /\*(.*?)\*/g;
const STRONG_EMPHASIS_REGEX = /\*\*(.*?)\*\*/g;
const LINK_REGEX = /\[(.*?)]\((.*?)\)/g;
const LINE_BREAK_REGEX = /\n$/gm;
const ABSOLUTE_URL_REGEX = /:\/\//;

/**
 * Parse a string that contains simple Markdown expressions into equivalent HTML.
 * @param rawText the input text, possibly containing markdown expressions
 * @returns {string} the equivalent HTML
 */
function parseSimpleMarkdownToHtml(rawText) {
  if (!rawText) return '';
  return rawText
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(STRONG_EMPHASIS_REGEX, '<strong>$1</strong>')
    .replace(EMPHASIS_REGEX, '<em>$1</em>')
    .replace(LINK_REGEX, (_substring, linkText, linkHref) => {
      const link = $('<a>').attr('href', linkHref.replaceAll('"', '%22')).text(linkText);
      if (ABSOLUTE_URL_REGEX.test(linkHref)) {
        link.attr({ target: '_blank', rel: 'noopener noreferrer' }).append(' <span class="fas fa-external-link-alt">');
      }
      return link.prop('outerHTML');
    })
    .replace(LINE_BREAK_REGEX, '<br />');
}

async function loadAndDisplayBanners() {
  let banners;
  try {
    banners = filterActiveBanners(filterDismissedBanners(await loadActiveBanners()));
  } catch (error) {
    console.error('Error loading active banners', error);
    return [];
  }

  const renderedBanners = renderBanners(banners);
  $('#banners-container').empty().append(renderedBanners.get());
}

$(loadAndDisplayBanners);
$(window).on('hashchange', loadAndDisplayBanners);

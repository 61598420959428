import { baseApiAxios } from './js/portal/api';
import { addButtonSpinner, addSpinner } from './js/portal/sidebar';
import { callCheckHostedZone, loadHostedZones, resultOnboardHostedZoneFunction } from './js/portal/zones';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { hasAccess } from './js/portal/main';

export default function HostedZonesOnboardPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="onboard-hosted-zone-form" id="onboard-hosted-zone-form" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Onboard Route 53 Hosted Zone</div>

            {/* CARD BODY */}
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  With this form you can request the onboarding of your previously created and configured Route 53
                  Private Hosted Zone, for example if you create it outside the portal using IaC. Onboarding a hosted zone
                  allows all other Private VPCs to lookup hostnames from the zone.
                  <br />
                  Please note that only whitelisted subdomains can be onboarded (aws.cloud.bmw, aws.confluent.cloud).
                </div>
              </div>

              <AccountDropdown
                id="aws-account-id"
                onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                  document.getElementById('submitButton').disabled = !hasAccess(accountId, ['manage_dns']);
                  loadHostedZones('hosted-zone-id', 'aws-account-id');
                }}
                short
                lookup/>

              <div class="form-group row">
                <label for="hosted-zone-id" class="col-form-label">
                  Hosted Zone
                  <span class="loading-form-inline">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </span>
                </label>
                <div class="col">
                  <select
                    required
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    data-size="10"
                    id="hosted-zone-id"
                    name="hosted-zone-id"
                    onchange={function () {
                      callCheckHostedZone(this.value.split(',')[1]);
                    }}>
                    <option value="" disabled selected>
                      - Select a Hosted Zone -
                    </option>
                  </select>
                </div>
              </div>
            </div>

            {/* CARD FOOTER */}
            <div class="card-footer">
              <input type="hidden" name="action" id="action" value="createJob" />
              <input type="hidden" name="jobType" id="jobType" value="onboard-hostedzone" />
              <input
                type="hidden"
                name="jobTypeDescription"
                id="jobTypeDescription"
                value="Onboard Route53 Hosted Zone"/>
              <div class="row px-2">
                <div class="col">
                  <input
                    type="button"
                    name="resetFormBtn"
                    class="btn btn-secondary"
                    onclick={() => location.reload()}
                    value="Reset"/>
                </div>
                <div class="col" align="right">
                  <button
                    id="submitButton"
                    type="submit"
                    class="btn btn-success onboard-hosted-zone-button"
                    value="Onboard existing Hosted Zone">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Onboard existing Hosted Zone
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    $('.selectpicker').selectpicker('refresh');
    $('#onboard-hosted-zone-form').on('submit', function (e) {
      e.preventDefault();
      $('#form-error').hide();
      $('#form-success').hide();
      addSpinner();
      addButtonSpinner();
      const zone_id = $('#hosted-zone-id')[0].value.split(',')[0].split('/')[2];
      const fqdn = $('#hosted-zone-id')[0].value.split(',')[1];

      const payload = {
        action: $('#jobType')[0].value,
        description: $('#jobTypeDescription')[0].value,
        account_id: $('#aws-account-id')[0].value.split(';')[0],
        hosted_zone_id: zone_id,
        zone_name: fqdn,
      };

      baseApiAxios
        .createOrder(payload)
        .then(resultOnboardHostedZoneFunction)
        .catch(err => {
          $('#form-error').html('<strong>Warning: </strong>' + err.message + ' ');
          $('#form-error').show();
        });
    });
  }

  return [page, onPageReady];
}

import { baseApiAxios } from './js/portal/api';
import TileDonutChart from './jsx/components/tiles/TileDonutChart';
import TileText from './jsx/components/tiles/TileText';
import ChartHorizontalStacked from './jsx/components/tiles/ChartHorizontalStacked';
import ChartLegend from './jsx/components/tiles/ChartLegend';
import {
  defaultColors,
  loadDonutChart,
  loadStackedAbsoluteValuesChart,
  loadTextTile,
  loadValueInRangeTile,
  setTimeframeSelectOptions,
  tileTitles,
} from './js/portal/statistics';
import TileValueInRange from './jsx/components/tiles/TileValueInRange';
import { addSpinner, removeSpinners } from './js/portal/sidebar';
import TileTable from './jsx/components/tiles/TileTable';
import { initDataTable } from './js/portal/datatable';
import { addCopyButton } from './js/portal/main';

export default async function StatisticsOrderPage() {
  let timeframeSelect;
  let dropdownSelection, radioSelection;
  let orderActionsData = {},
    orderData = {};

  const page = (
    <div id="dashboard_overview_account">
      <div class="row mb-3">
        <div class="col">
          <div class="dashboard-title d-inline-block">
            <h3 class="overview" style="line-height:32px;margin-bottom:0;">
              Order Statistics
            </h3>
          </div>
          <div id="network-usage-dashboard-status" class="d-inline-block"></div>
        </div>
        <div class="col-auto text-right">
          <span class="d-inline-block loading-form-inline">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </span>
          <span class="d-inline-block portal-dropdown mx-3">
            <select class="form-control form-select" id="chart-timeframe-select" onchange={dropdownChangeListener}>
              <option disabled selected>
                No Data available
              </option>
            </select>
          </span>
          <span class="d-inline-block">
            <ul class="nav nav-pills chart-timeframe-switch">
              <li class="nav-item">
                <input
                  type="radio"
                  id="category-monthly"
                  name="category"
                  value="monthly"
                  onchange={radioChangeListener}
                  checked/>
                <label class="nav-link" for="category-monthly">
                  Monthly
                </label>
              </li>
              <li class="nav-item">
                <input type="radio" id="category-daily" name="category" value="daily" onchange={radioChangeListener} />
                <label class="nav-link" for="category-daily">
                  Daily
                </label>
              </li>
            </ul>
          </span>
        </div>
      </div>
      <div class="row">
        <TileValueInRange
          id="order-success-rate"
          title="Order Success Rate"
          range="gray"
          rangeLabel="no data"></TileValueInRange>
        <TileText id="successful-orders" title="Successful Orders"></TileText>
        <TileText id="failed-orders" title="Failed Orders"></TileText>
        <TileText id="top-0-order" title="Top Order" highlightColor="var(--bs-secondary)"></TileText>
        <TileText id="top-1-order" title="Top Order" highlightColor="var(--bs-secondary)"></TileText>
        <TileText id="top-2-order" title="Top Order" highlightColor="var(--bs-secondary)"></TileText>
        <TileText id="failed-0-order" title="Failed Order" highlightColor="var(--bs-danger)"></TileText>
        <TileText id="failed-1-order" title="Failed Order" highlightColor="var(--bs-danger)"></TileText>
        <TileText id="failed-2-order" title="Failed Order" highlightColor="var(--bs-danger)"></TileText>
        <TileDonutChart
          id="orders-by-type"
          title="Orders by Type"
          labels={[]}
          colors={defaultColors}
          partial={26 / 30}></TileDonutChart>
        <TileText id="orders-by-type-and-state" title="Number of Orders" headline="by Type and State">
          <ChartHorizontalStacked
            title=""
            hideLegend={true}
            values={[]}
            colors={defaultColors}></ChartHorizontalStacked>
          <ChartLegend labels={[]} colors={defaultColors}></ChartLegend>
        </TileText>
        <TileDonutChart
          id="orders-by-state"
          title="Orders by State"
          labels={[]}
          colors={defaultColors}
          partial={26 / 30}></TileDonutChart>
        <TileTable id="order-data"></TileTable>
      </div>
    </div>
  );

  async function onPageReady() {
    timeframeSelect = document.getElementById('chart-timeframe-select');
    radioChangeListener();
  }

  function dropdownChangeListener(evt) {
    const value = evt.target.value;
    if (value) {
      dropdownSelection = value;
      loadDashboardData(radioSelection, dropdownSelection);
    }
  }

  async function radioChangeListener() {
    const value = document.querySelector(`.chart-timeframe-switch input[name="category"]:checked`)?.value;
    if (value) {
      radioSelection = value;
      dropdownSelection = null;
      const [initialOptions, initiallySelected] = await loadInitialData();
      setTimeframeSelectOptions(timeframeSelect, orderActionsData.stats ? initialOptions : []);
      dropdownSelection = initiallySelected;

      if (!dropdownSelection) {
        loadEmptyTiles();
      } else {
        loadDashboardData(radioSelection, dropdownSelection);
      }
    }
  }

  // LOAD AND DISPLAY DATA

  async function loadInitialData() {
    await loadOrderActionsData(radioSelection, dropdownSelection);
    if (!orderActionsData?.stats?.length) return [['No Data available'], null];
    const year = Math.max(...orderActionsData.stats.map(stat => stat.year));
    const month = Math.max(...orderActionsData.stats.map(stat => stat.year === year && stat.month));
    const options = orderActionsData.stats.map(stat => stat.sk);
    if (radioSelection === 'daily') {
      const day = Math.max(
        ...orderActionsData.stats.map(stat => stat.year === year && stat.month === month && stat.day)
      );
      return [options, `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`];
    }
    return [options, `${year}-${('0' + month).slice(-2)}`];
  }

  function loadEmptyTiles() {
    loadValueInRangeTile('order-success-rate', 0.0, 'gray', 'no data');
    loadTextTile('successful-orders', '-', null, true);
    loadTextTile('failed-orders', '-', null, true);
    [...Array(3)]
      .map(_ => ['Top Order', '-'])
      .forEach((item, index) => loadTextTile(`top-${index}-order`, item[1], null, true, item[0]));
    [...Array(3)]
      .map(_ => ['Failed Order', '-'])
      .forEach((item, index) => loadTextTile(`failed-${index}-order`, item[1], null, true, item[0], null));
    loadStackedAbsoluteValuesChart(
      'orders-by-type-and-state',
      'Number of Orders',
      'by Type and State',
      {},
      [],
      10,
      true,
      true
    );
    loadDonutChart('orders-by-state', 'Orders by State', {});
    loadDonutChart('orders-by-type', 'Orders by Type', {});
    $('#table-order-data').DataTable().clear().draw();
  }

  function loadDashboardData(timeframe, sk) {
    loadOrderActionsData(timeframe, sk);
    loadOrderData(timeframe, sk);
  }

  async function loadOrderActionsData(timeframe, sk) {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    try {
      orderActionsData = await baseApiAxios.get(
        `/stats/categories/orders/statistics/actions?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {}
      );

      if (orderActionsData?.stats?.length) {
        let chartData = orderActionsData.stats[0].data;

        let range, rangeLabel;
        if (chartData.success_rate > 98.0) {
          range = 'green';
          rangeLabel = 'excellent';
        } else if (96.0 < chartData.success_rate && chartData.success_rate <= 98.0) {
          range = 'yellow';
          rangeLabel = 'good';
        } else if (chartData.success_rate <= 96.0) {
          range = 'red';
          rangeLabel = 'warning';
        }

        loadValueInRangeTile('order-success-rate', chartData.success_rate, range, rangeLabel);
        loadTextTile('successful-orders', chartData.success, null, true);
        loadTextTile('failed-orders', chartData.failed, null, true);
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadOrderData(timeframe, sk) {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    try {
      orderData = await baseApiAxios.get(
        `/stats/categories/orders?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {}
      );

      let stateLabels = ['new', 'in_progress', 'failed', 'success'];
      if (orderData?.stats?.length) {
        let chartData = {},
          typeData = {},
          stateData = {},
          failedData = {};

        orderData.stats.forEach(dataset => {
          if (dataset.stat === 'actions' || dataset.sk !== sk) return;
          typeData[dataset.stat] = dataset.data.count;
          failedData[dataset.stat] = dataset.data.failed;
          let dataObj = {};
          stateLabels.forEach(label => {
            stateData[label] = (stateData[label] || 0) + dataset.data[label];
            dataObj[label] = { count: dataset.data[label] };
          });
          chartData[dataset.stat] = Object.assign(
            {
              count: Object.values(dataObj)
                .map(a => a.count)
                .reduce((a, b) => a + b),
            },
            dataObj
          );
        });

        loadStackedAbsoluteValuesChart(
          'orders-by-type-and-state',
          'Number of Orders',
          'by Type and State',
          chartData,
          stateLabels,
          Math.max(...Object.values(typeData)),
          true,
          true
        );
        loadDonutChart('orders-by-state', 'Orders by State', stateData);
        loadDonutChart('orders-by-type', 'Orders by Type', typeData);
        const mostRequested = Object.entries(typeData)
          .sort((a, b) => b[1] - a[1])
          .slice(0, 3);
        const mostFailed = Object.entries(failedData)
          .sort((a, b) => b[1] - a[1])
          .slice(0, 3);
        mostRequested.forEach((item, index) =>
          loadTextTile(`top-${index}-order`, item[1], null, true, tileTitles[item[0]], 'var(--bs-secondary)')
        );
        mostFailed.forEach((item, index) =>
          loadTextTile(
            `failed-${index}-order`,
            item[1],
            null,
            true,
            tileTitles[item[0]],
            'var(--bs-danger)',
            'Failed Orders'
          )
        );
        loadOrderTable();
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadOrderTable() {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    const tableData = orderData?.stats.map(dataset => Object.assign({ type: dataset.stat }, dataset.data));

    initDataTable(
      'table-order-data',
      'lCfrtpBi',
      [
        {
          extend: 'excelHtml5',
          text: 'Export Excel',
          exportOptions: {
            columns: ':visible',
          },
          titleAttr: 'Export the visible columns as Excel file',
        },
        {
          extend: 'csvHtml5',
          text: 'Export CSV',
          exportOptions: {
            columns: ':visible',
          },
          titleAttr: 'Export the visible columns as CSV file',
        },
      ],
      [
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'type',
          name: 'type_col',
          title: 'Order Type',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'count',
          name: 'count_col',
          title: 'Count',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'failed',
          name: 'failed_col',
          title: 'Failed',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'in_progress',
          name: 'in_progress_col',
          title: 'In Progress',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'new',
          name: 'new_col',
          title: 'New',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'success',
          name: 'success_col',
          title: 'Successful',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'success_rate',
          name: 'success_rate_col',
          title: 'Success Rate',
          createdCell: function (td, cellData) {
            addCopyButton(td);
            if (cellData > 98.0) {
              $(td).addClass('portal-success-background');
            } else if (96.0 < cellData && cellData <= 98.0) {
              $(td).addClass('portal-warning-background');
            } else if (cellData <= 96.0) {
              $(td).addClass('portal-danger-background');
            }
          },
          render: function (cellData) {
            return `${cellData.toFixed(2)}%`;
          },
        },
      ],
      null,
      null
    );

    const dt = $('#table-order-data').DataTable();
    dt.rows().remove();
    dt.rows.add(tableData).draw(false);

    removeSpinners();
  }

  return [page, onPageReady];
}

import { baseApiAxios } from './js/portal/api';
import { createJsonCodeBlock} from './js/portal/main';
import { removeSpinners } from './js/portal/sidebar';

export default function AdminSettingsListPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group row">
          <label for="portal-settings" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
            Portal Settings
            <span class="loading-form-inline mx-3 loading-animation">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
          </label>
          <div class="col-md-12 col-lg-9 col-xl-10">
            <select
              required
              class="form-control form-select selectpicker"
              data-live-search="true"
              data-show-subtext="true"
              id="portal-settings"
              name="portal-settings"
              data-size="10"
              onchange={() => printSettings()}>
              <option value="" disabled selected>
                - Select a Portal Setting -
              </option>
            </select>
          </div>
        </div>

        <div name="config-wrapper-detail">
          <div id="config_item" name="config_item" class="col-lg-12 col-md-12">
            <pre class="add-line-numbers">
              {[...Array(20).keys()].map(() => {
                return <span></span>;
              })}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );

  function printSettings() {
    const config = $('#portal-settings')[0].value;
    const configItem = $('#config_item');
    const json = JSON.parse(config);
    const json_nice = JSON.stringify(json, null, 2)
      .replace(/"Deny"/g, '<strong style="color:red">"Deny"</strong>')
      .replace(/"Allow"/g, '<strong style="color:green">"Allow"</strong>');

    const codeBlock = createJsonCodeBlock(json_nice);

    configItem.html(codeBlock);
  }

  function listSettings(result) {
    removeSpinners();

    const sel = document.getElementById('portal-settings');
    for (let i = sel.options.length - 1; i >= 0; i--) {
      sel.remove(i);
    }
    for (let key in result.settings) {
      const opt = document.createElement('option');
      opt.value = JSON.stringify(result.settings[key]['settings']);
      opt.innerText = result.settings[key]['configuration'];
      sel.appendChild(opt);
    }

    $('#portal-settings').html(
      $('#portal-settings option').sort(function (a, b) {
        return a.text < b.text ? -1 : 1;
      })
    );

    $('.selectpicker').selectpicker('refresh');

    printSettings();
  }

  function onPageReady() {
    $('.selectpicker').selectpicker('refresh');
    baseApiAxios.getSettings({ action: 'list-settings' }).then(listSettings);
  }

  return [page, onPageReady];
}
